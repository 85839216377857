import Assignments from 'DataServices/Assignments'
import { action } from 'mobx'
import GlobalEventBus from 'Services/GlobalEventBus'
import StoreChangeBus from 'Services/StoreChangeBus'
import BaseStore from 'Stores/base_store/BaseStore'
import StoreInstances, { getStoreFor } from 'Stores/StoreInstances'

export const STORE_TYPE = 'Assignments'

export function getAssignmentsStoreFor (param, opts = {}) {
  return getStoreFor(AssignmentsStore, STORE_TYPE, param, {
    forceUnique: false,
    preload: opts.preload,
    autoFetch: opts.autoFetch,
    fetchAll: opts.fetchAll,
  })
}

StoreChangeBus.subscribe(evt => {
  function refreshAssignments (params) {
    Assignments.getAll(params).then(assignments => {
      StoreInstances._propagateChanges(STORE_TYPE, assignments)
    })
  }

  if (evt.type === 'Events') {
    refreshAssignments({ event_id: evt.id })
  }

  if (evt.type === 'Jobs') {
    refreshAssignments({ job_id: evt.id })
  }
})

export class AssignmentsStore extends BaseStore {
  constructor (seedData) {
    const opts = {
      paginate: true,
    }
    super(STORE_TYPE, seedData, opts)
  }

  @action
  accept (id) {
    return Assignments.accept(id)
      .then(assign => {
        StoreInstances._propagateChanges(STORE_TYPE, [assign])
        return assign
      })
      .catch(console.error)
  }

  @action
  reject (id) {
    return Assignments.reject(id)
      .then(assign => {
        StoreInstances._propagateChanges(STORE_TYPE, [assign])
        return assign
      })
      .catch(console.error)
  }

  @action
  markEnroute (assignmentId) {
    return Assignments.mark(assignmentId, 'enroute')
      .then(assign => {
        StoreInstances._propagateChanges(STORE_TYPE, [assign])
        return assign
      })
  }

  @action
  markOnSite (assignmentId) {
    return Assignments.mark(assignmentId, 'on_site')
      .then(assign => {
        StoreInstances._propagateChanges(STORE_TYPE, [assign])
        return assign
      })
  }

  @action
  markComplete (assignmentId, params) {
    return Assignments.mark(assignmentId, 'complete', params)
      .then(assign => {
        StoreInstances._propagateChanges(STORE_TYPE, [assign])
        return assign
      })
  }

  @action
  requestCancellation (assignmentId, reason) {
    return Assignments.requestCancellation(assignmentId, reason)
      .then(assign => {
        StoreInstances._propagateChanges(STORE_TYPE, [assign])
        return assign
      })
  }

  @action
  withdrawCancellation (assignmentId) {
    return Assignments.withdrawCancellation(assignmentId)
      .then(assign => {
        StoreInstances._propagateChanges(STORE_TYPE, [assign])
        return assign
      })
  }

  @action
  approveCancellation (assignmentId) {
    return Assignments.approveCancellation(assignmentId)
      .then(assign => {
        StoreInstances._propagateChanges(STORE_TYPE, [assign])
        return assign
      })
  }

  @action
  denyCancellation (assignmentId) {
    return Assignments.denyCancellation(assignmentId)
      .then(assign => {
        StoreInstances._propagateChanges(STORE_TYPE, [assign])
        return assign
      })
  }

  @action
  bulkUpdate (bulkData) {
    return Assignments.bulkUpdate(bulkData)
      .then(assignments => StoreInstances._propagateChanges(STORE_TYPE, assignments))
      .then(assignments => GlobalEventBus.dispatch('assignments:bulkUpdate:success', assignments))
  }

  @action
  pickup (jobId) {
    return Assignments.pickup(jobId)
      .then(assign => {
        StoreInstances._propagateChanges(STORE_TYPE, [assign])
        return assign
      })
  }

  @action
  _fetchOne (id) {
    return Assignments.getById({ id: this._filter.id })
  }

  @action
  _fetchAll (overridePayload) {
    let payload = overridePayload || {
      ...this._filter,
    }
    return Assignments.getAll(payload)
  }
}
