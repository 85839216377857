export const ASSIGNMENT_CHECK_IN_THRESHOLD = 12 // hours

export const DEFAULT_HTML_TITLE = 'Detail Kommander'
export const HTML_TITLE_SUFFIX = `| ${DEFAULT_HTML_TITLE}`

export const BASE_API_URL = '/api/v1'
export const DEFAULT_JOB_GROUP_NAME = 'Main'

export const NOTIFICATION_REFRESH_IN_MS = 5 * 60 * 1000
export const TASKS_REFRESH_IN_MS = 5 * 60 * 1000

export const kpaBreakpoints = {
  SMALL: 570,
  LARGE: 940,
}

export const jobViewModes = {
  CALENDAR: 'calendar',
  CARDS: 'cards',
}

export const jobColorStatuses = {
  DEFAULT: 'default',
  ASSIGNMENT_ACKNOWLEDGED: 'assignment_acknowledged',
  ASSIGNMENT_ASSIGNED: 'assignment_assigned',
  BID_CANCELLED: 'bid_cancelled',
  BID_PLACED: 'bid_placed',
}

export const jobCalendarLegendModes = {
  AVAILABLE: 'available',
  ASSIGNED: 'assigned',
}

export const jobCardContexts = {
  AGENCY: 'agency',
  SELF: 'self',
  OTHER_USER: 'other_user',
}

export const rosterModes = {
  AGENCY_EVENT: 'agency_event',
  AGENCY_EVENT_DATE: 'agency_event_date',
  AGENCY_JOB_DATE: 'agency_job_date',
  EVENT: 'event',
}

export const kommanderPayTransferFrequencies = {
  DAILY: 'daily',
  WEEKLY: 'weekly',
}

export const kommanderPayTransferDays = {
  SUNDAY: 0,
  MONDAY: 1,
  TUESDAY: 2,
  WEDNESDAY: 3,
  THURSDAY: 4,
  FRIDAY: 5,
  SATURDAY: 6,
}

export const activityLogLabels = {
  EVENT_END: 'Event End',
  EVENT_START: 'Event Start',
  JOB_END: 'Job End',
  JOB_START: 'Job Start',
}

export const paneTypes = {
  DEFAULT: 'default',
  LIST: 'list',
  GRID: 'grid',
}

export const warningLabels = {
  UNACKNOWLEDGED_ASSIGNMENTS: 'Unacknowledged Assignments',
  CANCELLATION_REQUEST: 'Cancellation Request',
  INCOMPLETE_PRIMARY_ASSIGNMENTS: 'Incomplete Assignments',
  NOT_ENOUGH_BIDS: 'Not Enough Bids',
  NOT_ENOUGH_ASSIGNMENTS: ' Not Enough Assignments',
  ADJUSTED_TIMES: 'Adjusted Times',
}

export const paymentMethodTypes = {
  CREDIT_CARD: 'CreditCard',
  BANK_ACCOUNT: 'BankAccount',
  MANUAL: 'ManualPaymentMethod',
}

export const featureFlags = {
  agency: {
    ACTIVITY_LOG: 'activity_log',
    ASSIGNMENT_INSURANCE: 'assignment_insurance',
    CUSTOM_INVOICE_ID: 'custom_invoice_id',
    EVENT_DEPOSITS: 'event_deposits',
  },
}

export const chargeWords = {
  business: {
    FUTURE: 'pay',
    PAST: 'paid',
  },
  agency: {
    FUTURE: 'charge',
    PAST: 'charged',
  },
}

export const taxDocumentCreatedAtFormat = 'MM/DD/YYYY @ HH:mm'
