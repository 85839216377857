import { filter, map, merge, omit, orderBy, pick } from 'lodash'

import PayScheduleEntityRules from './PayScheduleEntityRules'
import TimeService from 'Services/TimeService'
import axios from './axios'
import moment from 'moment'

const baseUrlString = '/pay_schedule_entities'
const cleanParams = [
  'agency_id',
  'name',
  'payable_to',
  'pay_schedule_id',
  'pay_schedule_entity_rules',
]

const PayScheduleEntity = function (initialData) {
  var pse = {}

  pse.$reinitialize = function (data) {
    merge(pse, data)

    pse.created_at = moment.utc(data.created_at)
    pse.created_at_date = TimeService.momentFormatInLocalOffset(data.created_at, 'MM/DD/YYYY HH:mm')

    pse.pay_schedule_entity_rules = map(pse.pay_schedule_entity_rules, function (pser) {
      if (pser.type === 'PayScheduleEntityBlockRule ') {
      }
      return PayScheduleEntityRules.initWithData(pser)
    })
  }

  pse.$reinitialize(initialData)

  pse.$addRule = function (pser) {
    pse.pay_schedule_entity_rules.push(pser)
  }

  pse.$removeRule = function (pser) {
    pse.pay_schedule_entity_rules = filter(pse.pay_schedule_entity_rules, function (rule) {
      return rule.id !== pser.id
    })
  }

  pse.$clean = function () {
    return pick(pse, cleanParams)
  }

  pse.$prepareForCreate = function () {
    let createPse = pse.$clean()
    createPse.pay_schedule_entity_rules_attributes = pse.pay_schedule_entity_rules.map(function (pser) {
      return pser.$clean()
    })

    return omit(createPse, 'pay_schedule_entity_rules')
  }

  return pse
}

const PayScheduleEntities = {
  create: async function (payScheduleEntity) {
    return axios.post(baseUrlString, {
      pay_schedule_entity: payScheduleEntity.$prepareForCreate(),
    }).then(function (response) {
      return PayScheduleEntities.initWithData(response.data)
    })
  },
  getAllForPaySchedule: async function (payScheduleId) {
    return axios.get(baseUrlString + '?pay_schedule_id=' + payScheduleId)
      .then(response => response.data)
      .then(data => {
        const initializedArray = data.map(payScheduleEntity => PayScheduleEntities.initWithData(payScheduleEntity))
        return orderBy(initializedArray, ['id'], ['desc'])
      }).catch(function (error) {
        console.error(error.message)
      })
  },
  getById: async function (id) {
    return axios.get(baseUrlString + '/' + id)
      .then(response => response.data)
      .then(data => PayScheduleEntities.initWithData(data))
      .catch(function (error) {
        console.error(error.message)
      })
  },
  initWithData: function (data) {
    return new PayScheduleEntity(data)
  },
  initBlank: function (agencyId) {
    return PayScheduleEntities.initBlankForPaySchedule(null, agencyId)
  },
  initBlankForPaySchedule: function (payScheduleId, agencyId) {
    return PayScheduleEntities.initWithData({
      agency_id: agencyId,
      id: null,
      name: '',
      pay_schedule_entity_rules: [],
      pay_schedule_id: payScheduleId,
      created_at: TimeService.getNowInStoreTime(),
    })
  },
}

export default PayScheduleEntities
