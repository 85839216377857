import { map, merge, orderBy } from 'lodash'

import PayScheduleEntities from 'DataServices/PayScheduleEntities'
import axios from './axios'

const baseUrlString = '/pay_schedules'

const PaySchedule = function (initialData) {
  let paySchedule = {}

  paySchedule.$reinitialize = function (data) {
    merge(paySchedule, data)

    paySchedule.pay_schedule_entity = PayScheduleEntities.initWithData(paySchedule.pay_schedule_entity)
  }
  paySchedule.$reinitialize(initialData)
  return paySchedule
}

function initWithData (data) {
  return new PaySchedule(data)
}

async function archive (id) {
  return axios.post(`${baseUrlString}/${id}/archive`)
    .then(response => initWithData(response.data))
    .catch(console.error)
}

async function getAll (agencyId) {
  return axios.get(baseUrlString + '?agency_id=' + agencyId)
    .then((response) => {
      return orderBy(map(response.data, paySchedule => initWithData(paySchedule)), ['name'])
    }).catch(console.error)
}

async function getById (id) {
  return axios.get(`${baseUrlString}/${id}`)
    .then(response => initWithData(response.data))
    .catch(console.error)
}

// jobs: an array of objects like: { id: JOB_ID, start_date: null || moment(), end_date: null || moment() }
// assignments: an array of objects like: { id: JOB_ID, start_date: null || moment(), end_date: null || moment() }
async function getEstimates ({ jobs, assignments }) {
  // build up the param strings
  const params = {}

  if (jobs && jobs.length > 0) {
    params.jobs = map(jobs, (j) => {
      let txt = j.id
      if (j.start_date) txt += `|${j.start_date.toISOString()}`
      if (j.end_date) txt += `|${j.end_date.toISOString()}`
      return txt
    }).join(',')
  }

  if (assignments && assignments.length > 0) {
    params.assignments = map(assignments, (as) => {
      let txt = as.id
      if (as.start_date) txt += `|${as.start_date.toISOString()}`
      if (as.end_date) txt += `|${as.end_date.toISOString()}`
      return txt
    }).join(',')
  }
  return axios.get(`${baseUrlString}/estimates`, { params })
    .then((response) => response.data)
    .catch(console.error)
}

export default {
  archive,
  getAll,
  getById,
  getEstimates,
  initWithData,
}
