import Assignments from 'DataServices/Assignments'
import Notifications from 'DataServices/Notifications'
import * as types from './mutation_types'

export const getAllUnread = async ({ commit }) => {
  commit(types.FETCHING_NOTIFICATIONS)
  let notificationData = await Notifications.getUnread()

  commit(types.RECEIVE_UNREAD_NOTIFICATIONS, { notificationData })
  commit(types.FETCHED_NOTIFICATIONS)
}

export const getNotification = async ({ commit }, payload) => {
  commit(types.FETCHING_NOTIFICATIONS)
  let notification = await Notifications.getById(payload.id)

  commit(types.RECEIVE_NOTIFICATION, { notification })
  commit(types.FETCHED_NOTIFICATIONS)
}

export const getFirstPage = async ({ dispatch }) => {
  dispatch('getPage', { page: 1 })
}

export const getNextPage = async ({ dispatch, state }) => {
  let page = state.highestStoredPage < state.totalPages ? state.highestStoredPage + 1 : state.highestStoredPage
  dispatch('getPage', { page })
}

export const getPage = async ({ commit }, payload) => {
  commit(types.FETCHING_NOTIFICATIONS)
  let notificationData = await Notifications.getAll(payload.page)
  commit(types.RECEIVE_NOTIFICATIONS, { notificationData })
  commit(types.FETCHED_NOTIFICATIONS)
}

export const dismissNotification = async ({ commit }, payload) => {
  commit(types.READ_NOTIFICATION, payload)
  let successful = await Notifications.markRead(payload.id)
  if (!successful) {
    commit(types.UNREAD_NOTIFICATION, payload)
  }
}

export const undismissNotification = async ({ commit }, payload) => {
  commit(types.UNREAD_NOTIFICATION, payload)
  await Notifications.markUnread(payload.id)
}

export const dismissAllNotifications = async ({ commit }, payload) => {
  commit(types.READ_ALL_NOTIFICATIONS, payload)
  let successful = await Notifications.markAllRead()
  if (!successful) {
    commit(types.READ_NOTIFICATION, payload)
  }
}

export const acknowledgePrimaryAssignment = async({ commit, dispatch }, payload) => {
  // console.log(`Assignment ${payload.assignmentId} Acknowledgement`)
  dispatch('dismissNotification', {
    id: payload.notificationId,
  })

  try {
    await Assignments.accept(payload.assignmentId)
    dispatch('getNotification', {
      id: payload.notificationId,
    })
  } catch (e) {
    dispatch('undismissNotification', {
      id: payload.notificationId,
    })
  }
}

export const acceptAlternateAssignment = async({ commit, dispatch }, payload) => {
  // console.log(`Assignment ${payload.assignmentId} Accept`)
  dispatch('dismissNotification', {
    id: payload.notificationId,
  })

  try {
    await Assignments.accept(payload.assignmentId)
    dispatch('getNotification', {
      id: payload.notificationId,
    })
  } catch (e) {
    dispatch('undismissNotification', {
      id: payload.notificationId,
    })
  }
}

export const rejectAlternateAssignment = async({ commit, dispatch }, payload) => {
  // console.log(`Assignment ${payload.assignmentId} Reject`)
  dispatch('dismissNotification', {
    id: payload.notificationId,
  })

  try {
    await Assignments.reject(payload.assignmentId)
    dispatch('getNotification', {
      id: payload.notificationId,
    })
  } catch (e) {
    dispatch('undismissNotification', {
      id: payload.notificationId,
    })
  }
}
