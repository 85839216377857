import PersonnelTypes from 'DataServices/PersonnelTypes'
import * as types from './mutation_types'

export const getPersonnelTypes = async ({ dispatch, commit }) => {
  commit(types.FETCHING_PERSONNEL_TYPES)
  await dispatch('paySchedules/getPaySchedules', null, { root: true })
  let personnelTypes = await PersonnelTypes.getAll(window.dkVariables.agency_id)
  commit(types.RECEIVE_PERSONNEL_TYPES, { personnelTypes })
  commit(types.FETCHED_PERSONNEL_TYPES)
}

export const getPersonnelType = async ({ commit }, payload) => {
  let personnelType = await PersonnelTypes.getById(payload.personnelTypeId)
  commit(types.RECEIVE_PERSONNEL_TYPE, { personnelType })
}

export const updatePersonnelType = async ({ commit }, payload) => {
  commit(types.SAVING_PERSONNEL_TYPE)
  let personnelType = await PersonnelTypes.update(payload.personnelType)
  commit(types.RECEIVE_PERSONNEL_TYPE, { personnelType })
  commit(types.SAVED_PERSONNEL_TYPE)
}

export const createPersonnelType = async ({ commit }, payload) => {
  commit(types.SAVING_PERSONNEL_TYPE)
  let personnelType = await PersonnelTypes.create(payload.personnelType)
  commit(types.RECEIVE_PERSONNEL_TYPE, { personnelType })
  commit(types.SAVED_PERSONNEL_TYPE)
}
