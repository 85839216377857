import EventBus from 'Services/GlobalEventBus'

export const modalNames = {
  ABOUND_TAX_PROFILE: 'abound-tax-profile-dialog',
  ADD_USER: 'add-user-dialog',
  ASSIGNMENT_INSURANCE_CONTRACT: 'assignment-insurance-contract-dialog',
  ASSIGNMENT_MANAGER_CONFLICT: 'assignment-manager-conflict-dialog',
  ASSIGNMENT_MANAGER: 'assignment-manager-dialog',
  ATTACHMENT_EDITOR: 'attachment-editor-dialog',
  APPROVE_ESTIMATE: 'approve-estimate-dialog',
  APPROVE_ESTIMATE_WITHOUT_SENDING: 'approve-estimate-without-sending-dialog',
  CHARGE_DEPOSIT: 'charge-deposit-dialog',
  DECLINE_ESTIMATE: 'decline-estimate-dialog',
  SEND_ESTIMATE: 'send-estimate-dialog',
  CONFIRMATION_DIALOG: 'confirmation-dialog',
  EVENT_CANCEL: 'event-cancel-dialog',
  EVENT_CLONE: 'event-clone-dialog',
  EVENT_CLONE_ERROR: 'event-clone-error-dialog',
  EVENT_NAME_EDITOR: 'event-name-editor-dialog',
  EVENT_REJECT: 'event-reject-dialog',
  EVENT_VBA_LIST: 'event-vba-list-dialog',
  JOB_ASSIGNMENT_ACKNOWLEDGE: 'job-assignment-acknowledge-dialog',
  JOB_ASSIGNMENT_EDITOR: 'job-assignment-editor-dialog',
  KOMMANDER_PAY_ACCOUNT_SETTINGS: 'kommander-pay-account-settings-dialog',
  TAX_REPORT_DOWNLOAD: 'tax-report-download-dialog',
  W9_MANAGEMENT: 'w9-management-dialog',
  KOMMANDER_PAY_ACCOUNT_AGENCY_SETTINGS: 'kommander-pay-account-agency-settings-dialog',
}

export default {
  open (componentName, params) {
    if (!Object.values(modalNames).includes(componentName)) {
      throw Error(`'${componentName}' is not a valid modal component. See ModalService.js for valid options.`)
    }

    EventBus.dispatch('spawning-modal', { componentName, params })
  },

  close (modalId) {
    EventBus.dispatch('destroying-modal', modalId)
  },
}
