import { filter, orderBy, sortBy, toArray } from 'lodash'

export const gettingPaySchedules = state => state.gettingPaySchedules
export const gettingPayScheduleEntities = state => state.gettingPayScheduleEntities
export const paySchedules = state => sortBy(state.paySchedules, [ps => ps.name.toLowerCase()])
export const activePaySchedules = state => sortBy(filter(state.paySchedules, ps => ps.active), [ps => ps.name.toLowerCase()])
export const payScheduleEntities = state => state.payScheduleEntities
export const savingPayScheduleEntity = state => state.savingPayScheduleEntity
export const selectedPayScheduleId = state => state.selectedPayScheduleId
export const selectedPayScheduleEntityId = state => state.selectedPayScheduleEntityId
export const expenseCategories = state => toArray(sortBy(state.expenseCategories, [ec => ec.name.toLowerCase()]))

export const selectedPaySchedule = function (state) {
  return state.paySchedules[state.selectedPayScheduleId] || {}
}

export const selectedPayScheduleEntity = function (state) {
  return state.payScheduleEntities[state.selectedPayScheduleEntityId] || {}
}

export const selectedPayScheduleEntities = (state) => {
  return orderBy(filter(state.payScheduleEntities, { 'pay_schedule_id': state.selectedPayScheduleId }), ['id'], ['desc'])
}

// export const selectedPayScheduleEntityRule = (state) => {
//   const id = state.selectedPayScheduleEntityId
//   const pse = state.payScheduleEntities[id]
//   const ruleIdx = state.selectedPayScheduleEntityRuleIdx

//   return pse.rules[ruleIdx] || {}
// }
