<template>
  <span>{{ name }}</span>
</template>

<script>
export default {
  name: 'ks-user-name-view',
  computed: {
    name () {
      let string = ''

      if (this.user) {
        if (this.prefix !== '') {
          string += `${this.prefix} `
        }

        string += `${this.user.first_name} ${this.user.last_name}`

        if (this.suffix !== '') {
          string += ` ${this.suffix}`
        }

        return string
      } else {
        return this.loadingText
      }
    },
  },
  props: {
    loadingText: {
      type: String,
      default: 'Loading...',
    },
    prefix: {
      type: String,
      default: '',
    },
    suffix: {
      type: String,
      default: '',
    },
    user: {
      type: Object,
    },
  },
}
</script>
