<template>
  <div class="ks-vue-component">
    <div :class="formGroupClass">
      <div class="form__label-wrap">
        <template v-if="showLabel">
          <label class="form__label">{{ label }}</label>
          <div v-if="showOptionalText" class="form__optional">- optional</div>
          <div v-if="dirty" class="form__dirty-text">Unsaved</div>
        </template>
      </div>
      <div v-if="showHelpText" class="form__help">{{ helpText }}</div>
      <multiselect
        :value="value"
        @input="select"
        :options="options"
        :label="optionsLabel"
        :track-by="trackBy"
        :placeholder="placeholder"
        :searchable="searchable"
        :multiple="false"
        :taggable="taggable"
        :disabled="disabled"
        :show-labels="showLabels"
        :allow-empty="!required && allowEmpty"
        :selected-label="selectedLabel"
        :select-label="selectLabel"
        :deselect-label="deselectLabel"
        :group-label="groupLabel"
        :group-values="groupValues"
        :open-direction="openDirection"
        @open="hasFocus = true"
        @close="hasFocus = false"
        @search-change="onSearchChange"
        @tag="addTag"
        :close-on-select="true"
        v-if="!forceView"
      >
        <template slot="singleLabel" slot-scope="{ option }">
          <span v-if="option && option[optionsLabel]">{{ option[optionsLabel] }}</span>
        </template>
        <template slot="selection" slot-scope="{ isOpen }">
          <span
            class="multiselect__temp-placeholder"
            v-if="!searchable && isOpen && !value"
          >{{ placeholder }}</span>
        </template>
      </multiselect>
      <div class="view-mode" v-else>{{ value && value[optionsLabel] }}</div>
    </div>
    <!--<pre>{{ $v.value }}</pre>-->
  </div>
</template>

<script>
  import Multiselect from 'vue-multiselect'
  import SelectMixin from 'Mixins/SelectMixin'
  import { validationMixin } from 'vuelidate'

  export default {
    name: 'ks-select',
    components: {
      Multiselect,
    },
    mixins: [ SelectMixin, validationMixin ],
  }
</script>

<style lang="scss">
  @use "~Styles/mixins";

  .ks-vue-component .view-mode {
    @include mixins.view-mode;
  }
</style>
