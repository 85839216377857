import { isUndefined, merge, pick } from 'lodash'

import { v4 as uuid } from 'uuid'

const cleanParams = [
  'type',
  'description',
  'payable_to',
  'expense_category_id',
  'data',
  'depositing_virtual_bank_account_id'
]

const PayScheduleEntityRule = function (initialData, args) {
  var pser = {}

  pser.$reinitialize = function (data) {
    merge(pser, data)

    pser = new ruleFactories[pser.type](pser, args)
  }

  pser.$reinitialize(initialData)

  pser.$payableToUser = function () {
    return pser.payable_to === 'user'
  }

  pser.$payableToAgency = function () {
    return pser.payable_to === 'agency'
  }

  pser.$clean = function () {
    if (pser.type === 'PayScheduleEntityBlockRule') {
      pser.$updateBlockArraysFromObjects()
    } else if (pser.type === 'PayScheduleEntitySegmentRule') {
      pser = pser.$convertNumbersToStrings()
    }
    return pick(pser, cleanParams)
  }

  return pser
}

const PayScheduleEntityFixedRateRule = function (initialData, args) {
  var pser = {}

  pser.$reinitialize = function (data) {
    merge(pser, data)

    if (isUndefined(pser.data) || (pser.data.percent === null && pser.data.rate === null)) {
      if (!isUndefined(args) && args.percent) {
        pser.data = {
          percent: '',
          rate: null,
        }
      } else {
        pser.data = {
          percent: null,
          rate: '',
        }
      }
    }

    pser._type = (pser.data.rate !== null) ? 'Flat Rate' : 'Percentage'
  }

  pser.$reinitialize(initialData)

  pser.$flatRateRule = function () {
    return pser.data.rate !== null
  }

  pser.$percentRule = function () {
    return pser.data.percent !== null
  }

  return pser
}

const PayScheduleEntitySegmentRule = function (initialData) {
  var pser = {}

  pser.$reinitialize = function (data) {
    merge(pser, data)

    pser._type = 'Hourly Rate'
    if (isUndefined(pser.data)) {
      pser.data = {
        min_length_in_hours: '0',
        rounding_rule: {
          direction: 'up',
          enabled: false,
          interval: '15',
        },
        segments: [{
          num_hours: null,
          rate: '',
          use_user_base_rate: false,
          user_base_rate_multiplier: '1',
        }],
      }
    }
  }

  pser.$convertNumbersToStrings = function () {
    pser.data.min_length_in_hours = pser.data.min_length_in_hours.toString()
    pser.data.rounding_rule.interval = pser.data.rounding_rule.interval.toString()
    return pser
  }

  pser.$reinitialize(initialData)

  return pser
}

const PayScheduleEntityBlockRule = function (initialData, args) {
  var pser = {}

  pser.$reinitialize = function (data) {
    merge(pser, data)

    pser._type = 'Block Rate'

    if (isUndefined(pser.data)) {
      pser.data = {
        block_sizes_in_hours: [ args.blockSize || '' ],
        block_rates: [
          {
            rate: '',
            use_user_base_rate: false,
            user_base_rate_multiplier: '1.5',
          },
        ],
      }
    }

    pser.$createBlockObjects()
  }

  pser.$addBlockAtIdx = function (idx, block) {
    block._uuid = uuid()
    pser._blocks.splice(idx, 0, block)
  }

  pser.$addBlockBeforeFinalBlock = function (block) {
    let idx = pser._blocks.length - 1
    pser.$addBlockAtIdx(idx, block)
  }

  pser.$addEmptyBlockBeforeFinalBlock = function () {
    pser.$addBlockBeforeFinalBlock({
      sizeInHours: '',
      rate: {
        rate: '',
        use_user_base_rate: false,
        user_base_rate_multiplier: '1.5',
      },
    })
  }

  pser.$removeBlockAtIdx = function (idx) {
    pser._blocks.splice(idx, 1)
  }

  pser.$createBlockObjects = function () {
    pser._blocks = []
    pser.data.block_rates.forEach(function (rate, idx) {
      let sizeInHours = pser.data.block_sizes_in_hours[idx]
      let _uuid = uuid()

      pser._blocks.push({
        _uuid,
        rate,
        sizeInHours,
      })
    })
  }

  pser.$updateBlockArraysFromObjects = function () {
    pser.data.block_sizes_in_hours = []
    pser.data.block_rates = []
    pser._blocks.forEach(function (block) {
      pser.data.block_sizes_in_hours.push(block.sizeInHours)
      pser.data.block_rates.push(block.rate)
    })
  }

  pser.$reinitialize(initialData)

  return pser
}

const ruleFactories = {
  PayScheduleEntityFixedRateRule,
  PayScheduleEntitySegmentRule,
  PayScheduleEntityBlockRule,
}

const PayScheduleEntityRules = {
  initWithData: function (data, args) {
    return new PayScheduleEntityRule(data, args)
  },
  initBlankUserRule: function (type, args) {
    var pser = PayScheduleEntityRules.initBlankForPayScheduleEntity(type, null, args)
    pser.payable_to = 'user'
    return pser
  },
  initBlankAgencyRule: function (type, args) {
    var pser = PayScheduleEntityRules.initBlankForPayScheduleEntity(type, null, args)
    pser.payable_to = 'agency'
    return pser
  },
  initBlankForPayScheduleEntity: function (type, payScheduleEntityId, args) {
    return PayScheduleEntityRules.initWithData({
      id: null,
      description: '',
      pay_schedule_entity_id: payScheduleEntityId,
      type: type,
    }, args)
  },
}

export default PayScheduleEntityRules
