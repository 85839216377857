import 'moment-timezone'

import { action, computed, observable, toJS } from 'mobx'
import { cloneDeep, find, has, isArray, isEmpty, merge, omit, remove } from 'lodash'

import Page from 'Stores/base_store/StorePage'
import PageSet from 'Stores/base_store/PageSet'
import StoreInstances from 'Stores/StoreInstances'
import moment from 'moment'
import { v4 as uuid } from 'uuid'

class BaseStore {
  _uuid = uuid()
  _type = ''
  _unique = false
  _promises = []
  _filter = {}
  _opts = { autoFetch: true }
  _requests = []
  _hardDisablePagination = false
  @observable _setFilterCalled = false

  @observable _currentPageSetUuid = undefined
  @observable _upcomingPageSetUuid = undefined
  @observable _pageSets = []

  _d = {
    enabled: false,
  } // Storing debug data related to the store instance

  constructor (type, seed, opts) {
    this._d.createdAt = Date.now()
    this._type = type

    StoreInstances.registerStore(this)

    // If the store constructor specifies the pagination state explicitly,
    // that is the value we should default back to in the event the filter causes
    // the pagination state to change. See `setFilter` for use.
    this._hardDisablePagination = opts && opts.paginate === false

    if (seed) {
      if (seed.data) {
        this._filter = seed.filter
        this._setFilterCalled = true
        const pageSet = new PageSet(this)
        const page = new Page(pageSet, 1)
        page._records = isArray(seed.data) ? seed.data : [seed.data]
        page._lastFetchedAt = Date.now()
        pageSet._pages.push(page)
        pageSet._currentPageNumber = 1

        this._pageSets.push(pageSet)
        this._currentPageSetUuid = pageSet.uuid
        // this._pageMap.set(this._currentPageNumber, page.uuid)
      } else if (seed.filter) {
        this.setFilter(seed.filter)
      }
    }
  }

  get uuid () {
    return this._uuid
  }

  set unique (unique) {
    this._unique = unique
  }

  @computed
  get allRecords () {
    return this.currentPageSet ? this.currentPageSet.allRecords : []
  }

  @computed
  get records () {
    return this.currentPageSet ? this.currentPageSet.currentPageRecords : []
  }

  @computed
  get pageData () {
    return this.currentPageSet ? this.currentPageSet.pageData : {}
  }

  @computed
  get links () {
    return this.currentPageSet ? this.currentPageSet.links : {}
  }

  @computed
  get loading () {
    if (!this._setFilterCalled) {
      return { any: true }
    } else {
      return (this.referenceablePageSet ? this.referenceablePageSet.loading : {})
    }
  }

  @computed
  get cacheValidation () {
    return this.currentPageSet ? this.currentPageSet.cacheValidation : {}
  }

  @computed
  get filterWithoutSortOrder () {
    return {
      ...omit(this._filter, 'sortOrder'),
    }
  }

  @computed
  get filter () {
    return {
      ...this.filterWithoutSortOrder,
      sort: this._sortString(),
    }
  }

  @computed
  get filterStringified () {
    return JSON.stringify(this._filter)
  }

  @computed
  get opts () {
    return this._opts
  }

  @computed
  get currentPageSet () {
    return this._pageSets.find(ps => ps.uuid === this._currentPageSetUuid)
  }

  @computed
  get upcomingPageSet () {
    return this._pageSets.find(ps => ps.uuid === this._upcomingPageSetUuid)
  }

  /**
   * Will return the upcoming page set if it exists, otherwise the current page
   * set will be used.
   */
  @computed
  get referenceablePageSet () {
    return this.upcomingPageSet || this.currentPageSet
  }

  @action
  setFilterOrRefresh (params, opts = {}) {
    // If we find a page set that has a matching filter, it means this store matches
    // the filter too. We will reload entirely. Otherwise, we need to set a new filter.
    const matchingPageSet = find(this._pageSets, ps => ps.compareFilter(params))
    if (matchingPageSet) {
      return this.reload()
    } else {
      return this.setFilter(params, opts)
    }
  }

  @action
  setOpts (opts) {
    if (!isEmpty(opts)) {
      this._opts = merge(this._opts, opts)
    }
    return this._opts
  }

  @action
  setFilter (params, opts = {}) {
    this._setFilterCalled = true
    this.setOpts(opts)
    this._filter = omit(params, 'page')
    // this._filterChanged = { changed: true, startingPage }

    const startingPage = params && params.page ? parseInt(params.page) : 1

    // Pass the starting page into the PageSet for the case where the store
    // data is not fetched right away. Whenever we finally called `initFetch`
    // later, the PageSet knows which page to start on. This is so we don't
    // always start on page 1.
    const newPageSet = new PageSet(this, startingPage)
    // Comparisons must be performed before the page set push.
    const matchingPageSet = find(this._pageSets, ps => ps.compare(newPageSet) && ps.cacheValidation.all.cacheValid)
    const validPageSet = matchingPageSet || newPageSet
    if (!matchingPageSet) {
      this._pageSets.push(newPageSet)
    }

    // TODO: comparing of new and old page sets -- _.some()
    // is the filter the same AND page set cache is valid?
    // if so, use it -- if not new page set.

    // if (isEqual(params, cloneDeep(toJS(this._filter))) && this._pages.has(this._pageNumberToUuid(startingPage))) {
    //   const pageUuid = this._pageNumberToUuid(startingPage)
    //   if (this._pages.get(pageUuid).cacheValid) {
    //     return deferred.resolve(this._pages.get(pageUuid).records)
    //   } else {
    //     return this._pages.get(pageUuid).reload()
    //   }
    // }

    this._upcomingPageSetUuid = validPageSet.uuid

    // If we are looking up just one ID, turn off pagination
    this.opts.paginate = !has(this._filter, 'id') && !this._hardDisablePagination

    if (this.opts.autoFetch) {
      validPageSet.initFetch(startingPage)
    }

    return validPageSet._initPagePromise(startingPage)
  }

  @action
  goToNextPage () {
    return this.referenceablePageSet.goToNextPage()
  }

  @action
  goToPreviousPage () {
    return this.referenceablePageSet.goToPreviousPage()
  }

  @action
  goToPage (pageNumber) {
    return this.referenceablePageSet.goToPage(pageNumber)
  }

  @action
  goToFirstPage () {
    return this.referenceablePageSet.goToFirstPage()
  }

  @action
  goToLastPage () {
    return this.referenceablePageSet.goToLastPage()
  }

  @action
  reloadIfCacheInvalid () {
    if (this.referenceablePageSet && !this.referenceablePageSet.cacheValid) {
      this.reload()
    }
  }

  @action
  initFetch () {
    return this.referenceablePageSet.initFetch()
  }

  @action
  _swapToUpcomingPageSet (pageSet) {
    if (pageSet.uuid === this._upcomingPageSetUuid) {
      this._currentPageSetUuid = this._upcomingPageSetUuid
      this._upcomingPageSetUuid = undefined

      // Clear out all other page sets
      remove(this._pageSets, ps => ps.uuid !== this._currentPageSetUuid)
    } else {
    }
  }

  @action
  propagateChanges (changes, action) {
    function itemContainsAllFields (item, fields) {
      return fields.every(field => !!item[field])
    }

    const includeFields = this._filter.include ? this._filter.include.split('') : []
    let allFieldsIncluded
    if (Array.isArray(changes)) {
      allFieldsIncluded = changes.every(c => itemContainsAllFields(c, includeFields))
    } else {
      allFieldsIncluded = itemContainsAllFields(changes, includeFields)
    }

    if (allFieldsIncluded) {
      this._reallyPropagateChanges(changes, action)
    } else {
      this.reload()
    }
  }

  _reallyPropagateChanges (changes, action) {
    if (this.upcomingPageSet) {
      this.upcomingPageSet._propagateChanges(changes, action)
    }
    if (this.currentPageSet) {
      this.currentPageSet._propagateChanges(changes, action)
    }
  }

  @action
  reload (force = false) {
    // If a page set is "upcoming" it means that the request
    // is in flight currently.
    const startingPage = this.currentPageSet ? this.currentPageSet.currentPage.pageNumber : 1
    if (force || !this.upcomingPageSet) {
      const pageSet = new PageSet(this)
      this._pageSets.push(pageSet)
      this._upcomingPageSetUuid = pageSet.uuid
      pageSet.initFetch(startingPage)
      return pageSet._initPagePromise(startingPage)
    }
    return this.upcomingPageSet._initPagePromise(startingPage)
  }

  getRecord (id) {
    return find(this.allRecords, r => r.id === id)
  }

  /**
   * Clear the contents of the store by removing all of the page sets.
   */
  @action
  _clearStore () {
    this._pageSets.clear()
  }

  _sortString () {
    return this._filter.sortOrder ? this._filter.sortOrder.map(sort => `${sort.sortField}|${sort.direction}`).join(',') : ''
  }

  @action
  _fetchOne () {
    throw new Error('Virtual Function: Must override function `BaseStore._fetchOne`.')
  }

  @action
  _fetchAll (overridePayload) {
    throw new Error('Virtual Function: Must override function `BaseStore._fetchAll`.')
  }

  __debug () {
    const records = this.allRecords
    const filter = cloneDeep(this.filterWithoutSortOrder)
    const pageSets = cloneDeep(toJS(this._pageSets))
    // const pages = pageSets._pages
    const opts = cloneDeep(this.opts)
    return {
      type: this._type,
      filterStr: this.filterStringified,
      sortString: this._sortString(),
      // numPages: size(pageSets._pages),
      pageSets,
      // pages,
      records,
      filter,
      uuid: this._uuid,
      cache: this.cacheValidation,
      createdAt: this._d.createdAt,
      createdAtMoment: moment(this._d.createdAt),
      createdAtMomentFormat: moment(this._d.createdAt).format('YYYY-MM-DDTHH:mm:ss:SSSS'),
      opts,
      paginate: this.opts.paginate,
      hardDisablePagination: this._hardDisablePagination,
    }
  }

  __debugConsole () {
    if (this._d.enabled) {
      /* eslint-disable no-console */
      console.log(`[${this._type}Store]:`, ...arguments)
      /* eslint-enable no-console */
    }
  }

  __debugLog () {
    if (this._d.enabled) {
      /* eslint-disable no-console */
      console.log(`[${this._type}Store]:`, this.__debug())
      /* eslint-enable no-console */
    }
  }

  set debug (d) {
    this._d.enabled = d
  }
}

export default BaseStore
