import Model from './Model'

class Location extends Model {
  constructor (initialData) {
    super(initialData, {
      momentKeys: [
        'created_at',
        'updated_at',
      ],
    })
  }
}

export default Location
