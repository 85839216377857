import OVERSEER from 'Services/NewUserPermissionsService'

const MODEL_NAME = 'Job'

export default class JobPolicy {
  _record = undefined

  constructor (record) {
    this._record = record

    this._canCreateJob = OVERSEER.queryPermissions(MODEL_NAME, this._record, 'jobs.create')
    this._canManageAssignments = OVERSEER.queryPermissions(MODEL_NAME, this._record, 'jobs.manage_assignments')
    this._canUpdateJob = OVERSEER.queryPermissions(MODEL_NAME, this._record, 'jobs.update')
    this._canViewAssignments = OVERSEER.queryPermissions(MODEL_NAME, this._record, 'jobs.view_assignments')
    this._canViewAssignmentsAfterScheduled = OVERSEER.queryPermissions(MODEL_NAME, this._record, 'jobs.view_assignments_after_scheduled')
    this._canViewPayScheduleEstimateAggregate = OVERSEER.queryPermissions(MODEL_NAME, this._record, 'jobs.view_pay_schedule_estimate.aggregate')
    this._canViewPayScheduleEstimateUser = OVERSEER.queryPermissions(MODEL_NAME, this._record, 'jobs.view_pay_schedule_estimate.user')
    this._canViewStats = OVERSEER.queryPermissions(MODEL_NAME, this._record, 'jobs.view_stats')
  }

  get canCreateJob () { return this._canCreateJob.can }
  get canManageAssignments () { return this._canManageAssignments.can }
  get canUpdateJob () { return this._canUpdateJob.can }
  get canViewAssignments () { return this._canViewAssignments.can }
  get canViewAssignmentsAfterScheduled () { return this._canViewAssignmentsAfterScheduled.can }
  get canViewPayScheduleEstimateAggregate () { return this._canViewPayScheduleEstimateAggregate.can }
  get canViewPayScheduleEstimateUser () { return this._canViewPayScheduleEstimateUser.can }
  get canViewStats () { return this._canViewStats.can }
}
