import * as actions from './actions'
import * as getters from './getters'

import mutations from './mutations'

const state = {
  tasks: {},
  taskGroups: {
    requested_events: {
      collapsed: false,
      sortOrder: 1,
      name: 'Accept Event Requests',
    },
    accepted_events: {
      collapsed: false,
      sortOrder: 2,
      name: 'Add Jobs',
    },
    estimatable_events: {
      collapsed: false,
      sortOrder: 3,
      name: 'Send Estimate',
    },
    estimate_declined_events: {
      collapsed: false,
      sortOrder: 4,
      name: 'Send New Estimate',
    },
    estimate_approved_events: {
      collapsed: false,
      sortOrder: 5,
      name: 'Open Bidding',
    },
    bidding_open_events: {
      collapsed: false,
      sortOrder: 6,
      name: 'Close Bidding',
    },
    need_schedule_events: {
      collapsed: false,
      sortOrder: 7,
      name: 'Schedule Personnel',
    },
    finished_events: {
      collapsed: false,
      sortOrder: 8,
      name: 'Send Invoice',
    },
    payment_error_events: {
      collapsed: false,
      sortOrder: 9,
      name: 'Retry Payment',
    },
    invoiced_events: {
      collapsed: false,
      sortOrder: 10,
      name: 'Mark Paid',
    },
    paid_events: {
      collapsed: false,
      sortOrder: 11,
      name: 'Mark Distributed',
    },
  },
  fetchingTasks: true,
}

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
}
