import Model from './Model'

export const contractTypes = {
  OCAC_INSURANCE_LLOYDS_OF_LONDON: 'ocac_insurance_lloyds_of_london',
}

export const deliveryMethods = {
  EMAIL: 'email',
  SMS: 'sms',
}

export const contractStates = {
  CREATED: 'created',
  SENT_TO_API: 'sent_to_api',
  SENT_TO_SIGNER: 'sent_to_signer',
  VIEWED: 'viewed',
  SIGNED: 'signed',
  WITHDRAWN: 'withdrawn',
  ERROR: 'error',
}

class Contract extends Model {
  constructor (initialData) {
    super(initialData, {
      momentKeys: [
        'created_at',
        'updated_at',
        'sent_at',
        'signed_at',
        'withdrawn_at',
      ],
      aasmStates: Object.values(contractStates),
    })
  }

  get deliveryMethodDisplay () {
    switch (this.delivery_method) {
      case deliveryMethods.EMAIL:
        return 'Email'
      case deliveryMethods.SMS:
        return 'SMS'
      default:
        return ''
    }
  }

  get isOutstanding () {
    return this.isSentToApi || this.isSentToSigner || this.isViewed
  }

  get isUsable () {
    return !this.isWithdrawn && !this.isError
  }
}

export default Contract
