export const vueCurrencyFilterOptions = {
  symbol: '$',
  thousandsSeparator: ',',
  fractionCount: 2,
  fractionSeparator: '.',
  symbolPosition: 'front',
  symbolSpacing: false,
}

export const vueScrollToOptions = {
  easing: 'ease-in-out',
  offset: -20,
}

export const defaultOverlayScrollbarOptions = {
  overflowBehavior: {
    x: 'hidden',
    y: 'scroll',
  },
  scrollbars: {
    autoHide: 'leave',
  },
}
