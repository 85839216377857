const ROUTE_SUBSCRIPTIONS = []

class RouterService {
  subscribe (regex, callback) {
    ROUTE_SUBSCRIPTIONS.push({ regex, callback })
  }

  navigate (to) {
    for (let sub of ROUTE_SUBSCRIPTIONS) {
      if (to.match(sub.regex)) {
        return sub.callback(to)
      }
    }

    window.location.assign(to)
  }
}

export default new RouterService()
