import { isUndefined, merge, orderBy, pick } from 'lodash'

import TimeService from 'Services/TimeService'
import axios from './axios'
import moment from 'moment'

const baseUrlString = '/personnel_types'

const rateFields = [
  'rate_per_hour',
  'alcohol_hourly_rate',
  'holiday_hourly_rate',
  'other_hourly_rate',
  'rate_per_assignment',
]

const cleanCreateParams = [
  ...rateFields,
  'agency_id',
  'name',
  'pay_schedule_id',
]

const cleanUpdateParams = [
  ...cleanCreateParams,
  'active',
]

const PersonnelType = function (initialData) {
  var personnelType = {}

  personnelType.$reinitialize = function (data) {
    merge(personnelType, data)

    personnelType.created_at = moment.utc(data.created_at)
    personnelType.created_at_date = TimeService.momentFormatInLocalOffset(data.created_at, 'MM/DD/YYYY HH:mm')

    rateFields.forEach(function (field) {
      if (!isUndefined(personnelType[field])) {
        personnelType[`_${field}`] = parseFloat(personnelType[field]).toFixed(2)
      }
    })

    return personnelType
  }

  personnelType.$reinitialize(initialData)

  personnelType.$cleanForCreate = function () {
    rateFields.forEach(function (field) {
      if (!isUndefined(personnelType[`_${field}`])) {
        personnelType[field] = personnelType[`_${field}`].toString()
      }
    })
    return pick(personnelType, cleanCreateParams)
  }

  personnelType.$cleanForUpdate = function () {
    rateFields.forEach(function (field) {
      personnelType[field] = personnelType[`_${field}`].toString()
    })
    return pick(personnelType, cleanUpdateParams)
  }

  personnelType.$prepareForCreate = function () {
    let pt = personnelType.$cleanForCreate()
    pt.agency_id = window.dkVariables.agency_id
    return pt
  }

  personnelType.$prepareForUpdate = function () {
    return personnelType.$cleanForUpdate()
  }

  return personnelType
}

const PersonnelTypes = {
  create: async function (personnelType) {
    return axios.post(baseUrlString, {
      agency_officer_type: personnelType.$prepareForCreate(),
    }).then(function (response) {
      return PersonnelTypes.initWithData(response.data)
    })
  },
  update: async function (personnelType) {
    return axios.put(`${baseUrlString}/${personnelType.id}`, {
      agency_officer_type: personnelType.$prepareForUpdate(),
    }).then(function (response) {
      return PersonnelTypes.initWithData(response.data)
    })
  },
  getAll: async function (agencyId) {
    return axios.get(baseUrlString + '?agency_id=' + agencyId)
      .then(function (response) {
        return orderBy(response.data.map(personnelType => {
          return PersonnelTypes.initWithData(personnelType)
        }, ['name']))
      }).catch(function (error) {
        console.error(error.message)
      })
  },
  getById: async function (id) {
    return axios.get(baseUrlString + '/' + id)
      .then(function (response) {
        return PersonnelTypes.initWithData(response.data)
      }).catch(function (error) {
        console.error(error.message)
      })
  },
  initBlank: function () {
    let blankData = {
      name: '',
      pay_schedule_id: null,
    }

    return PersonnelTypes.initWithData(blankData)
  },
  initBlankWithRates: function () {
    let blankData = {
      name: '',
      pay_schedule_id: null,
    }

    rateFields.forEach(function (field) {
      blankData[field] = '0.0'
    })

    return PersonnelTypes.initWithData(blankData)
  },
  initWithData: function (data) {
    return new PersonnelType(data)
  },
}

export default PersonnelTypes
