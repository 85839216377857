import { includes } from 'lodash'
import { observable } from 'mobx'
import moment from 'moment'

import EventPolicy from 'Models/policies/EventPolicy'

import Business from './Business'
import Location from './Location'
import Model, { CommonApiColumnExcludes } from './Model'
import { UserStateTransformations } from 'Utils'

export const stateGroups = {
  open: [
    'requested',
    'accepted',
    'estimated',
    'estimate_approved',
    'estimate_declined',
    'bidding_closed',
    'scheduled',
    'active',
    'finished',
  ],
  processing: [
    'invoiced',
    'attempting_payment',
    'awaiting_payment_settlement',
    'paid',
    'payment_error',
    'attempting_distribution',
    'distribution_error',
  ],
  closed: [
    'cancelled',
    'rejected',
    'distributed',
  ],
}

export const stateGroupNames = Object.keys(stateGroups)

export const allStates = Object.keys(stateGroups).reduce((arr, group) => {
  return arr.concat(stateGroups[group])
}, [])

export const stateGroupsForProgressBar = [
  ['cancelled', 'requested', 'rejected', 'accepted'],
  ['estimated', 'estimate_declined', 'estimate_approved'],
  ['bidding_open', 'bidding_closed'],
  ['scheduled', 'active', 'finished'],
  ['invoiced', 'attempting_payment', 'awaiting_payment_settlement', 'payment_error', 'paid',
    'attempting_distribution', 'distribution_error', 'distributed'],
]

const superConstructorConfig = {
  momentKeys: [
    'created_at',
    'updated_at',
    'start_date',
    'end_date',
    'derived_start_date',
    'derived_end_date',
    'finished_at',
    'activated_at',
    'auto_scheduled_at',
    'bidding_start_date',
    'bidding_end_date',
  ],
  modelKeyMaps: {
    business: Business,
    location: Location,
    locations: Location,
  },
}

const ModelSpecificApiKeysToExclude = [
  'locations',
]

class Event extends Model {
  @observable policy = null

  constructor (initialData) {
    super(initialData, superConstructorConfig)

    if (this.public_event_id) {
      this._public_event_id_parts = splitPublicEventId(this.public_event_id)
      this._public_event_id_no_prefix = publicEventIdWithoutPrefix(this.public_event_id)
    } else {
      this._public_event_id_no_prefix = 'n/a'
    }

    this.policy = new EventPolicy(this)
  }

  dataForApi () {
    let removeKeys = [].concat(CommonApiColumnExcludes)
      .concat(ModelSpecificApiKeysToExclude)
      .concat(Object.keys(superConstructorConfig['modelKeyMaps']))

    let keys = Object.keys(this).filter(k => {
      return !removeKeys.includes(k)
    })

    let apiObject = {}
    keys.forEach(k => {
      apiObject[k] = this[k]
    })
    return apiObject
  }

  get permissionsAgencyId () {
    return parseInt(this.agency_id)
  }

  get permissionsBusinessId () {
    return parseInt(this.business_id)
  }

  get permissionsOwnedByUserId () {
    // TODO: if we don't want this to work at all do we just return null?
    return parseInt(this.user_id)
  }

  get isV2 () {
    return parseInt(this.invoice_version) === 2
  }

  get isV1 () {
    return parseInt(this.invoice_version) === 1
  }

  get fullAddress () {
    return `${this.location_name} ${this.location_address} ${this.location_address_2} ${this.city} ${this.state} ${this.zip_code}`
  }

  get eventState () {
    return UserStateTransformations.convertEventState(this.aasm_state)
  }

  set eventState (newState) {
    return false
  }

  get relevantStartDate () {
    return this.derived_start_date || this.start_date
  }

  get relevantEndDate () {
    return this.derived_end_date || this.end_date
  }

  // Permission Checks

  // NEW PERMISSION STYLE BEGIN
  get canClone () { return this.policy.canClone }
  mayClone () { return this.isV2 }
  get canMayClone () { return this.canClone && this.mayClone() }

  get canAccept () { return this.policy.canAccept }
  mayAccept () { return this.isRequested() && !this.hasBlockingApprovalRequests() }
  get canMayAccept () { return this.canAccept && this.mayAccept() }

  get canUnaccept () { return this.policy.canUnaccept }
  mayUnaccept () { return this.isAccepted() }
  get canMayUnaccept () { return this.canUnaccept && this.mayUnaccept() }

  get canReject () { return this.policy.canReject }
  mayReject () { return this.isRequested() && !this.hasActiveApprovalRequests() }
  get canMayReject () { return this.canReject && this.mayReject() }

  get canInteractingAsAgency () { return this.policy.canInteractingAsAgency }
  get canInteractingAsBusiness () { return this.policy.canInteractingAsBusiness }

  get canInvoice () {
    return this.policy.canInvoice
    // this._rawPermissions['event.invoice']
  }
  mayInvoice () {
    return (this.isFinished() ||
    this.isPaymentError() ||
    (this.isInvoiced() && this.isManualPaymentMethod()))
  }
  get canMayInvoice () { return this.canInvoice && this.mayInvoice() }

  get canViewInvoice () {
    return this.policy.canViewInvoice
  }
  mayViewInvoice () {
    return this.isInvoiced() || this.isInBillingState()
  }
  get canMayViewInvoice () {
    return this.canViewInvoice && this.mayViewInvoice()
  }

  get canEstimate () {
    return this.policy.canEstimate
  }

  mayEstimate () {
    return (this.isAccepted() ||
        this.isEstimated() ||
        this.isEstimateDeclined() ||
        this.isEstimateApproved()
      ) &&
      this.hasJobsOrEventFees()
  }

  get canMayEstimate () {
    return this.canEstimate && this.mayEstimate()
  }

  get canViewEstimate () {
    return this.policy.canViewEstimate
  }
  mayViewEstimate () {
    return this.hasEstimateToView()
  }
  get canMayViewEstimate () {
    return this.canViewEstimate && this.mayViewEstimate()
  }

  // canApproveEstimate () {
  //   return this._rawPermissions['event.approve_estimate'] &&
  //     this.isEstimated()
  // }

  get canApproveEstimate () {
    return this.policy.canApproveEstimate
  }
  mayApproveEstimate () {
    return this.isEstimated()
  }
  get canMayApproveEstimate () {
    return this.canApproveEstimate && this.mayApproveEstimate()
  }

  // canDeclineEstimate () {
  //   return this._rawPermissions['event.decline_estimate'] &&
  //     this.isEstimated()
  // }

  get canDeclineEstimate () {
    return this.policy.canDeclineEstimate
  }
  mayDeclineEstimate () {
    return this.isEstimated()
  }
  get canMayDeclineEstimate () {
    return this.canDeclineEstimate && this.mayDeclineeEstimate()
  }

  get canDefaultFilterAllJobs () {
    return this.policy.canDefaultFilterAllJobs
  }

  get canViewActivityLog () {
    return this.policy.canViewActivityLog
  }

  get canShowOverviewTab () {
    return this.policy.canShowOverviewTab
  }

  get canViewExpenseCategorySummary () {
    return this.policy.canViewExpenseCategorySummary
  }

  get canViewApprovalRequests () {
    return this.policy.canViewApprovalRequests
  }

  get canViewNotes () {
    return this.policy.canViewNotes
  }

  get canViewRoster () {
    return this.policy.canViewRoster
  }

  get canManageBidding () {
    return this.policy.canOpenBidding || this.policy.canCloseBidding ||
           this.policy.canSkipBidding || this.policy.canReopenBidding
  }
  mayManageBidding () {
    return !this.isCancelled() && !this.isRejected()
  }
  get canMayManageBidding () {
    return this.canManageBidding && this.mayManageBidding()
  }

  get canOpenBidding () {
    return this.policy.canOpenBidding
  }
  mayOpenBidding () {
    return (this.isEstimated() || this.isEstimateApproved()) && !this.bidding_start_date && this.relevantEndDate.isAfter(moment())
  }
  get canMayOpenBidding () {
    return this.canOpenBidding && this.mayOpenBidding()
  }

  get canCloseBidding () {
    return this.policy.canCloseBidding
  }
  mayCloseBidding () {
    return this.isEstimateApproved() && this.isFutureEvent() && this.isBiddingOpen()
  }
  get canMayCloseBidding () {
    return this.canCloseBidding && this.mayCloseBidding()
  }

  get canSkipBidding () {
    return this.policy.canSkipBidding
  }
  maySkipBidding () {
    return this.isEstimateApproved()
  }
  get canMaySkipBidding () {
    return this.canSkipBidding && this.maySkipBidding()
  }

  get canReopenBidding () {
    return this.policy.canReopenBidding
  }
  mayReopenBidding () {
    return (this.isBiddingClosed() || this.isScheduled()) && moment().isBefore(this.relevantStartDate)
  }
  get canMayReopenBidding () {
    return this.canReopenBidding && this.mayReopenBidding()
  }

  get canSchedule () {
    return this.policy.canSchedule
  }
  maySchedule () {
    return this.isBiddingClosed() || this.isScheduled() || this.isActive()
  }
  get canMaySchedule () {
    return this.canSchedule && this.maySchedule()
  }

  get canCancel () { return this.policy.canCancel }
  get canCancelWhileRequested () { return this.policy.canCancelWhileRequested }
  get canMayCancel () {
    return (
      this.canCancel && this.mayCancelAfterRequested()
    ) || (
      this.canCancelWhileRequested && this.mayCancelWhileRequested()
    )
  }
  mayCancelWhileRequested () { return this.isRequested() }
  mayCancelAfterRequested () { return !this.isActive() && !this.isInDistributionState() && this.permissionStatusCheck() }

  get canViewFees () {
    return this.policy.canViewFees
  }
  mayViewFees () {
    return true
  }
  get canMayViewFees () {
    return this.canViewFees && this.mayViewFees()
  }

  get canAddFees () {
    return this.policy.canAddFees
  }
  mayAddFees () {
    return this.permissionStatusCheck()
  }
  get canMayAddFees () {
    return this.canAddFees && this.mayAddFees()
  }

  get canDeleteFees () {
    return this.policy.canDeleteFees
  }
  mayDeleteFees () {
    return this.permissionStatusCheck()
  }
  get canMayDeleteFees () {
    return this.canDeleteFees && this.mayDeleteFees()
  }

  get canEditFees () {
    return this.policy.canEditFees || this.policy.canUpdateFees
  }
  mayEditFees () {
    return this.permissionStatusCheck()
  }
  get canMayEditFees () {
    return this.canEditFees && this.mayEditFees()
  }

  get canViewJobStats () {
    return this.policy.canViewJobStats
  }

  canCreateJobsAndEventFees () {
    return this.canMayCreateJob &&
      this.canMayAddFees
  }

  // canManageAssignments () {
  //   return this._rawPermissions['assignments.manage'] && this.permissionStatusCheck()
  // }

  get canManageAssignments () {
    return this.policy.canManageAssignments
  }
  mayManageAssignments () {
    return this.permissionStatusCheck()
  }
  get canMayManageAssignments () {
    return this.canManageAssignments && this.mayManageAssignments()
  }

  get canUpdateJob () {
    return this.policy.canUpdateJob
  }
  mayUpdateJob () {
    return !this.isPastFinished() && !(this.isCancelled() || this.isRejected())
  }
  get canMayUpdateJob () {
    return this.canUpdateJob && this.mayUpdateJob()
  }

  get canCreateJob () {
    return this.policy.canCreateJob
  }
  mayCreateJob () {
    return !this.isPastFinished() && !(this.isCancelled() || this.isRejected())
  }
  get canMayCreateJob () {
    return this.canCreateJob && this.mayCreateJob()
  }

  // canUpdateJob () {
  //   return this._rawPermissions['jobs.update'] && !this.isPastFinished() && !(this.isCancelled() || this.isRejected())
  // }

  // canViewEventCategoryId () {
  //   return this._rawPermissions['event.view_event_category_id']
  // }

  get canViewEventCategoryId () {
    return this.policy.canViewEventCategoryId
  }

  get canEditName () { return this.policy.canEditName }
  get canMayEditName () { return this.canEditName && this.mayEditName() }
  mayEditName () { return this.permissionStatusCheck() }

  // canEditEventCategoryId () {
  //   return this._rawPermissions['event.edit_event_category_id'] && this.permissionStatusCheck()
  // }

  get canEditEventCategoryId () {
    return this.policy.canEditEventCategoryId
  }
  mayEditEventCategoryId () {
    return this.permissionStatusCheck()
  }
  get canMayEditEventCategoryId () {
    return this.canEditEventCategoryId && this.mayEditEventCategoryId()
  }

  // canCreateEventFees () {
  //   return this._rawPermissions['event_fees.create'] && this.permissionStatusCheck()
  // }

  // canDeleteEventFees () {
  //   return this._rawPermissions['event_fees.delete'] && this.permissionStatusCheck()
  // }

  // canEditEventFees () {
  //   return this._rawPermissions['event_fees.update'] && this.permissionStatusCheck()
  // }

  // canViewStartDate () {
  //   return this._rawPermissions['event.view_start_date']
  // }

  get canViewStartDate () {
    return this.policy.canViewStartDate
  }

  // canEditStartDate () {
  //   return this._rawPermissions['event.edit_start_date'] && this.permissionStatusCheck()
  // }

  get canEditStartDate () {
    return this.policy.canEditStartDate
  }
  mayEditStartDate () {
    return this.permissionStatusCheck()
  }
  get canMayEditStartDate () {
    return this.canEditStartDate && this.mayEditStartDate()
  }

  // canViewEndDate () {
  //   return this._rawPermissions['event.view_end_date']
  // }

  get canViewEndDate () {
    return this.policy.canViewEndDate
  }

  // canEditEndDate () {
  //   return this._rawPermissions['event.edit_end_date'] && this.permissionStatusCheck()
  // }

  get canEditEndDate () {
    return this.policy.canEditEndDate
  }
  mayEditEndDate () {
    return this.permissionStatusCheck()
  }
  get canMayEditEndDate () {
    return this.canEditEndDate && this.mayEditEndDate()
  }

  // canViewAlcoholServed () {
  //   return this._rawPermissions['event.view_alcohol_served']
  // }

  get canViewAlcoholServed () {
    return this.policy.canViewAlcoholServed
  }

  // canEditAlcoholServed () {
  //   return this._rawPermissions['event.edit_alcohol_served'] && this.permissionStatusCheck()
  // }

  get canEditAlcoholServed () {
    return this.policy.canEditAlcoholServed
  }
  mayEditAlcoholServed () {
    return this.permissionStatusCheck()
  }
  get canMayEditAlcoholServed () {
    return this.canEditAlcoholServed && this.mayEditAlcoholServed()
  }

  // canViewOvertime () {
  //   return this._rawPermissions['event.view_overtime']
  // }

  get canViewOvertime () {
    return this.policy.canViewOvertime
  }

  get canEditOvertime () {
    return this.policy.canEditOvertime
  }
  mayEditOvertime () {
    return this.permissionStatusCheck()
  }
  get canMayEditOvertime () {
    return this.canEditOvertime && this.mayEditOvertime()
  }

  // canViewDescription () {
  //   return this._rawPermissions['event.view_description']
  // }

  get canViewDescription () {
    return this.policy.canViewDescription
  }

  // canEditDescription () {
  //   return this._rawPermissions['event.edit_description'] && this.permissionStatusCheck()
  // }

  get canEditDescription () {
    return this.policy.canEditDescription
  }
  mayEditDescription () {
    return this.permissionStatusCheck()
  }
  get canMayEditDescription () {
    return this.canEditDescription && this.mayEditDescription()
  }

  // canViewSpecialInstructions () {
  //   return this._rawPermissions['event.view_special_instructions']
  // }

  get canViewSpecialInstructions () {
    return this.policy.canViewSpecialInstructions
  }

  get canEditSpecialInstructions () {
    return this.policy.canEditSpecialInstructions
  }
  mayEditSpecialInstructions () {
    return this.permissionStatusCheck()
  }
  get canMayEditSpecialInstructions () {
    return this.canEditSpecialInstructions && this.mayEditSpecialInstructions()
  }

  // canViewNumberOfOfficers () {
  //   return this._rawPermissions['event.view_number_of_officers']
  // }

  get canViewNumberOfOfficers () {
    return this.policy.canViewNumberOfOfficers
  }

  // canEditNumberOfOfficers () {
  //   return this._rawPermissions['event.edit_number_of_officers'] && this.permissionStatusCheck()
  // }

  get canEditNumberOfOfficers () {
    return this.policy.canEditNumberOfOfficers
  }
  mayEditNumberOfOfficers () {
    return this.permissionStatusCheck()
  }
  get canMayEditNumberOfOfficers () {
    return this.canEditNumberOfOfficers && this.mayEditNumberOfOfficers()
  }

  // canViewEstimatedAttendance () {
  //   return this._rawPermissions['event.view_estimated_attendance']
  // }

  get canViewEstimatedAttendance () {
    return this.policy.canViewEstimatedAttendance
  }

  // canEditEstimatedAttendance () {
  //   return this._rawPermissions['event.edit_estimated_attendance'] && this.permissionStatusCheck()
  // }

  get canEditEstimatedAttendance () {
    return this.policy.canEditEstimatedAttendance
  }
  mayEditEstimatedAttendance () {
    return this.permissionStatusCheck()
  }
  get canMayEditEstimatedAttendance () {
    return this.canEditEstimatedAttendance && this.mayEditEstimatedAttendance()
  }

  // canViewEstimatedVehicles () {
  //   return this._rawPermissions['event.view_estimated_vehicles']
  // }

  get canViewEstimatedVehicles () {
    return this.policy.canViewEstimatedVehicles
  }

  // canEditEstimatedVehicles () {
  //   return this._rawPermissions['event.edit_estimated_vehicles'] && this.permissionStatusCheck()
  // }

  get canEditEstimatedVehicles () {
    return this.policy.canEditEstimatedVehicles
  }
  mayEditEstimatedVehicles () {
    return this.permissionStatusCheck()
  }
  get canMayEditEstimatedVehicles () {
    return this.policy.canEditEstimatedVehicles && this.mayEditEstimatedVehicles()
  }

  // canViewGateCode () {
  //   return this._rawPermissions['event.view_gate_code']
  // }

  get canViewGateCode () {
    return this.policy.canViewGateCode
  }

  // canEditGateCode () {
  //   return this._rawPermissions['event.edit_gate_code'] && this.permissionStatusCheck()
  // }

  get canEditGateCode () {
    return this.policy.canEditGateCode
  }
  mayEditGateCode () {
    return this.permissionStatusCheck()
  }
  get canMayEditGateCode () {
    return this.canEditGateCode && this.mayEditGateCode()
  }

  // canViewDepositType () {
  //   return this._rawPermissions['event.view_deposit_type']
  // }

  get canViewDepositType () {
    return this.policy.canViewDepositType
  }

  // canEditDepositType () {
  //   return this._rawPermissions['event.edit_deposit_type']
  // }

  get canEditDepositType () {
    return this.policy.canEditDepositType
  }

  // canViewDepositAmount () {
  //   return this._rawPermissions['event.view_deposit_amount']
  // }

  get canViewDepositAmount () {
    return this.policy.canViewDepositAmount
  }

  // canEditDepositAmount () {
  //   return this._rawPermissions['event.edit_deposit_amount']
  // }

  get canEditDepositAmount () {
    return this.policy.canEditDepositAmount
  }

  // canViewLockboxCode () {
  //   return this._rawPermissions['event.view_lockbox_code']
  // }

  get canViewLockboxCode () {
    return this.policy.canViewLockboxCode
  }

  // canEditLockboxCode () {
  //   return this._rawPermissions['event.edit_lockbox_code'] && this.permissionStatusCheck()
  // }

  get canEditLockboxCode () {
    return this.policy.canEditLockboxCode
  }
  mayEditLockboxCode () {
    return this.permissionStatusCheck()
  }
  get canMayEditLockboxCode () {
    return this.canEditLockboxCode && this.mayEditLockboxCode()
  }

  // canViewCustomInvoiceId () {
  //   return this._rawPermissions['event.view_custom_invoice_id']
  // }

  get canViewCustomInvoiceId () {
    return this.policy.canViewCustomInvoiceId
  }

  get canEditCustomInvoiceId () {
    return this.policy.canEditCustomInvoiceId
  }

  // canViewBusinessId () {
  //   return this._rawPermissions['event.view_business_id']
  // }

  get canViewBusinessId () {
    return this.policy.canViewBusinessId
  }

  // canEditBusinessId () {
  //   return this._rawPermissions['event.edit_business_id'] && this.permissionStatusCheck()
  // }

  get canEditBusinessId () {
    return this.policy.canEditBusinessId
  }
  mayEditBusinessId () {
    return this.permissionStatusCheck()
  }
  get canMayEditBusinessId () {
    return this.canEditBusinessId && this.mayEditBusinessId()
  }

  // canViewContact1Id () {
  //   return this._rawPermissions['event.view_contact_1_id']
  // }

  get canViewContact1Id () {
    return this.policy.canViewContact1Id
  }

  // canEditContact1Id () {
  //   return this._rawPermissions['event.edit_contact_1_id'] && this.permissionStatusCheck()
  // }

  get canEditContact1Id () {
    return this.policy.canEditContact1Id
  }
  mayEditContact1Id () {
    return this.permissionStatusCheck()
  }
  get canMayEditContact1Id () {
    return this.canEditContact1Id && this.mayEditContact1Id()
  }

  // canViewPermit () {
  //   return this._rawPermissions['event.view_permit']
  // }

  get canViewPermit () {
    return this.policy.canViewPermit
  }

  // canEditPermit () {
  //   return this._rawPermissions['event.edit_permit'] && this.permissionStatusCheck()
  // }

  get canEditPermit () {
    return this.policy.canEditPermit
  }
  mayEditPermit () {
    return this.permissionStatusCheck()
  }
  get canMayEditPermit () {
    return this.canEditPermit && this.mayEditPermit()
  }

  // canViewBiddingStartDate () {
  //   return this._rawPermissions['event.view_bidding_start_date']
  // }

  get canViewBiddingStartDate () {
    return this.policy.canViewBiddingStartDate
  }

  get canEditBiddingStartDate () {
    return this.policy.canEditBiddingStartDate
  }
  mayEditBiddingStartDate () {
    return this.isBeforeEstimateApproved() || this.isEstimateApproved() || this.isBiddingOpen()
  }
  get canMayEditBiddingStartDate () {
    return this.canEditBiddingStartDate && this.mayEditBiddingStartDate()
  }

  // canViewBiddingEndDate () {
  //   return this._rawPermissions['event.view_bidding_end_date']
  // }

  get canViewBiddingEndDate () {
    return this.policy.canViewBiddingEndDate
  }

  get canEditBiddingEndDate () {
    return this.policy.canEditBiddingEndDate
  }
  mayEditBiddingEndDate () {
    return this.isBeforeEstimateApproved() || this.isEstimateApproved() || this.isBiddingOpen()
  }
  get canMayEditBiddingEndDate () {
    return this.canEditBiddingEndDate && this.mayEditBiddingEndDate()
  }

  get canViewPaymentsAndDistributions () {
    return this.policy.canViewPaymentsAndDistributions
  }

  get canViewAssignments () {
    return this.policy.canViewAssignments
  }
  mayViewAssignments () {
    const notAllowedStates = [
      'requested', 'accepted',
      'estimated', 'estimate_approved',
      'estimate_declined', 'bidding_closed',
    ]

    return this.policy.canViewAssignmentsAfterScheduled && !includes(notAllowedStates, this.aasm_state)
  }
  get canMayViewAssignments () {
    return this.canViewAssignments || this.mayViewAssignments()
  }

  // canViewAssignments () {
  //   const notAllowedStates = [
  //     'requested', 'accepted',
  //     'estimated', 'estimate_approved',
  //     'estimate_declined', 'bidding_closed',
  //   ]
  //   return this._rawPermissions['event.can_view_assignments'] || this._rawPermissions['event.can_view_my_assignments'] || (this._rawPermissions['event.can_view_assignments_after_scheduled'] && !includes(notAllowedStates, this.aasm_state))
  // }

  // canViewLocation () {
  //   return this._rawPermissions['event.view_location']
  // }

  get canViewLocation () {
    return this.policy.canViewLocation
  }

  // canEditLocation () {
  //   return this._rawPermissions['event.edit_location'] && this.permissionStatusCheck()
  // }

  get canEditLocation () {
    return this.policy.canEditLocation
  }
  mayEditLocation () {
    return this.permissionStatusCheck()
  }
  get canMayEditLocation () {
    return this.canEditLocation && this.mayEditLocation()
  }

  get canViewPaymentMethodType () {
    return this.policy.canViewPaymentMethodType
  }

  // canEditPaymentMethodType () {
  //   return this._rawPermissions['event.edit_payment_method_type'] && (this.isInvoicedOrBefore() || this.isPaymentError())
  // }

  get canEditPaymentMethodType () {
    return this.policy.canEditPaymentMethodType
  }
  mayEditPaymentMethodType () {
    return this.isInvoicedOrBefore() || this.isPaymentError()
  }
  get canMayEditPaymentMethodType () {
    return this.canEditPaymentMethodType && this.mayEditPaymentMethodType()
  }

  get canSelectManualPaymentMethod () {
    return this.policy.canSelectManualPaymentMethod
  }

  // canCreateAttachment () {
  //   return this._rawPermissions['event.create_attachment'] && !this.isCancelled()
  // }

  get canCreateAttachment () {
    return this.policy.canCreateAttachment
  }
  mayCreateAttachment () {
    return !this.isCancelled()
  }
  get canMayCreateAttachment () {
    return this.canCreateAttachment && this.mayCreateAttachment()
  }

  // canRemoveAttachment () {
  //   return this._rawPermissions['event.remove_attachment'] && !this.isPastFinished()
  // }

  get canRemoveAttachment () {
    return this.policy.canRemoveAttachment
  }
  mayRemoveAttachment () {
    return !this.isPastFinished()
  }
  get canMayRemoveAttachment () {
    return this.canRemoveAttachment && this.mayRemoveAttachment()
  }

  // canViewWarnings () {
  //   return this._rawPermissions['event.view_warnings']
  // }

  get canViewWarnings () {
    return this.policy.canViewWarnings
  }

  get canViewJobFilters () {
    return this.policy.canViewJobFilters
  }

  get canEditJobPickupOverrideOption () {
    return this.policy.canEditJobPickupOverrideOption
  }

  get canEditJobNotifyOverrideOption () {
    return this.policy.canEditJobNotifyOverrideOption
  }

  get canViewKommanderPay () {
    return this.policy.canViewKommanderPay
  }

  get canViewPayments () {
    return this.policy.canViewPayments
  }

  get canViewDeposits () {
    return this.policy.canViewDeposits
  }

  get canViewDistributions () {
    return this.policy.canViewDistributions
  }

  get canEditBeforeEventInvoiced () {
    return this.policy.canEditBeforeEventInvoiced
  }

  get canEditBeforeEventEstimated () {
    return this.policy.canEditBeforeEventEstimated
  }

  permissionStatusCheck () {
    // AA's can edit up through the finished state
    const finishedIndex = stateGroups.open.findIndex(state => state === 'finished')
    // BA's can edit before the estimated state
    const estimatedIndex = stateGroups.open.findIndex(state => state === 'estimated')
    const currentStateIndex = stateGroups.open.findIndex(state => state === this.aasm_state)
    return !this.isCancelled() &&
      !this.isRejected() &&
      currentStateIndex >= 0 &&
      ((currentStateIndex <= finishedIndex && this.canEditBeforeEventInvoiced) || // this._rawPermissions['event.edit_before_event_invoiced']
      (currentStateIndex < estimatedIndex && this.canEditBeforeEventEstimated)) // this._rawPermissions['event.edit_before_event_estimated']
  }

  get canSelectUnavailablePaymentMethod () {
    return this.policy.canSelectUnavailablePaymentMethod
  }

  // NEW PERMISSIONING STYLE ENDS

  // Misc Helpers
  isManualPaymentMethod () {
    return this.payment_method_type === 'ManualPaymentMethod'
  }

  isCreditCardPaymentMethod () {
    return this.payment_method_type === 'CreditCard'
  }

  isBankAccountPaymentMethod () {
    return this.payment_method_type === 'BankAccount'
  }

  isElectronicPaymentMethod () {
    return !this.isManualPaymentMethod()
  }

  isPastEvent () {
    return this.relevantEndDate.isBefore()
  }

  isFutureEvent () {
    return this.relevantStartDate.isAfter()
  }

  isBiddingOpen () {
    return this.isEstimateApproved() && this.isBetweenBiddingDates() && !!this.ready_for_bidding_at
  }

  isBetweenBiddingDates () {
    return this.bidding_start_date && this.bidding_start_date.isBefore() &&
      (!this.bidding_end_date || this.bidding_end_date.isAfter())
  }

  hasEstimateToView () {
    return this.last_estimate_id !== null
  }

  // State Checks
  isRequested () {
    return this.aasm_state === 'requested'
  }

  isAccepted () {
    return this.aasm_state === 'accepted'
  }

  isRejected () {
    return this.aasm_state === 'rejected'
  }

  isEstimated () {
    return this.aasm_state === 'estimated'
  }

  isEstimateApproved () {
    return this.aasm_state === 'estimate_approved'
  }

  isEstimateDeclined () {
    return this.aasm_state === 'estimate_declined'
  }

  isBiddingClosed () {
    return this.aasm_state === 'bidding_closed'
  }

  isScheduled () {
    return this.aasm_state === 'scheduled'
  }

  isActive () {
    return this.aasm_state === 'active'
  }

  isFinished () {
    return this.aasm_state === 'finished'
  }

  isInvoiced () {
    return this.aasm_state === 'invoiced'
  }

  isAttemptingPayment () {
    return this.aasm_state === 'attempting_payment'
  }

  isAwaitingPaymentSettlement () {
    return this.aasm_state === 'awaiting_payment_settlement'
  }

  isPaid () {
    return this.aasm_state === 'paid'
  }

  isPaymentError () {
    return this.aasm_state === 'payment_error'
  }

  isAttemptingDistribution () {
    return this.aasm_state === 'attempting_distribution'
  }

  isDistributed () {
    return this.aasm_state === 'distributed'
  }

  isDistributionError () {
    return this.aasm_state === 'distribution_error'
  }

  isDistributionBlocked () {
    return this.aasm_state === 'distribution_blocked'
  }

  isCancelled () {
    return this.aasm_state === 'cancelled'
  }

  isInPaymentState () {
    return this.isAttemptingPayment() ||
      this.isPaid() ||
      this.isPaymentError() ||
      this.isAwaitingPaymentSettlement()
  }

  isInDistributionState () {
    return this.isAttemptingDistribution() ||
      this.isDistributed() ||
      this.isDistributionError() ||
      this.isDistributionBlocked()
  }

  isInBillingState () {
    return this.isInPaymentState() ||
      this.isInDistributionState()
  }

  isOver () {
    return this.isFinished() ||
      this.isInvoiced() ||
      this.isInBillingState()
  }

  hasNotStarted () {
    return !this.isOver() && !this.isActive()
  }

  isPastFinished () {
    return this.isInvoiced() || this.isInBillingState()
  }

  isInAJobPickupableState () {
    return this.isScheduled() || this.isActive()
  }

  hasBlockingApprovalRequests () {
    return this.cached_metadata.has_blocking_approval_requests
  }

  hasActiveApprovalRequests () {
    return this.cached_metadata.has_active_approval_requests
  }

  hasCurrentApprovedApprovalRequests () {
    return this.cached_metadata.has_current_approved_approval_requests
  }

  hasCurrentDeniedApprovalRequests () {
    return this.cached_metadata.has_current_denied_approval_requests
  }

  get awaitingResponseApproverIds () {
    return this.cached_metadata.awaiting_response_approver_ids
  }

  hasJobs () {
    return this.cached_metadata.number_of_jobs && this.cached_metadata.number_of_jobs > 0
  }

  hasEventFees () {
    return this.cached_metadata.number_of_event_fees && this.cached_metadata.number_of_event_fees > 0
  }

  hasJobsOrEventFees () {
    return this.hasJobs() || this.hasEventFees()
  }

  areBiddingDatesNull () {
    return !this.bidding_start_date && !this.bidding_end_date
  }

  // this is for when we add a bidding on/off toggle on the event - defaults to true
  isBiddingEnabled () {
    return true
  }

  wasBiddingSkipped () {
    return this.areBiddingDatesNull() && !this.bidding_skipped_dt
  }

  isBeforeEstimateApproved () {
    return this.isRequested() || this.isAccepted() || this.isEstimated()
  }

  isBeforeBiddingOpen () {
    return (this.isAccepted() || this.isRequested() || this.isEstimated() || this.isEstimateApproved()) && !this.isBiddingOpen()
  }

  isAfterBidding () {
    return !this.isBeforeBiddingOpen() && !this.isBiddingOpen()
  }

  isBeforeActive () {
    return this.isBeforeBiddingOpen() || this.isBiddingClosed() || this.isScheduled()
  }

  isInvoicedOrBefore () {
    return this.isRequested() || this.isAccepted() || this.isEstimated() || this.isEstimateApproved() || this.isEstimateDeclined() ||
           this.isBiddingClosed() || this.isScheduled() || this.isActive() || this.isFinished() || this.isInvoiced()
  }

  biddingDescriptionText () {
    if (!this.isBiddingEnabled()) {
      return 'is disabled'
    } else if (this.wasBiddingSkipped()) {
      return 'was skipped'
    } else if (!this.bidding_start_date || !this.bidding_end_date) {
      return 'dates not set'
    } else if (this.isBeforeBiddingOpen()) {
      return `opens in ${this.bidding_start_date.toNow(true)}`
    } else if (this.isBiddingOpen()) {
      return `closes in ${this.bidding_end_date.toNow(true)}`
    } else if (this.isBiddingEnabled() && (this.isBiddingClosed() || this.isAfterBidding())) {
      return `closed ${this.bidding_end_date.fromNow(true)} ago`
    }
  }
}

export function splitPublicEventId (id) {
  const lastIdx = id.lastIndexOf('-')
  const firstIdx = id.substring(0, lastIdx).lastIndexOf('-')

  const prefix = id.substring(0, firstIdx)
  const year = id.substring(firstIdx + 1, lastIdx)
  const number = id.slice(lastIdx + 1)

  return [ prefix, year, number ]
}

export function publicEventIdWithoutPrefix (id) {
  const [, part2, part3] = splitPublicEventId(id)
  return `${part2}-${part3}`
}

export default Event
