import StoreInstances, { getStoreFor } from 'Stores/StoreInstances'

import BaseStore from 'Stores/base_store/BaseStore'
import Events from 'DataServices/Events'
import StoreChangeBus from 'Services/StoreChangeBus'
import { action } from 'mobx'
import { getAssignmentsStoreFor } from 'Stores/AssignmentsStore'

const STORE_TYPE = 'Events'

export function getEventsStoreFor (param, opts = {}) {
  return getStoreFor(EventsStore, STORE_TYPE, param, {
    forceUnique: false,
    preload: opts.preload,
    autoFetch: opts.autoFetch,
    fetchAll: opts.fetchAll,
  })
}

const validApprovalRequestActions = ['add', 'remove', 'update']

StoreChangeBus.subscribe(evt => {
  if (evt.type === 'Jobs' && (evt.action === 'add' || evt.action === 'remove')) {
    // Refresh the associated Event record when a Job is added or removed.
    Events.getById(evt.data.event_id)
      .then(events => StoreInstances._propagateChanges(STORE_TYPE, events))
  } else if (evt.type === 'Notes' && (evt.action === 'add' || evt.action === 'remove')) {
    // Refresh the associated Event record when a Note is added or removed.
    Events.getById(evt.data.noteable_id)
      .then(events => StoreInstances._propagateChanges(STORE_TYPE, events))
  } else if (evt.type === 'ApprovalRequests' && (validApprovalRequestActions.includes(evt.action))) {
    Events.getById(evt.data.approvable_id)
      .then(events => StoreInstances._propagateChanges(STORE_TYPE, events))
  }
})

function reloadAssignmentsForEvent (eventId) {
  const assignmentStore = getAssignmentsStoreFor({ event_id: eventId })
  assignmentStore.reload()
}

export class EventsStore extends BaseStore {
  constructor (seed) {
    const opts = {
      paginate: true,
    }
    super(STORE_TYPE, seed, opts)
  }

  @action
  create (event, attachmentIds) {
    const payload = {
      event,
      attachment_ids: attachmentIds,
    }
    return Events.create(payload)
      .then(evt => {
        StoreInstances._propagateChanges(STORE_TYPE, [evt])
        return evt
      })
      .catch(console.error)
  }

  @action
  update (event) {
    const payload = {
      ...event,
    }
    return Events.update(payload)
      .then(evt => {
        StoreInstances._propagateChanges(STORE_TYPE, [evt])
        return evt
      })
  }

  @action
  acceptEvent ({ eventId }) {
    return Events.accept({ eventId })
      .then(evt => {
        StoreInstances._propagateChanges(STORE_TYPE, [evt])
        return evt
      })
      .catch(console.error)
  }

  @action
  unacceptEvent ({ eventId }) {
    return Events.unaccept({ eventId })
      .then(evt => {
        StoreInstances._propagateChanges(STORE_TYPE, [evt])
        return evt
      })
      .catch(console.error)
  }

  @action
  rejectEvent ({ eventId, reason }) {
    return Events.reject({ eventId, reason })
      .then(evt => {
        StoreInstances._propagateChanges(STORE_TYPE, [evt])
        return evt
      })
  }

  @action
  cancelEvent ({ eventId, reason }) {
    return Events.cancel({ eventId, reason })
      .then(evt => {
        StoreInstances._propagateChanges(STORE_TYPE, [evt])
        return evt
      })
  }

  @action
  openBidding ({ eventId }) {
    return Events.openBidding({ eventId })
      .then(evt => {
        StoreInstances._propagateChanges(STORE_TYPE, [evt])
        return evt
      })
  }

  @action
  closeBidding ({ eventId }) {
    return Events.closeBidding({ eventId })
      .then(evt => {
        StoreInstances._propagateChanges(STORE_TYPE, [evt])
        return evt
      })
  }

  @action
  skipBidding ({ eventId }) {
    return Events.skipBidding({ eventId })
      .then(evt => {
        StoreInstances._propagateChanges(STORE_TYPE, [evt])
        return evt
      })
  }

  @action
  reopenBidding ({ eventId }) {
    return Events.reopenBidding({ eventId })
      .then(evt => {
        StoreInstances._propagateChanges(STORE_TYPE, [evt])
        return evt
      })
  }

  @action
  schedule ({ eventId }) {
    return Events.schedule({ eventId })
      .then(evt => {
        reloadAssignmentsForEvent(eventId)
        StoreInstances._propagateChanges(STORE_TYPE, [evt])
        return evt
      })
  }

  @action
  clone (reqData) {
    return Events.clone(reqData)
      .then(evt => {
        StoreInstances._propagateChanges(STORE_TYPE, [evt])
        return evt
      })
  }

  @action
  cloneAndAccept (reqData) {
    return Events.cloneAndAccept(reqData)
      .then(evt => {
        StoreInstances._propagateChanges(STORE_TYPE, [evt])
        return evt
      })
  }

  @action
  _fetchAll (overridePayload) {
    const payload = overridePayload || {
      ...this._filter,
    }
    return Events.getAll(payload)
  }

  @action
  _fetchOne () {
    return Events.getById(this._filter.id)
  }
}
