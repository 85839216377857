import { action, toJS } from 'mobx'
import { cloneDeep, invokeMap, isEqual, some, sortBy } from 'lodash'

export function filterSortMerge (params, sortOrder) {
  return cloneDeep(toJS({
    ...params,
    sortOrder,
  }))
}

export function getStoreFor (LocalStore, type, param, opts = {}) {
  const forceUnique = opts.forceUnique
  const preload = opts.preload
  const autoFetch = opts.autoFetch === undefined ? true : opts.autoFetch
  const fetchAll = opts.fetchAll
  // If it's an object as a param, we want the `type` param ONLY in the lookup.
  // If we attach it directly to the param object, it'll be set in the filter below as well.
  let lookupParam = (param && typeof param === 'object') ? { type, filter: param } : param
  let store = globalStoreInstance.lookupStore(lookupParam)
  if (!store || forceUnique) {
    const createNewStore = () => {
      let s = new LocalStore()
      if (param && typeof param === 'object') {
        s.setFilter(param, { preload, autoFetch, fetchAll })
      } else {
        s.setOpts({ preload, autoFetch, fetchAll })
      }
      return s
    }

    if (param && typeof param === 'object' && param.hasOwnProperty('id')) {
      // If we don't find a perfect match on the store, but the filter has an id and we
      // find a store with that record in it, then we clone that record into a new
      // store with the id as the filter.
      const containingStore = globalStoreInstance.storeContainingRecord(type, param.id)
      if (containingStore) {
        let record = containingStore.getRecord(param.id)
        store = new LocalStore({
          data: record,
          filter: { id: param.id },
        })
      } else {
        store = createNewStore()
      }
    } else {
      // Otherwise, just make a new store with the filter.
      store = createNewStore()
    }
  }

  if (forceUnique) {
    store.unique = true
  }

  store.reloadIfCacheInvalid()
  return store
}

class StoreInstances {
  stores = {}

  lookupStore (param) {
    let store = null
    if (typeof param === 'string') {
      store = this.stores[param]
    } else if (param && typeof param === 'object') {
      let typeStores = this._storesOfType(param.type)
      store = typeStores.find(st => isEqual(st._filter, param.filter))
    }
    return store
  }

  storeContainingRecord (storeType, id) {
    const stores = this._storesOfType(storeType)
    return stores.find((store) => storeContainingRecordFilter(store, id))
  }

  storesContainingRecord (storeType, id) {
    const stores = this._storesOfType(storeType)
    return stores.filter((store) => storeContainingRecordFilter(store, id))
  }

  registerStore (instance) {
    this.stores[instance.uuid] = instance
  }

  disposeStore (uuid) {
    this.stores[uuid].dispose()
    delete this.stores[uuid]
  }

  /**
   * Private Methods
   */
  @action
  _propagateChanges (storeType, changedRecords, action) {
    this._storesOfType(storeType).forEach(store => {
      store.propagateChanges(changedRecords, action)
    })
    return changedRecords
  }

  _storesOfType (storeType) {
    return Object.values(this.stores).filter(store => store._type === storeType)
  }

  __debug (type) {
    return sortBy(invokeMap(type ? this._storesOfType(type) : this.stores, '__debug'), 'type')
  }
}

function storeContainingRecordFilter (store, id) {
  let pages = []
  if (!store.currentPageSet) return false
  for (const p of store.currentPageSet._pages.values()) {
    pages.push(p)
  }
  return some(pages, page => some(page.records, rec => rec.id === id))
}

const globalStoreInstance = new StoreInstances()
window.globalStore = globalStoreInstance
export default globalStoreInstance
