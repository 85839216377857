import Tasks from 'DataServices/Tasks'
import * as types from './mutation_types'
import { WindowVariables } from 'Utils'

export const getTasks = async ({ commit }) => {
  commit(types.FETCHING_TASKS)
  let taskGroups = await Tasks.getAll(WindowVariables.agencyId)
  commit(types.RECEIVE_TASKS, { taskGroups })
  commit(types.FETCHED_TASKS)
}

export const toggleTaskGroup = ({ commit }, payload) => {
  commit(types.TOGGLE_TASK_GROUP, payload)
}

export const collapseAllTaskGroups = ({ commit }) => {
  commit(types.COLLAPSE_ALL_TASK_GROUPS)
}

export const expandAllTaskGroups = ({ commit }) => {
  commit(types.EXPAND_ALL_TASK_GROUPS)
}
