<template>
  <ks-user-name-view v-if="userId" :user="user" v-bind="$attrs" />
</template>

<script>
import { getUsersMinStoreFor } from 'Stores/UsersMinStore'
import KsUserNameView from 'Components/KsUserNameView'

export default {
  name: 'ks-user-name',
  data () {
    return {
      usersMinStore: getUsersMinStoreFor(),
    }
  },
  components: {
    KsUserNameView,
  },
  fromMobx: {
    user () {
      return this.usersMinStore.allRecords.find(u => u.id === this.userId)
    },
  },
  watch: {
    userId: {
      handler (newUserId, oldUserId) {
        if (newUserId && newUserId !== oldUserId) {
          this.usersMinStore.setFilter({ id: newUserId })
        }
      },
      immediate: true,
    },
  },
  props: {
    userId: {
      type: Number,
    },
  },
}
</script>
