import Model from './Model'

export const tags = {
  PRIMARY: 'primary',
  NOTIFICATIONS: 'notifications',
  FINANCE: 'finance',
}

class EmailAddress extends Model {
  constructor (initialData, { timezone = null }) {
    super(initialData, {
      momentKeys: [
        'created_at',
        'inactive_at',
        'suppressed_at',
        'updated_at',
        'verified_at',
      ],
      timezone,
    })
  }

  get active () {
    return this.inactive_at === null
  }

  get inactive () {
    return !this.active
  }

  get suppressed () {
    return this.suppressed_at !== null
  }

  get useable () {
    return this.active && !this.suppressed
  }
}

export default EmailAddress
