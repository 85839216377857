import * as actions from './actions'
import * as getters from './getters'
import mutations from './mutations'

const state = {
  personnelTypes: {},
  fetchingPersonnelTypes: true,
}

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
}
