import * as types from './mutation_types'

import { filter, first, orderBy, size } from 'lodash'

import Vue from 'vue'

export default {
  [types.RECEIVE_PAY_SCHEDULES] (state, { paySchedules }) {
    paySchedules.forEach(paySchedule => {
      addPaySchedule(state, paySchedule)
    })
  },
  [types.RECEIVE_PAY_SCHEDULE] (state, { paySchedule }) {
    addPaySchedule(state, paySchedule)
  },
  [types.RECEIVE_PAY_SCHEDULE_ENTITIES] (state, { payScheduleEntities }) {
    payScheduleEntities.forEach(payScheduleEntity => {
      addPayScheduleEntity(state, payScheduleEntity)
    })
  },
  [types.RECEIVE_EXPENSE_CATEGORIES] (state, { expenseCategories }) {
    expenseCategories.forEach(expenseCategory => {
      addExpenseCategory(state, expenseCategory)
    })
  },
  [types.RECEIVE_EXPENSE_CATEGORY] (state, { expenseCategory }) {
    addExpenseCategory(state, expenseCategory)
  },
  [types.SELECT_PAY_SCHEDULE] (state, { payScheduleId }) {
    state.selectedPayScheduleId = payScheduleId
    var filteredList = orderBy(filter(state.payScheduleEntities, {
      'pay_schedule_id': state.selectedPayScheduleId,
    }), ['id'], ['desc'])
    state.selectedPayScheduleEntityId = size(filteredList) > 0 ? first(filteredList).id : null
  },
  [types.SELECT_PAY_SCHEDULE_ENTITY] (state, { payScheduleEntityId }) {
    state.selectedPayScheduleEntityId = payScheduleEntityId
  },
  [types.SAVING_PAY_SCHEDULE_ENTITY] (state) {
    state.savingPayScheduleEntity = true
  },
  [types.SAVED_PAY_SCHEDULE_ENTITY] (state) {
    state.savingPayScheduleEntity = false
  },
  [types.API_ERROR] (state, { error }) {
    state.apiError = error
  },
  [types.RECEIVE_PAY_SCHEDULE_ENTITY] (state, { payScheduleEntity }) {
    addPayScheduleEntity(state, payScheduleEntity)
  },
}

function addPaySchedule (state, paySchedule) {
  Vue.set(state.paySchedules, paySchedule.id, paySchedule)
  addPayScheduleEntity(state, paySchedule.pay_schedule_entity)
}

function addPayScheduleEntity (state, payScheduleEntity) {
  Vue.set(state.payScheduleEntities, payScheduleEntity.id, payScheduleEntity)
}

function addExpenseCategory (state, expenseCategory) {
  Vue.set(state.expenseCategories, expenseCategory.id, expenseCategory)
}
