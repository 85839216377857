import * as types from './mutation_types'

import Vue from 'vue'
import { each } from 'lodash'

export default {
  [types.RECEIVE_TASKS] (state, { taskGroups }) {
    clearTasks(state)
    each(taskGroups, function (tasks, groupName) {
      addTasks(state, tasks, groupName)
    })
  },
  [types.TOGGLE_TASK_GROUP] (state, { taskGroup }) {
    setGroupCollapsedState(state, taskGroup, !state.taskGroups[taskGroup].collapsed)
  },
  [types.COLLAPSE_ALL_TASK_GROUPS] (state) {
    each(state.taskGroups, function (tasks, taskGroup) {
      setGroupCollapsedState(state, taskGroup, true)
    })
  },
  [types.EXPAND_ALL_TASK_GROUPS] (state) {
    each(state.taskGroups, function (tasks, taskGroup) {
      setGroupCollapsedState(state, taskGroup, false)
    })
  },
  [types.FETCHING_TASKS] (state) {
    state.fetchingTasks = true
  },
  [types.FETCHED_TASKS] (state) {
    state.fetchingTasks = false
  },
  [types.API_ERROR] (state, { error }) {
    state.apiError = error
  },
}

function clearTasks (state) {
  state.tasks = {}
}

function addTasks (state, tasks, groupName) {
  Vue.set(state.tasks, groupName, tasks)
}

function setGroupCollapsedState (state, taskGroup, collapsed) {
  state.taskGroups[taskGroup].collapsed = collapsed
}
