import { camelCase, each, isString } from 'lodash'

import Assignment from './Assignment'
import { DEFAULT_JOB_GROUP_NAME } from 'Utils/constants'
import Event from './Event'
import Model from './Model'
import { RoundIfNotWhole } from 'Utils'
import TimeService from 'Services/TimeService'
import { insuranceAssignmentAddonNames } from './AssignmentAddon'
import JobPolicy from 'Models/policies/JobPolicy'

class Job extends Model {
  constructor (initialData) {
    super(initialData, {
      momentKeys: [
        'start_date',
        'end_date',
        'created_at',
        'updated_at',
        'deleted_at',
      ],
      modelKeyMaps: {
        assignments: Assignment,
        event: Event,
      },
    })

    if (isString(this.cached_metadata)) {
      this.cached_metadata = JSON.parse(this.cached_metadata)
    }

    // Create getters for all cached metadata fields
    // e.g. Make `cachedNumPrimaries` getter for `cached_metadata.num_primaries`
    Object.keys(this.cached_metadata).forEach(key => {
      const fnName = camelCase(`cached_${key}`)

      const fn = function () {
        return this.cached_metadata[key]
      }

      Object.defineProperty(this, fnName, { get: fn })
    })

    this.policy = new JobPolicy(this)
  }

  get permissionsAgencyId () {
    return parseInt(this.agency_id)
  }

  get permissionsBusinessId () {
    return parseInt(this.business_id)
  }

  get permissionsOwnedByUserId () {
    // TODO: if we don't want this to work at all do we just return null?
    return null
  }

  // NEW PERMISSIONING STYLE BEGIN

  get canManageAssignments () {
    return this.policy.canManageAssignments
  }

  get canUpdateJob () {
    return this.policy.canUpdateJob
  }

  get canViewStats () {
    return this.policy.canViewStats
  }

  get canViewAssignments () {
    return this.policy.canViewAssignments && this.policy.canViewAssignmentsAfterScheduled
  }

  get canViewPayScheduleEstimateAggregate () {
    return this.policy.canViewPayScheduleEstimateAggregate
  }

  get canViewPayScheduleEstimateUser () {
    return this.policy.canViewPayScheduleEstimateUser
  }

  get canCreateJob () {
    return this.policy.canCreateJob
  }

  // NEW PERMISSIONING STYLE END

  // Returns the total number of hours available by job duration * job quantity
  totalNumHours () {
    return this.lengthInHours() * this.officer_qty
  }

  lengthInHours () {
    return this.end_date.diff(this.start_date, 'hours', true)
  }

  lengthInHoursRounded () {
    return RoundIfNotWhole(this.lengthInHours(), 2)
  }

  isInDefaultGroup () {
    return this.job_group === ''
  }

  hasActiveBidForUserId (id) {
    return this.cached_metadata.active_bid_user_ids.includes(id)
  }

  hasActiveBidForUser (user) {
    return this.hasActiveBidForUserId(user.id)
  }

  hasCancelledBidForUserId (id) {
    return this.cached_metadata.cancelled_bid_user_ids.includes(id)
  }

  hasCancelledBidForUser (user) {
    return this.hasCancelledBidForUserId(user.id)
  }

  hasPrimaryAssignmentForUserId (id) {
    return this.cached_metadata.active_scheduled_primary_user_ids.includes(id)
  }

  hasPrimaryAssignmentForUser (user) {
    return this.hasPrimaryAssignmentForUserId(user.id)
  }

  hasAcceptedPrimaryAssignmentForUserId (id) {
    return this.cached_metadata.accepted_primary_user_ids.includes(id)
  }

  hasAcceptedPrimaryAssignmentForUser (user) {
    return this.hasAcceptedPrimaryAssignmentForUserId(user.id)
  }

  hasAssignedPrimaryAssignmentForUserId (id) {
    return this.cached_metadata.assigned_primary_user_ids.includes(id)
  }

  hasAssignedPrimaryAssignmentForUser (user) {
    return this.hasAssignedPrimaryAssignmentForUserId(user.id)
  }

  hasAlternateAssignmentForUserId (id) {
    return this.cached_metadata.active_scheduled_alternate_user_ids.includes(id)
  }

  hasAlternateAssignmentForUser (user) {
    return this.hasAlternateAssignmentForUserId(user.id)
  }

  hasAcceptedAlternateAssignmentForUserId (id) {
    return this.cached_metadata.accepted_alternate_user_ids.includes(id)
  }

  hasAcceptedAlternateAssignmentForUser (user) {
    return this.hasAcceptedAlternateAssignmentForUserId(user.id)
  }

  hasAssignedAlternateAssignmentForUserId (id) {
    return this.cached_metadata.assigned_alternate_user_ids.includes(id)
  }

  hasAssignedAlternateAssignmentForUser (user) {
    return this.hasAssignedAlternateAssignmentForUserId(user.id)
  }

  get hasOpenSlots () {
    return this.cached_metadata.num_open_slots > 0
  }

  get hasNotStarted () {
    return this.start_date.isAfter(/* defaults current time */)
  }

  get groupName () {
    return this.job_group === '' ? DEFAULT_JOB_GROUP_NAME : this.job_group
  }

  get minimalTimeRange () {
    return TimeService.minimalTwixFormat(this.start_date.twix(this.end_date), true, false)
  }

  get formattedTimeRange () {
    return TimeService.formattedTwixFormat(this.start_date.twix(this.end_date), false, false)
  }

  get minimalStartTime () {
    return TimeService.momentFormat(this.start_date, false, false)
  }

  get jobNameDisplay () {
    return (this.job_name && this.job_name) !== '' ? this.job_name : 'Job'
  }

  get canChangeActivityLogOptions () {
    return !(this.cached_metadata.num_completed_primaries > 0)
  }

  get hasEnoughBids () {
    return this.cached_metadata.num_submitted_bids >= this.officer_qty
  }

  get hasIncompleteAssignments () {
    return this.cached_metadata.num_completed_primaries < this.cached_metadata.num_primaries
  }

  get hasCancellationRequests () {
    return (this.cached_metadata.num_cancel_requested - this.cached_metadata.num_cancelled_primaries) > 0
  }

  get hasUnacknowledgedAssignments () {
    return this.cached_metadata.assigned_primary_user_ids.length > 0
  }

  get hasAnyAssignmentInsurance () {
    for (let addonName of Object.values(insuranceAssignmentAddonNames)) {
      if (this._hasAssignmentAddonByName(addonName)) {
        return true
      }
    }
    return false
  }

  get hasOcacInsuranceLloydsOfLondon () {
    return this._hasAssignmentAddonByName(insuranceAssignmentAddonNames.OCAC_INSURANCE_LLOYDS_OF_LONDON)
  }

  get allEnabledInsuranceAssignmentAddons () {
    return Object.values(insuranceAssignmentAddonNames).filter(addonName => {
      return this._hasAssignmentAddonByName(addonName)
    })
  }

  _hasAssignmentAddonByName (addonName) {
    return !!this.computed_assignment_addon_settings[addonName]
  }

  diff (job) {
    let diff = {}

    let straightComparisonKeys = [
      'id', 'agency_officer_type_id', 'job_type_id', 'event_id', 'officer_qty', 'event_id', 'job_name', 'job_group',
      'created_by_id', 'notes', 'business_id', 'overtime', 'pay_schedule_id', 'pay_schedule_entity_id',
      'allow_job_pickup_override',
    ]
    each(straightComparisonKeys, key => {
      if (job[key] !== this[key]) {
        diff[key] = job[key]
      }
    })

    let momentComparisonKeys = [
      'start_date', 'end_date',
    ]
    each(momentComparisonKeys, key => {
      if (job[key].format() !== this[key].format()) {
        diff[key] = job[key].clone().toISOString()
      }
    })

    return diff
  }
}

export default Job
