import FlashMessages from 'Services/FlashMessages'
import { WindowVariables } from 'Utils'

function newAppVersionInterceptor (response) {
  const availableVersion = response && response.headers['js-version']
  if (availableVersion) {
    if (window.dkLastAlertedVersion !== availableVersion &&
        WindowVariables.currentVersion !== availableVersion) {
      FlashMessages.info(
        'A new version of Detail Kommander is available, click this message or refresh the page to get it.',
        'Update Available',
        {
          timeout: false,
          onClick: () => { location.reload(true) },
        }
      )
      window.dkLastAlertedVersion = availableVersion
    }
  }

  return response
}

function currentUserSuspendedStatusInterceptor (response) {
  const header = response && response.headers['current-user-suspended']
  const latestStatus = header ? JSON.parse(header.toLowerCase()) : undefined
  if (latestStatus !== undefined && latestStatus !== null) {
    if (window.dkLastAlertedUserSuspendedStatus !== latestStatus && WindowVariables.currentUser.suspended !== latestStatus) {
      if (latestStatus) {
        location.reload(true)
      } else {
        _redirectDeactivatedUser()
      }
      return Promise.reject(new Error('User status has changed.'))
    }
    window.dkLastAlertedUserSuspendedStatus = latestStatus
  }

  return response
}

function forceRefreshInterceptor (response) {
  _forceRefreshIfNecessary(response)
  return response
}

function forceRefreshErrorInterceptor (error) {
  _forceRefreshIfNecessary(error.response)
}

function _forceRefreshIfNecessary (response) {
  const header = response.headers['force-refresh']
  const shouldForceRefresh = header ? JSON.parse(header) : false

  if (shouldForceRefresh) {
    _redirectDeactivatedUser()
  }
}

function _redirectDeactivatedUser () {
  location.replace('/user_deactivated')
}

function policyRequestInterceptors (request) {
  if (isKeyPresent(request, 'policy')) {
    findAndDeleteKey(request, 'policy')
  }

  return request
}

function isKeyPresent (obj, targetKey) {
  // Base case: if the current object is not an object or is null, stop the recursion
  if (typeof obj !== 'object' || obj === null) {
    return false
  }

  // Check if the target key exists in the current object
  if (targetKey in obj) {
    return true
  }

  // Iterate over all properties of the current object
  for (const key in obj) {
    // Recursively check nested objects
    if (isKeyPresent(obj[key], targetKey)) {
      return true
    }
  }

  // If the key is not found in the current object or its nested objects, return false
  return false
}

function findAndDeleteKey (obj, targetKey, currentPath = []) {
  if (typeof obj !== 'object' || obj === null) {
    return null // Key not found
  }

  if (targetKey in obj) {
    return currentPath // Key found, return the array of parent keys
  }

  for (const key in obj) {
    const nestedPath = currentPath.concat(key)
    const correctPath = findAndDeleteKey(obj[key], targetKey, nestedPath)
    if (correctPath !== null) {
      deleteKeyByPath(obj, correctPath)
    }
  }

  return null // Key not found in the current object or its nested objects
}

function deleteKeyByPath (obj, path) {
  let currentObj = obj
  for (let i = 0; i < path.length - 1; i++) {
    const key = path[i]
    if (currentObj[key] !== undefined) {
      currentObj = currentObj[key]
    } else {
      return // Path not found, nothing to delete
    }
  }
  const lastKey = path[path.length - 1]
  if (lastKey in currentObj) {
    delete currentObj[lastKey]
  }
}

export function registerInterceptors (axiosInstance) {
  axiosInstance.interceptors.response.use(forceRefreshInterceptor, forceRefreshErrorInterceptor)
  axiosInstance.interceptors.response.use(currentUserSuspendedStatusInterceptor)
  axiosInstance.interceptors.response.use(newAppVersionInterceptor)
  axiosInstance.interceptors.request.use(policyRequestInterceptors)
}
