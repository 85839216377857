<template>
  <div class="btn-wrapper" v-tooltip.top="showTooltip && tooltipDisplay">
    <component
      :is="componentType"
      v-bind="buttonAttrs"
      class="icon-button"
      :class="{
        'icon-button--disabled': isDisabled,
        'icon-button--with-border': showBorder,
        'no-underline': isLink,
      }"
      :style="style"
      @click="onClick"
    >
      <transition name="fade">
        <div class="icon-button__spinner">
          <span
            v-if="loading"
            class="ks-spinner"
            :class="{ 'ks-spinner--dark': !primary }"
          ></span>
        </div>
      </transition>

      <div
        class="icon-button__label"
        :class="{ 'btn__label--hidden': loading }"
      >
        <slot />
      </div>
    </component>
  </div>
</template>

<script>
  import ButtonMixin from 'Mixins/ButtonMixin'

  export default {
    name: 'ksIconButton',
    mixins: [ButtonMixin],
    computed: {
      style () {
        if (this.hoverBackgroundColor) {
          return {
            '--hover-bg-color': this.hoverBackgroundColor,
          }
        } else {
          return {}
        }
      },
    },
    props: {
      disabled: {
        type: Boolean,
        default: false,
      },
      hoverBackgroundColor: {
        type: String,
        default: '',
      },
      showBorder: {
        type: Boolean,
        default: false,
      },
    },
  }
</script>

<style lang="scss" scoped>
  @use "~Styles/colors";
  @use "~Styles/variables";
  @use "~Styles/mixins";

  .icon-button {
    --hover-bg-color: #{colors.$kommander-hover-bg};
    $p: &;
    color: inherit;
    padding: variables.$icon-button-padding;
    background-color: transparent;
    border: solid 1px transparent;
    border-radius: 2px;

    &:not(.icon-button--disabled) {
      @include mixins.clickable();
      @include mixins.no-select();

      transition-property: background-color, border-color;
      transition-duration: variables.$hover-transition-duration;
      transition-timing-function: variables.$hover-transition-timing-function;

      &:hover {
        background-color: var(--hover-bg-color);
        border-color: colors.$kommander-icon-button-border;
      }
    }

    &--disabled {
      opacity: 0.5;
    }

    &--with-border {
      border-color: colors.$kommander-icon-button-border;
    }

    &__spinner {
      align-items: center;
      display: flex;
      justify-content: center;
      position: absolute;

      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }

    &__label {
      align-items: center;
      display: flex;
      justify-content: center;
      transition: opacity 0.5s;

      &--hidden {
        opacity: 0;
      }
    }
  }
</style>
