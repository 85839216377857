import axios from './axios'
import VirtualBankAccount from 'Models/VirtualBankAccount'
import { filter, includes } from 'lodash'
import BaseDataService from 'DataServices/BaseDataService'
import defer from 'Utils/defer'

export function baseIndexUrlString () {
  return `/virtual_bank_accounts`
}

class VirtualBankAccounts extends BaseDataService {
  async getAll (params) {
    return axios.get(baseIndexUrlString(), { params: params })
      .then(response => response.data)
      .then(respData => {
        return Promise.all(respData.data.map(async vba => {
          return newVirtualBankAccount(vba)
        }))
          .then(vbas => {
            return { data: vbas, links: respData.links }
          })
      })
      .catch(console.error)
  }

  async getById (id) {
    const deferred = defer()

    if (!id) {
      console.error('Argument Missing: id is required')
    }

    const callback = (records, ids) => {
      // console.log('CALLBACK', records, ids)
      if (ids) {
        deferred.resolve(filter(records, r => includes(ids, r.id)))
      } else {
        deferred.reject([])
      }
    }

    this._createOrAddRequestToBatch(this._createDataRequest([id], callback))

    return deferred.promise
  }

  async update (data) {
    const id = data.id
    delete data.id
    return axios.put(`${baseIndexUrlString()}/${id}`, { virtual_bank_account: { ...data } })
        .then(response => newVirtualBankAccount(response.data))
        .catch(console.error)
  }

  async create (agencyId, data) {
    return axios.post(`agencies/${agencyId}/${baseIndexUrlString()}/`, { virtual_bank_account: { ...data } })
      .then(response => newVirtualBankAccount(response.data))
      .catch(console.error)
  }
}

function newVirtualBankAccount (data) {
  return new VirtualBankAccount(data)
}

export default new VirtualBankAccounts()
