<script>
  export default {
    computed: {
      buttonAttrs () {
        const attrs = {}

        if (this.isLink) {
          attrs.href = this.href
          attrs.target = this.target
        } else if (this.isRouterLink) {
          attrs.to = this.to
        } else {
          attrs.disabled = this.isDisabled
          attrs.type = this.type
        }

        return attrs
      },
      isLink () {
        return this.href !== undefined
      },
      isRouterLink () {
        return this.to !== undefined
      },
      componentType () {
        if (this.isLink) {
          return 'a'
        } else if (this.isRouterLink) {
          return 'router-link'
        } else {
          return 'button'
        }
      },
      isDisabled () {
        return this.disabled || this.loading
      },
      showDisabledTooltip () {
        return this.disabled && this.disabledTooltip !== ''
      },
      showNormalTooltip () {
        return !this.disabled && this.tooltip !== ''
      },
      showTooltip () {
        return this.showDisabledTooltip || this.showNormalTooltip
      },
      tooltipDisplay () {
        const tooltipOptions = {
          content: '',
          delay: { show: 1000 },
        }

        if (this.showDisabledTooltip) {
          return {
            ...tooltipOptions,
            content: this.disabledTooltip || '',
          }
        } else if (this.showNormalTooltip) {
          return {
            ...tooltipOptions,
            content: this.tooltip || '',
          }
        } else {
          return {
            ...tooltipOptions,
            content: '',
          }
        }
      },
    },
    methods: {
      onClick (event) {
        if (this.stopPropagation) {
          event.stopPropagation()
        }

        if (this.loading || this.disabled) {
          event.preventDefault()
          return null
        } else if (this.activityType) {
          // TODO: We should really return the `event` in every case to allow `stopPropogation` to
          // work from outside this component
          this.$emit('click', this.activityType)
        } else {
          this.$emit('click', event)
        }
      },
    },
    props: {
      activityType: {
        type: String,
      },
      label: {
        type: String,
      },
      primary: {
        type: Boolean,
        default: false,
      },
      disabled: {
        type: Boolean,
        default: false,
      },
      disabledTooltip: {
        type: String,
        default: '',
      },
      loading: {
        type: Boolean,
        default: false,
      },
      clickFunction: {
        type: Function,
      },
      danger: {
        type: Boolean,
        default: false,
      },
      type: {
        type: String,
        default: 'button',
      },
      href: {
        type: String,
      },
      stopPropagation: {
        type: Boolean,
        default: false,
      },
      target: {
        type: String,
        default: '_self',
      },
      tooltip: {
        type: String,
        default: '',
      },
      to: {
        type: Object,
      },
    },
  }
</script>
