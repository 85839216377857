import OVERSEER from 'Services/NewUserPermissionsService'

const MODEL_NAME = 'Business'

export default class BusinessPolicy {
  _record = undefined

  constructor (record) {
    this._record = record

    this._canViewDkRate = OVERSEER.queryPermissions(MODEL_NAME, this._record, 'business.view_dk_rate')
  }

  get canViewDkRate () { return this._canViewDkRate.can }
}
