<template>
  <transition
    enter-class
    enter-active-class="collapsing"
    enter-to-class
    leave-class
    leave-active-class="collapsing"
    leave-to-class
    @enter="onEnter"
    @after-enter="onAfterEnter"
    @leave="onLeave"
    @after-leave="onAfterLeave"
  >
    <div v-show="show" :class="classObject">
      <slot></slot>
    </div>
  </transition>
</template>

<script>

  export default {
    data () {
      return {
        show: this.visible,
        transitioning: false,
      }
    },
    model: {
      prop: 'visible',
      event: 'input',
    },
    props: {
      accordion: {
        type: String,
        default: null,
      },
      // Whether we should delay expanding the section after `visible` becomes `true`.
      // Number is in ms.
      delayExpandBy: {
        type: Number,
        default: 0,
      },
      visible: {
        type: Boolean,
        default: false,
      },
    },
    watch: {
      visible (newVal) {
        if (newVal !== this.show) {
          setTimeout(() => {
            this.show = newVal
          }, this.delayExpandBy)
        }
      },
    },
    computed: {
      classObject () {
        return {
          'collapse': !this.transitioning,
          'show': this.show && !this.transitioning,
        }
      },
    },
    methods: {
      toggle () {
        this.show = !this.show
      },
      onEnter (el) {
        el.style.height = 0
        this.reflow(el)
        el.style.height = el.scrollHeight + 'px'
        this.transitioning = true
        this.$emit('show')
      },
      onAfterEnter (el) {
        el.style.height = null
        this.transitioning = false
        this.$emit('shown')
      },
      onLeave (el) {
        el.style.height = 'auto'
        el.style.display = 'block'
        el.style.height = el.getBoundingClientRect().height + 'px'
        this.reflow(el)
        this.transitioning = true
        el.style.height = 0
        this.$emit('hide')
      },
      onAfterLeave (el) {
        el.style.height = null
        this.transitioning = false
        this.$emit('hidden')
      },
      reflow (el) {
        /* eslint-disable no-unused-expressions */
        el.offsetHeight // Force repaint
      },
    },
  }

</script>
