import Vue from 'vue'
import * as types from './mutation_types'

export default {
  [types.RECEIVE_PERSONNEL_TYPES] (state, { personnelTypes }) {
    personnelTypes.forEach(personnelType => {
      addPersonnelType(state, personnelType)
    })
  },
  [types.RECEIVE_PERSONNEL_TYPE] (state, { personnelType }) {
    addPersonnelType(state, personnelType)
  },
  [types.FETCHING_PERSONNEL_TYPES] (state) {
    state.fetchingPersonnelType = true
  },
  [types.FETCHED_PERSONNEL_TYPES] (state) {
    state.fetchingPersonnelTypes = false
  },
  [types.SAVING_PERSONNEL_TYPE] (state) {
    state.savingPersonnelTypes = true
  },
  [types.SAVED_PERSONNEL_TYPE] (state) {
    state.savingPersonnelType = false
  },
  [types.API_ERROR] (state, { error }) {
    state.apiError = error
  },
}

function addPersonnelType (state, personnelType) {
  Vue.set(state.personnelTypes, personnelType.id, personnelType)
}
