import { each, find, isArray, union } from 'lodash'

export default class BaseDataService {
  batches = []
  dataRequests = []

  _createDataRequest (ids, callback, mode) {
    const request = { ids, callback, mode }
    this.dataRequests.push(request)
    return request
  }

  _createOrAddRequestToBatch (request) {
    const matchingBatch = find(this.batches, { open: true, mode: request.mode })

    if (matchingBatch) {
      clearTimeout(matchingBatch.timeout)
      matchingBatch.ids = union(matchingBatch.ids, request.ids)

      matchingBatch.requests.push(request)
      matchingBatch.timeout = setTimeout(() => this._processBatch(matchingBatch), 50)
    } else {
      this._createBatch(request)
    }
  }

  _createBatch (request) {
    const newBatch = {
      open: true,
      key: request.key,
      ids: isArray(request.ids) ? request.ids : [request.ids],
      mode: request.mode,
      requests: [request],
      timeout: setTimeout(() => this._processBatch(newBatch), 50),
      globalTimeout: setTimeout(() => {
        clearTimeout(newBatch.timeout)
        this._processBatch(newBatch)
      }, 250),
    }
    this.batches.push(newBatch)
    return newBatch
  }

  _processBatch (batch) {
    batch.open = false

    let data = { ids: batch.ids.join(','), mode: batch.mode }
    if (batch.mode) {
      data.mode = batch.mode
    }

    if (batch.globalTimeout) {
      clearTimeout(batch.globalTimeout)
    }

    this.getAll(data)
      .then(results => {
        // console.log('RETURNED', results, this.dataRequests)
        each(batch.requests, request => {
          // console.log('REQ', request)
          request.callback(results.data, request.ids)
        })
      })
      .catch(resp => {
        console.error('CATCH', resp)
        each(batch.requests, request => {
          request.callback(undefined)
        })
      })
  }
}
