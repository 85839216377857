import Model from './Model'
import GlobalPolicyService from 'Services/GlobalPolicyService'

class Business extends Model {
  constructor (initialData) {
    super(initialData, {
      momentKeys: [
        'created_at',
        'updated_at',
      ],
    })

    this.policy = GlobalPolicyService.findOrCreate('Business', this)
  }

  get permissionsAgencyId () {
    return parseInt(this.agency_id)
  }

  get permissionsBusinessId () {
    return parseInt(this.business_id)
  }

  get permissionsOwnedByUserId () {
    // TODO: if we don't want this to work at all do we just return null?
    return parseInt(this.user_id)
  }

  get isDeactivated () {
    return this.suspended
  }

  get canViewDkRate () {
    return this.policy.canViewDkRate
  }
}

export default Business
