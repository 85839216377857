<template>
  <div
    class="btn-wrapper"
    v-click-outside="closeDropdown"
    :class="{ center: center }"
    v-tooltip.top="showTooltip && tooltipDisplay"
  >
    <component
      :is="componentType"
      v-bind="buttonAttrs"
      class="btn"
      :class="{
        'btn-primary': primary,
        'btn-xs': small,
        'btn-danger': danger,
        'btn-default': !danger,
        'btn--has-dropdown': hasDropdown,
        'btn--has-vertical-margins': hasVerticalMargins,
        'btn--disabled': isDisabled,
        'no-underline': isLink,
      }"
      :style="{ width: width }"
      @click="onClick"
    >
      <transition name="fade">
        <div class="btn__spinner">
          <span
            v-if="loading"
            class="ks-spinner"
            :class="{ 'ks-spinner--dark': !primary }"
          ></span>
        </div>
      </transition>

      <span class="btn__label" :class="{ 'btn__label--hidden': loading }">{{
        label
      }}</span>
    </component>

    <button
      v-if="hasDropdown"
      class="btn btn-dropdown"
      :class="{
        'btn-primary': primary,
        'btn-xs': small,
        'btn-danger': danger,
        'btn--disabled': isDisabled,
        'btn--has-vertical-margins': hasVerticalMargins,
        'btn-default': !danger,
      }"
      :disabled="isDisabled"
      @click.prevent="toggleDropdown"
    >
      <svgicon class="ks-icon-size-xs" icon="ks-icon-caret" />
    </button>

    <transition name="fade">
      <div
        v-if="dropdownVisible"
        class="btn-dropdown-list"
        :class="{
          'btn-dropdown-list--has-vertical-margins': hasVerticalMargins,
        }"
      >
        <ks-button-dropdown-item
          v-for="item in dropdownItems"
          :key="item.label"
          :label="item.label"
          :click-function="item.clickFunction"
          :activity-type="item.activityType"
          :danger="item.danger"
          :stop-propagation="stopPropagation"
          :href="item.href"
          @click="onDropdownItemClick"
        />
      </div>
    </transition>
  </div>
</template>

<script>
  import svgicon from 'vue-svgicon'
  import 'Components/icons'
  import { ClickOutside } from 'Directives/ClickOutside'
  import ButtonMixin from 'Mixins/ButtonMixin.vue'
  import KsButtonDropdownItem from 'Components/form/KsButtonDropdownItem.vue'

  export default {
    name: 'ks-button',
    components: {
      svgicon,
      KsButtonDropdownItem,
    },
    directives: {
      ClickOutside,
    },
    mixins: [ ButtonMixin ],
    data () {
      return {
        dropdownOpen: false,
        hasFocus: false,
        observer: null,
      }
    },
    computed: {
      hasDropdown () {
        return this.dropdownItems.length > 0
      },
      dropdownVisible () {
        return this.dropdownOpen && this.hasDropdown
      },
    },
    methods: {
      closeDropdown () {
        this.dropdownOpen = false
      },
      openDropdown () {
        this.dropdownOpen = true
      },
      toggleDropdown (event) {
        if (this.stopPropagation) {
          event.stopPropagation()
        }

        this.dropdownOpen = !this.dropdownOpen
      },
      onDropdownItemClick (activityType) {
        this.closeDropdown()
        this.$emit('dropdown-click', activityType)
      },
    },
    props: {
      dropdownItems: {
        type: Array,
        default: () => [
          /* { label: 'Cancel Event', href: url, clickFunction: fn, danger: true } */
        ],
      },
      small: {
        type: Boolean,
        default: false,
      },
      width: {
        type: String,
      },
      center: {
        type: Boolean,
        default: false,
      },
      hasVerticalMargins: {
        type: Boolean,
        default: true,
      },
    },
  }

</script>

<style lang="scss">
  .btn-wrapper {
    &.center {
      > .btn {
        margin-left: auto;
        margin-right: auto;
      }
    }

    // I had SUCH a hard time getting this to work correctly.
    // The only way I could make the clone button turn gray was to put the code in this file
    // and make the styles !important. ¯\_(ツ)_/¯
    // TODO: Possibly make this a built-in part of the component, so it makes more sense to
    // have the styles in here, but idk.
    &.grayed-out > button {
      color: #bbb !important;
      border-color: #bbb !important;
    }
  }

  .btn {
    &__spinner {
      align-items: center;
      display: flex;
      justify-content: center;
      position: absolute;

      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }

    &__label {
      transition: opacity 0.5s;

      &--hidden {
        opacity: 0;
      }
    }
  }
</style>
