import OVERSEER from 'Services/NewUserPermissionsService'

const MODEL_NAME = 'Agency'


export default class AgencyPolicy {
  _record = undefined

  constructor (record) {
    this._record = record

    this._canViewAgencyOfficerTypes = OVERSEER.queryPermissions(MODEL_NAME, this._record, 'agency.view_agency_officer_types')
  }

  get canViewAgencyOfficerTypes () { return this._canViewAgencyOfficerTypes.can }
}
