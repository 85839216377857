import { map, merge } from 'lodash'

import axios from './axios'
import moment from 'moment'

const baseUrlString = '/notifications'

const Notification = function (initialData) {
  var notification = {}

  notification.$reinitialize = function (data) {
    merge(notification, data)

    notification.created_at = moment.utc(data.created_at)
    notification.created_at_display = notification.created_at.fromNow()
  }

  notification.$reinitialize(initialData)

  return notification
}

const Notifications = {
  getUnread: async function () {
    return axios.get(`${baseUrlString}?unread=true`)
      .then(function (response) {
        return map(response.data, function (notification) {
          let data = response.data
          data.notifications = map(data.notifications, function (notification) {
            return Notifications.initWithData(notification)
          })
          return data
        })
      }).catch(function (error) {
        console.error(error.message)
      })
  },
  getById: async function (id) {
    return axios.get(`${baseUrlString}/${id}`)
      .then(function (response) {
        return Notifications.initWithData(response.data)
      }).catch(function (error) {
        console.error(error.message)
      })
  },
  getAll: async function (page = 1) {
    return axios.get(`${baseUrlString}?page=${page}`)
      .then(function (response) {
        let data = response.data
        data.notifications = map(data.notifications, function (notification) {
          return Notifications.initWithData(notification)
        })
        return data
      }).catch(function (error) {
        console.error(error.message)
      })
  },
  markRead: async function (id) {
    return axios.put(`${baseUrlString}/${id}/mark_read`)
      .then(function (response) {
        return response.status === 200
      }).catch(function (error) {
        console.error(error.message)
      })
  },
  markUnread: async function (id) {
    return axios.put(`${baseUrlString}/${id}/mark_unread`)
      .then(function (response) {
        return response.status === 200
      }).catch(function (error) {
        console.error(error.message)
      })
  },
  markAllRead: async function (id) {
    return axios.put(`${baseUrlString}/mark_all_read`)
      .then(function (response) {
        return response.status === 200
      }).catch(function (error) {
        console.error(error.message)
      })
  },
  initWithData: function (data) {
    return new Notification(data)
  },
}

export default Notifications
