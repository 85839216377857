import OVERSEER from 'Services/NewUserPermissionsService'

const MODEL_NAME = 'Assignments'

export default class AssignmentsPolicy {
  _record = undefined

  constructor (record) {
    this._record = record

    this._canApproveCancellation = OVERSEER.queryPermissions(MODEL_NAME, this._record, 'assignments.approve_cancellation')
    this._canCreateActivityLogEntry = OVERSEER.queryPermissions(MODEL_NAME, this._record, 'assignments.create_activity_log_entry')
    this._canEdit = OVERSEER.queryPermissions(MODEL_NAME, this._record, 'assignments.edit')
    this._canRequestCancel = OVERSEER.queryPermissions(MODEL_NAME, this._record, 'assignments.request_cancel')
    this._canRequestCancelForOtherUser = OVERSEER.queryPermissions(MODEL_NAME, this._record, 'assignments.request_cancel_for_other_user')
    this._canWithdrawCancel = OVERSEER.queryPermissions(MODEL_NAME, this._record, 'assignments.withdraw_cancel')
    this._canWithdrawCancelForOtherUser = OVERSEER.queryPermissions(MODEL_NAME, this._record, 'assignments.withdraw_cancel_for_other_user')
  }

  get canApproveCancellation () { return this._canApproveCancellation.can }
  get canCreateActivityLogEntry () { return this._canCreateActivityLogEntry.can }
  get canEdit () { return this._canEdit.can }
  get canRequestCancel () { return this._canRequestCancel.can }
  get canRequestCancelForOtherUser () { return this._canRequestCancelForOtherUser.can }
  get canWithdrawCancel () { return this._canWithdrawCancel.can }
  get canWithdrawCancelForOtherUser () { return this._canWithdrawCancelForOtherUser.can }
}
