var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.closeDropdown),expression:"closeDropdown"},{name:"tooltip",rawName:"v-tooltip.top",value:(_vm.showTooltip && _vm.tooltipDisplay),expression:"showTooltip && tooltipDisplay",modifiers:{"top":true}}],staticClass:"btn-wrapper",class:{ center: _vm.center }},[_c(_vm.componentType,_vm._b({tag:"component",staticClass:"btn",class:{
      'btn-primary': _vm.primary,
      'btn-xs': _vm.small,
      'btn-danger': _vm.danger,
      'btn-default': !_vm.danger,
      'btn--has-dropdown': _vm.hasDropdown,
      'btn--has-vertical-margins': _vm.hasVerticalMargins,
      'btn--disabled': _vm.isDisabled,
      'no-underline': _vm.isLink,
    },style:({ width: _vm.width }),on:{"click":_vm.onClick}},'component',_vm.buttonAttrs,false),[_c('transition',{attrs:{"name":"fade"}},[_c('div',{staticClass:"btn__spinner"},[(_vm.loading)?_c('span',{staticClass:"ks-spinner",class:{ 'ks-spinner--dark': !_vm.primary }}):_vm._e()])]),_vm._v(" "),_c('span',{staticClass:"btn__label",class:{ 'btn__label--hidden': _vm.loading }},[_vm._v(_vm._s(_vm.label))])],1),_vm._v(" "),(_vm.hasDropdown)?_c('button',{staticClass:"btn btn-dropdown",class:{
      'btn-primary': _vm.primary,
      'btn-xs': _vm.small,
      'btn-danger': _vm.danger,
      'btn--disabled': _vm.isDisabled,
      'btn--has-vertical-margins': _vm.hasVerticalMargins,
      'btn-default': !_vm.danger,
    },attrs:{"disabled":_vm.isDisabled},on:{"click":function($event){$event.preventDefault();return _vm.toggleDropdown.apply(null, arguments)}}},[_c('svgicon',{staticClass:"ks-icon-size-xs",attrs:{"icon":"ks-icon-caret"}})],1):_vm._e(),_vm._v(" "),_c('transition',{attrs:{"name":"fade"}},[(_vm.dropdownVisible)?_c('div',{staticClass:"btn-dropdown-list",class:{
        'btn-dropdown-list--has-vertical-margins': _vm.hasVerticalMargins,
      }},_vm._l((_vm.dropdownItems),function(item){return _c('ks-button-dropdown-item',{key:item.label,attrs:{"label":item.label,"click-function":item.clickFunction,"activity-type":item.activityType,"danger":item.danger,"stop-propagation":_vm.stopPropagation,"href":item.href},on:{"click":_vm.onDropdownItemClick}})}),1):_vm._e()])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }