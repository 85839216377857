import { map, merge } from 'lodash'
import axios from './axios'
import Assignment from 'Models/Assignment'

const baseUrlString = '/assignments'

const Assignments = {
  getById: async function ({ id }) {
    return axios.get(`${baseUrlString}/${id}`)
      .then(response => newAssignment(response.data))
      .catch(console.error)
  },
  getAll: async function (params) {
    return axios.get(`${baseUrlString}`, { params })
      .then(response => ({
        links: response.data.links,
        data: map(response.data.data, newAssignment),
      }))
      .catch(console.error)
  },
  accept: async function (id) {
    return axios.put(`${baseUrlString}/${id}/accept`)
      .then(resp => newAssignment(resp.data))
      .catch(console.error)
  },
  reject: async function (id) {
    return axios.put(`${baseUrlString}/${id}/reject`)
      .then(resp => newAssignment(resp.data))
      .catch(console.error)
  },
  mark: async function (id, type, params = {}) {
    return axios.put(`${baseUrlString}/${id}/mark`, merge({ type: type }, params))
      .then(resp => newAssignment(resp.data))
      .catch(console.error)
  },
  requestCancellation: async function (id, reason) {
    return axios.put(`${baseUrlString}/${id}/request_cancellation`, reason)
      .then(resp => newAssignment(resp.data))
      .catch(console.error)
  },
  withdrawCancellation: async function (id) {
    return axios.put(`${baseUrlString}/${id}/withdraw_cancellation`)
      .then(resp => newAssignment(resp.data))
      .catch(console.error)
  },
  approveCancellation: async function (id) {
    return axios.put(`${baseUrlString}/${id}/approve_cancellation`)
      .then(resp => newAssignment(resp.data))
      .catch(console.error)
  },
  denyCancellation: async function (id) {
    return axios.put(`${baseUrlString}/${id}/deny_cancellation`)
      .then(resp => newAssignment(resp.data))
      .catch(console.error)
  },
  bulkUpdate: async function (bulkData) {
    return axios.put(`${baseUrlString}/bulk_update`, JSON.stringify({ data: bulkData }), { headers: { 'Content-Type': 'application/json' } })
      .then(response => map(response.data, newAssignment))
      .catch(console.error)
  },
  pickup: async function (jobId) {
    return axios.post(`${baseUrlString}/pickup`, { job_id: jobId })
      .then(resp => newAssignment(resp.data))
      .catch(console.error)
  },
}

function newAssignment (assignment) {
  return new Assignment(assignment)
}

export default Assignments
