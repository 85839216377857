import Model from './Model'

class VirtualBankAccount extends Model {
  constructor (initialData) {
    super(initialData, {
      momentKeys: [
        'created_at',
        'updated_at',
      ],
    })
  }

  get isExternalDistributionAllowed () {
    return !this.internal_only
  }
}

export default VirtualBankAccount
