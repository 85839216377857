import axios from './axios'

const baseUrlString = '/tasks'
const baseAgencyUrlStringPrefix = '/agencies'
const baseAgencyUrlStringSuffix = baseUrlString

const baseAgencyUrlString = function (agencyId) {
  return `${baseAgencyUrlStringPrefix}/${agencyId}/${baseAgencyUrlStringSuffix}`
}

const Tasks = {
  getAll: async function (agencyId) {
    return axios.get(baseAgencyUrlString(agencyId))
      .then(function (response) {
        return response.data
      }).catch(console.error)
  },
}

export default Tasks
