import { fromPairs, map, some, sortBy } from 'lodash'

export const fetchingTasks = state => state.fetchingTasks
export const taskGroups = state => state.taskGroups
export const anyExpanded = state => some(state.taskGroups, function (group) {
  return !group.collapsed
})

export const tasks = state => {
  let taskKeys = Object.keys(state.tasks)
  let sortedTaskKeys = sortBy(taskKeys, [(key) => state.taskGroups[key].sortOrder])

  let sortedTasks = fromPairs(
    map(sortedTaskKeys, key => [key, state.tasks[key]])
  )

  return sortedTasks
}
