import 'moment-timezone'

import { camelCase } from 'lodash'
import moment from 'moment'
// Removed WindowVariables import because of circular reference w/ Agency.js model

export const CommonApiColumnExcludes = [
  'policy',
  'updated_at',
  'created_at',
]

class Model {
  constructor (initialData, {
    momentKeys = [
      // Key names of properties that will be converted into Moment objects
      // e.g. ['start_date', 'end_date']
    ],
    modelKeyMaps = {
      // Property names and the class object used to instantiate its model
      // e.g. { 'event': EventModelClass }
    },
    aasmStates = [
      // Array of aasm states for which to automatically generate getters
      // e.g. { 'new', 'cancelled', 'in_progress' }
    ],
    functionKeyMaps = {
      // Property names and the a function used to instantiate it
      // e.g. { 'event': (event) => new EventModelClass(event) }
    },
    // Allows passing in the timezone for objects that are being instantiated inside WindowVariables,
    // otherwise WindowVariables will be undefined and throw an error when reading the timezone property.
    timezone = null,
  }) {
    for (let key of Object.keys(initialData)) {
      this[key] = initialData[key]
    }

    for (let key of momentKeys) {
      if (this[key]) {
        // Removed WindowVariables import because of circular reference w/ Agency.js model
        this[key] = moment.utc(this[key]).tz(window.dkVariables.time_zone) // WindowVariables.timezone)
      }
    }

    for (let key of Object.keys(modelKeyMaps)) {
      if (this[key]) {
        this[key] = instantiateDataObjectOrArrayWithKlass(this[key], modelKeyMaps[key])
      }
    }

    for (let key of Object.keys(functionKeyMaps)) {
      if (this[key]) {
        this[key] = functionKeyMaps[key](this[key])
      }
    }

    if (this.aasm_state !== undefined) {
      aasmStates.forEach(state => {
        // Changes `state_name` to `isStateName`
        const fnName = camelCase(`is_${state}`)

        const fn = function () {
          return this.aasm_state === state
        }

        // Define a getter of `fnName` that runs function `fn`
        Object.defineProperty(this, fnName, { get: fn })
      })
    }
  }

  throwModelError (error) {
    throw new Error(`'${this.constructor.name}' Model - ${error}`)
  }
}

function instantiateDataObjectOrArrayWithKlass (data, Klass) {
  if (Array.isArray(data)) {
    return data.map(obj => instantiateDataObjectWithKlass(obj, Klass))
  } else {
    return instantiateDataObjectWithKlass(data, Klass)
  }
}

function instantiateDataObjectWithKlass (object, Klass) {
  if (Array.isArray(object)) {
    return object.map(object => instantiateObjectFromKlass(object, Klass))
  } else {
    return instantiateObjectFromKlass(object, Klass)
  }
}

function instantiateObjectFromKlass (object, Klass) {
  return new Klass(object)
}

export default Model
