import StoreInstances, { getStoreFor } from 'Stores/StoreInstances'
import BaseStore from 'Stores/base_store/BaseStore'
import { action } from 'mobx'
import VirtualBankAccounts from 'DataServices/VirtualBankAccounts'

const STORE_TYPE = 'VirtualBankAccounts'

export function getVirtualBankAccountsStoreFor (param, opts = {}) {
  return getStoreFor(VirtualBankAccountsStore, STORE_TYPE, param, {
    forceUnique: false,
    preload: opts.preload,
    autoFetch: opts.autoFetch,
    fetchAll: opts.fetchAll,
  })
}

export class VirtualBankAccountsStore extends BaseStore {
  constructor (seedData) {
    const opts = {
      paginate: true,
    }
    super(STORE_TYPE, seedData, opts)
  }

  @action
  _fetchAll () {
    let payload = {
      ...this.filter,
    }
    return VirtualBankAccounts.getAll(payload)
  }

  @action
  _fetchOne () {
    let payload = {
      ids: this._filter.id,
    }
    return VirtualBankAccounts.getAll(payload)
      .then(response => response.data)
  }

  @action
  update (vba) {
    let payload = {
      ...vba,
    }
    return VirtualBankAccounts.update(payload)
      .then(vba => {
        StoreInstances._propagateChanges(STORE_TYPE, [vba])
        return vba
      })
  }

  @action
  create (agencyId, vba) {
    let payload = {
      ...vba,
    }
    return VirtualBankAccounts.create(agencyId, payload)
      .then(vba => {
        StoreInstances._propagateChanges(STORE_TYPE, [vba])
        return vba
      })
  }
}
