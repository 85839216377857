import * as types from './mutation_types'

import { first, isEmpty, sortBy, toArray } from 'lodash'

import ExpenseCategories from 'DataServices/ExpenseCategories'
import PayScheduleEntities from 'DataServices/PayScheduleEntities'
import PaySchedules from 'DataServices/PaySchedules'
import { WindowVariables } from 'Utils'

export const loadPayScheduleEntityEditor = async ({ dispatch, commit, state }) => {
  await dispatch('getExpenseCategories')
  await dispatch('getPaySchedules')

  if (state.selectedPayScheduleId === null) {
    await dispatch('selectFirstPaySchedule')
  }
}

export const getPaySchedules = async ({ commit }) => {
  let paySchedules = await PaySchedules.getAll(WindowVariables.agencyId)
  commit(types.RECEIVE_PAY_SCHEDULES, { paySchedules })
}

export const getPaySchedule = async ({ commit }, payload) => {
  let paySchedule = await PaySchedules.getById(payload.payScheduleId)
  commit(types.RECEIVE_PAY_SCHEDULE, { paySchedule })
}

export const getPayScheduleEntities = async ({ commit }, payload) => {
  let payScheduleEntities = await PayScheduleEntities.getAllForPaySchedule(payload.payScheduleId)
  commit(types.RECEIVE_PAY_SCHEDULE_ENTITIES, {
    payScheduleId: payload.payScheduleId,
    payScheduleEntities,
  })
}

export const getExpenseCategories = async ({ commit }, payload) => {
  let expenseCategories = await ExpenseCategories.getAll(WindowVariables.agencyId)
  commit(types.RECEIVE_EXPENSE_CATEGORIES, { expenseCategories })
}

export const createExpenseCategory = async ({ dispatch, commit }, payload) => {
  let expenseCategory = await ExpenseCategories.create(WindowVariables.agencyId, payload.name)
  commit(types.RECEIVE_EXPENSE_CATEGORY, { expenseCategory })
  return expenseCategory
}

export const savePayScheduleEntity = async ({ dispatch, commit }, payload) => {
  // Note that we are saving the PSE (mutation)
  commit(types.SAVING_PAY_SCHEDULE_ENTITY)

  // Do the async persisting of the PSE (API)
  try {
    // Add return PS/PSE data to store (mutation)
    let payScheduleEntity = await PayScheduleEntities.create(payload.payScheduleEntity)
    commit(types.RECEIVE_PAY_SCHEDULE_ENTITY, { payScheduleEntity })

    // If this is for a new paySchedule, fetch that and select it
    if (payload.payScheduleEntity.pay_schedule_id === null) {
      await dispatch('getPaySchedule', {
        payScheduleId: payScheduleEntity.pay_schedule_id,
      })
      dispatch('selectPaySchedule', {
        payScheduleId: payScheduleEntity.pay_schedule_id,
      })

    // Otherwise, select the payScheduleEntity
    } else {
      dispatch('selectPayScheduleEntity', {
        payScheduleEntityId: payScheduleEntity.id,
      })
    }
  } catch (error) {
    // Record error, if there is one (mutation)
    commit(types.API_ERROR, { error })
    console.error(error)
  } finally {
    // Note that we are finished saving the PSE (mutation)
    commit(types.SAVED_PAY_SCHEDULE_ENTITY)
  }
}

export const archivePaySchedule = async({ dispatch, commit }, payload) => {
  let paySchedule = await PaySchedules.archive(payload.payScheduleId)
  commit(types.RECEIVE_PAY_SCHEDULE, { paySchedule })
  commit(types.SELECT_PAY_SCHEDULE, {
    payScheduleId: null,
  })
  commit(types.SELECT_PAY_SCHEDULE_ENTITY, {
    payScheduleEntityId: null,
  })
}

export const selectPaySchedule = async ({ dispatch, commit }, payload) => {
  var payScheduleId = payload.payScheduleId

  commit(types.SELECT_PAY_SCHEDULE, {
    payScheduleId,
  })

  if (payScheduleId !== null) {
    await dispatch('getPayScheduleEntities', { payScheduleId })
  }
}

export const selectFirstPaySchedule = async ({ dispatch, commit, state }) => {
  if (!isEmpty(state.paySchedules)) {
    let payScheduleId = first(toArray(sortBy(state.paySchedules, [ps => ps.name.toLowerCase()]))).id
    await dispatch('selectPaySchedule', { payScheduleId })
  }
}

export const selectPayScheduleEntity = ({ dispatch, commit }, payload) => {
  var payScheduleEntityId = payload.payScheduleEntityId
  commit(types.SELECT_PAY_SCHEDULE_ENTITY, {
    payScheduleEntityId,
  })
}

export const selectPayScheduleEntityRule = ({ dispatch, commit }, payload) => {
  var payScheduleEntityRuleIdx = payload.payScheduleEntityRuleIdx
  commit(types.SELECT_PAY_SCHEDULE_ENTITY, {
    payScheduleEntityRuleIdx,
  })
}
