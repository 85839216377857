import { filter, includes } from 'lodash'

import BaseDataService from 'DataServices/BaseDataService'
import User from 'Models/User'
import axios from './axios'
import defer from 'Utils/defer'

export function baseIndexUrlString () {
  return `/users`
}

class Users extends BaseDataService {
  async getAll (params) {
    return axios.get(baseIndexUrlString(), { params: params })
      .then(response => response.data)
      .then(respData => {
        return Promise.all(respData.data.map(async user => {
          return newUser(user)
        }))
          .then(usersArray => {
            return { data: usersArray, links: respData.links }
          })
      })
      .catch(console.error)
  }

  async getById (id, mode) {
    const deferred = defer()

    if (!id) {
      console.error('Argument Missing: id is required')
    }

    const callback = (records, ids) => {
      // console.log('CALLBACK', records, ids)
      if (ids) {
        deferred.resolve(filter(records, r => includes(ids, r.id)))
      } else {
        deferred.reject([])
      }
    }

    this._createOrAddRequestToBatch(this._createDataRequest([id], callback, mode))

    return deferred.promise
  }

  async create (userData) {
    let payload = { user: userData }
    return axios.post(`${baseIndexUrlString()}`, payload)
      .then(user => newUser(user.data))
  }
}

function newUser (user) {
  return new User(user)
}

export default new Users()
