<template>
  <div class="ks-vue-component">
    <div
      class="form-group"
      v-bind:class="{
        'form-group--error': hasError,
        'form-group--valid': isValid,
        'form-group--focus': hasFocus,
        'form-group--disabled': disabled,
        'form-group-inline': inline,
      }"
    >
      <div class="form__label-wrap">
        <template v-if="showLabel">
          <label class="form__label">{{ label }}</label>
          <div v-if="showOptionalText" class="form__optional">- optional</div>
          <div v-if="unsavedChanges" class="form__dirty-text">Unsaved</div>
        </template>
      </div>
      <div v-if="showHelpText" class="form__help">{{ helpText }}</div>
      <div :class="{ 'form__input-group': showAddons }" v-if="!forceView">
        <span v-if="showPrefix" class="form__input-group__addon">{{
          prefix
        }}</span>
        <input
          v-if="isTextType"
          ref="input"
          class="form__input"
          :class="{ 'form__input-group__input': showAddons }"
          :placeholder="placeholder"
          :disabled="disabled"
          v-model="valueProxy"
          @input="updateValue"
          @focus="onFocus"
          @blur="onBlur"
          :autofocus="autofocus"
          type="text"
        />
        <input
          v-if="isNumberType"
          ref="input"
          class="form__input"
          :class="{ 'form__input-group__input': showAddons }"
          :placeholder="placeholder"
          :disabled="disabled"
          :step="step"
          v-model="valueProxy"
          @input="updateValue"
          @focus="onFocus"
          @blur="onBlur"
          :autofocus="autofocus"
          :min="minValue"
          :max="maxValue"
          type="number"
        />
        <input
          v-if="isPhoneType"
          ref="input"
          class="form__input"
          :class="{ 'form__input-group__input': showAddons }"
          v-mask="'(###) ###-####'"
          :placeholder="placeholder"
          :disabled="disabled"
          v-model="valueProxy"
          @input="updateValue"
          @focus="onFocus"
          @blur="onBlur"
          :autofocus="autofocus"
          type="tel"
          pattern="\([0-9]{3}\) [0-9]{3}-[0-9]{4}"
        />
        <span v-if="showSuffix" class="form__input-group__addon">{{
          suffix
        }}</span>
      </div>
      <div
        class="text-view-mode"
        v-if="(isTextType || isNumberType) && forceView"
      >
        {{ valueProxy || '--' }}
      </div>

      <textarea
        v-if="isTextAreaType && !forceView"
        ref="input"
        class="form__textarea"
        :placeholder="placeholder"
        :disabled="disabled"
        v-model="valueProxy"
        @input="updateValue"
        @focus="onFocus"
        @blur="onBlur"
        :autofocus="autofocus"
      ></textarea>
      <div class="textarea-view-mode" v-if="isTextAreaType && forceView">
        {{ valueProxy }}
      </div>

      <div v-if="isDateType" class="form__input-group">
        <input
          ref="input"
          class="form__input form__input-group__input"
          v-mask="'#?#/#?#/####'"
          :placeholder="placeholder"
          :disabled="disabled"
          v-model="valueProxy"
          @input="updateDateValue"
          @focus="onFocus"
          @blur="onBlurDate"
          :autofocus="autofocus"
          type="text"
        />
        <span
          class="form__input-group__btn form__input-group__btn--icon"
          @click="showCalendar"
        >
          <svgicon class="ks-icon-size-xs" icon="ks-icon-calendar" />
        </span>
        <date-picker
          v-if="calendarVisible"
          v-model="valueProxy"
          @input="updateDateValue"
          :format="formatDate"
          :min-date="minDate"
          :max-date="maxDate"
          @close="hideCalendar"
          color="#0364D5"
        />
      </div>

      <v-popover
        v-if="isTimeType"
        :open="timepickerVisible"
        trigger="manual"
        offset="2px"
        placement="bottom-end"
        popoverClass="form__input-group__timepicker overflow-scroll"
        @hide="hideTimepicker"
      >
        <div class="form__input-group tooltip-target">
          <input
            ref="input"
            class="form__input form__input-group__input"
            v-mask="'##:##'"
            :placeholder="placeholder"
            :disabled="disabled"
            v-model="valueProxy"
            @input="updateTimeValue"
            @focus="onFocus"
            @blur="onBlurDate"
            :autofocus="autofocus"
            type="text"
          />
          <span
            class="form__input-group__btn form__input-group__btn--icon"
            @click="toggleTimepicker"
          >
            <svgicon class="ks-icon-size-xs" icon="ks-icon-clock" />
          </span>
        </div>

        <template slot="popover">
          <div class="tooltip-content">
            <div class="form__input-group__timepicker__hours">
              <div
                v-for="h in hours"
                :key="h"
                class="form__input-group__timepicker__hour"
                :class="{
                  'form__input-group__timepicker__hour--selected': h === hour,
                }"
                @click="onClickHour(h)"
              >
                {{ h }}
              </div>
            </div>
            <div class="form__input-group__timepicker__minutes">
              <div
                v-for="min in minutes"
                :key="min"
                class="form__input-group__timepicker__minute"
                :class="{
                  'form__input-group__timepicker__minute--selected':
                    min === minute,
                }"
                @click="onClickMinute(min)"
              >
                {{ min }}
              </div>
            </div>
            <!-- <div
              v-for="time in times"
              :key="time"
              class="form__input-group__timepicker__time"
              @click="onClickTime(time)"
            >{{ time }}</div>-->
          </div>
        </template>
      </v-popover>
    </div>

    <template v-if="$v.$dirty">
      <span class="form-group__message" v-if="showRequireNumAlphaNumericError"
        >{{ label }} must have at least
        {{ $v.$params.requireNumAlphaNumeric.min }} alphanumeric
        characters.</span
      >

      <span class="form-group__message" v-if="showRequireNumAlphaNumericIfError"
        >{{ label }} must have at least
        {{ $v.$params.requireNumAlphaNumericIf.min }} alphanumeric
        characters.</span
      >

      <span class="form-group__message" v-if="showMinLengthError"
        >{{ label }} must have at least
        {{ $v.$params.minLength.min }} characters.</span
      >

      <span class="form-group__message" v-if="showMaxLengthError"
        >{{ label }} must have no more than
        {{ $v.$params.maxLength.max }} characters.</span
      >

      <span class="form-group__message" v-if="showBetweenError"
        >{{ label }} must be between {{ minValue }} and {{ maxValue }}.</span
      >
    </template>

    <!-- <pre>{{ $v }}</pre> -->
  </div>
</template>

<script>
  import { isUndefined } from 'lodash'
  import DatePicker from 'vue-md-date-picker'
  import moment from 'moment'
  import { VueMaskDirective } from 'v-mask'
  import svgicon from 'vue-svgicon'
  import 'Components/icons'

  /* eslint-disable no-useless-escape */
  const dateFormatRegex = /([0-9]{4})[-\/]([0-1]?[0-9])[-\/]([0-3]?[0-9])/
  /* eslint-enable no-useless-escape */

  const timeRegex = /(\d+):(\d+)/

  const inputTypes = {
    NUMBER: 'number',
    TEXTAREA: 'textarea',
    DATE: 'date',
    TIME: 'time',
    PHONE: 'phone',
    TEXT: 'text',
  }

  export default {
    name: 'ks-input-field',
    components: {
      DatePicker,
      svgicon,
    },
    directives: {
      mask: VueMaskDirective,
    },
    data () {
      let hour = null
      let minute = null

      if (this.value && this.value.match) {
        const match = this.value.match(timeRegex)
        if (match) {
          [, hour, minute] = match
        }
      }

      return {
        hasFocus: false,
        calendarVisible: false,
        timepickerVisible: false,
        valueProxy: this.value,
        originalValue: null,
        hour,
        minute,
      }
    },
    computed: {
      times () {
        let times = []
        for (let i = 0; i < 24; i++) {
          for (let j = 0; j < 4; j++) {
            const hour = i < 10 ? `0${i}` : i
            const min = j === 0 ? '00' : 15 * j
            times.push(`${hour}:${min}`)
          }
        }
        return times
      },
      hours () {
        let hours = []
        for (let i = 0; i < 24; i++) {
          const hour = i < 10 ? `0${i}` : `${i}`
          hours.push(hour)
        }
        return hours
      },
      minutes () {
        let minutes = []
        for (let i = 0; i < 60; i += this.minuteStep) {
          const minute = i < 10 ? `0${i}` : `${i}`
          minutes.push(minute)
        }
        return minutes
      },
      showHelpText () {
        return !isUndefined(this.helpText)
      },
      required () {
        return this.$v.$params.required ||
          this.$v.$params.requiredIf ||
          this.$v.$params.requireNumAlphaNumeric ||
          this.$v.$params.requireNumAlphaNumericIf
      },
      showOptionalText () {
        return !this.viewOnly && !this.optionalTextDisabled && !this.required
      },
      showRequireNumAlphaNumericError () {
        return !!this.$v.$params.requireNumAlphaNumeric && !this.$v.requireNumAlphaNumeric
      },
      showRequireNumAlphaNumericIfError () {
        return !!this.$v.$params.requireNumAlphaNumericIf && !this.$v.requireNumAlphaNumericIf
      },
      showMinLengthError () {
        return !!this.$v.$params.minLength && !this.$v.minLength
      },
      showMaxLengthError () {
        return !!this.$v.$params.maxLength && !this.$v.maxLength
      },
      showBetweenError () {
        // TODO
        return false
      },
      inputType () {
        if (Object.values(inputTypes).includes(this.type)) {
          return this.type
        } else {
          return inputTypes.TEXT
        }
      },
      isNumberType () {
        return this.inputType === inputTypes.NUMBER
      },
      isTextAreaType () {
        return this.inputType === inputTypes.TEXTAREA
      },
      isDateType () {
        return this.inputType === inputTypes.DATE
      },
      isTimeType () {
        return this.inputType === inputTypes.TIME
      },
      isPhoneType () {
        return this.inputType === inputTypes.PHONE
      },
      isTextType () {
        return this.inputType === inputTypes.TEXT
      },
      betweenValuesPresent () {
        return !isUndefined(this.minValue) && !isUndefined(this.maxValue)
      },
      showLabel () {
        return !isUndefined(this.label)
      },
      showPrefix () {
        return !(this.prefix === '')
      },
      showSuffix () {
        return !(this.suffix === '')
      },
      showAddons () {
        return this.showPrefix || this.showSuffix
      },
      isValid () {
        return !this.$v.$invalid && this.value && this.value.length > 0
      },
      hasError () {
        return (this.$v && this.$v.$error) || this.forceError
      },
      dateIsValid () {
        if (this.inputType === 'date') {
          return moment(this.valueProxy, this.dateFormat, true).isValid()
        } else {
          return false
        }
      },
      timeIsValid () {
        if (this.inputType === 'time') {
          return this.valueProxy.match(/[0-9]{1,2}:[0-9]{2}/) !== null
        } else {
          return false
        }
      },
      dirty () {
        return this.showDirtyIndicator && this.$v && this.$v.$dirty
      },
      unsavedChanges () {
        return this.dirty && (this.valueProxy !== this.originalValue)
      },
      forceView () {
        return this.viewOnly
      },
    },
    methods: {
      updateValue () {
        this.$v.$touch()
        this.$emit('input', this.valueProxy)
      },
      updateDateValue () {
        if (this.disabled) {
          this.valueProxy = this.value
        } else if (this.inputType === 'date' && (this.valueProxy === '' || this.dateIsValid)) {
          this.$v.$touch()
          this.$emit('input', this.valueProxy)
        }
      },
      updateTimeValue () {
        if (this.inputType === 'time' && (this.valueProxy === '' || this.timeIsValid)) {
          // console.log(this.inputType, this, this.$v)
          this.$v.$touch()
          this.$emit('input', this.valueProxy)
        }
      },
      onBlur () {
        this.hasFocus = false
        this.$emit('blur')
      },
      onBlurDate () {
        this.onBlur()

        // Reset the date if the current input date is invalid
        if (!this.dateIsValid) {
          this.valueProxy = this.value
        }
      },
      onFocus () {
        this.hasFocus = true
      },
      showCalendar () {
        this.calendarVisible = true
        this.onFocus()
      },
      hideCalendar () {
        this.calendarVisible = false
        this.onBlur()

        // By: Matt & Tyler... this is ridiculous, Microsoft.
        // Force IE11 to do a full re-render of the page after a position: fixed; element (the datepicker) comes up
        // If you don't do this you won't be able to click on anything outside of the viewport shown when the datepicker was up
        setTimeout(() => {
          document.querySelector('body').style['display'] = 'none'
          document.querySelector('body').style['display'] = 'block'
        })
      },
      formatDate (date) {
        // all of this craziness is just for IE11
        // in IE11 moment('2018-1-8') does not parse validly like it does w/ Chrome, Firefox, etc.
        // therefore, we preprocess the date string and make sure it is 2018-01-08
        const res = dateFormatRegex.exec(date)
        let newDate = ''

        if (res) {
          if (res[2].length === 1) {
            newDate += `0${res[2]}/`
          } else {
            newDate += `${res[2]}/`
          }

          if (res[3].length === 1) {
            newDate += `0${res[3]}/`
          } else {
            newDate += `${res[3]}/`
          }

          newDate += res[1]
          return moment(newDate).format(this.dateFormat)
        } else {
          return ''
        }
      },
      showTimepicker () {
        this.timepickerVisible = true
        this.onFocus()
      },
      hideTimepicker () {
        this.timepickerVisible = false
        this.onBlur()
      },
      toggleTimepicker () {
        if (this.timepickerVisible) {
          this.hideTimepicker()
        } else {
          this.showTimepicker()
        }
      },
      onClickTime (time) {
        this.valueProxy = time
        this.updateValue()
        this.hideTimepicker()
      },
      onClickHour (hour) {
        this.hour = hour
        this.valueProxy = `${this.hour}:${this.minute}`
        this.updateValue()
      },
      onClickMinute (minute) {
        this.minute = minute
        this.valueProxy = `${this.hour}:${this.minute}`
        this.updateValue()
      },
      focus () {
        this.$refs.input.focus()
      },
    },
    mounted () {
      if (this.autofocus) {
        this.focus()
      }
    },
    created () {
      this.originalValue = this.value === null ? '' : this.value

      const watcher = (newVal, oldVal, dereg = null) => {
        if (newVal !== oldVal && newVal !== this.valueProxy) {
          this.valueProxy = newVal

          if (this.newVal && this.newVal.match) {
            const [, hour, min] = newVal.match(timeRegex)
            this.hour = hour
            this.min = min
          }

          if (dereg) {
            dereg()
          }
        }
      }

      // This is so that you can set this.value on the outside programmatically and have the Input Field update its own view
      if (this.watchValue) {
        this.$watch('value', watcher)
      }

      // This is so that you can set this.value on the outside programmatically and have the Input Field update its own view, once
      if (this.watchOnce) {
        const dereg = this.$watch('value', (newVal, oldVal) => watcher(newVal, oldVal, dereg))
      }
    },
    props: {
      value: {
        default: '',
      },
      watchValue: {
        type: Boolean,
        default: false,
      },
      watchOnce: {
        type: Boolean,
        default: false,
      },
      label: {
        type: String,
      },
      helpText: {
        type: String,
      },
      placeholder: {
        type: String,
      },
      type: {
        type: String,
        default: 'text',
      },
      step: {
        type: String,
        default: '1',
      },
      disabled: {
        type: Boolean,
        default: false,
      },
      autofocus: {
        type: Boolean,
        default: false,
      },
      prefix: {
        type: String,
        default: '',
      },
      suffix: {
        type: String,
        default: '',
      },
      $v: {
        type: Object,
        required: true,
      },
      inline: {
        type: Boolean,
        default: false,
      },
      optionalTextDisabled: {
        type: Boolean,
        default: false,
      },
      dateFormat: {
        type: String,
        default: 'MM/DD/YYYY',
      },
      minDate: {
        type: String,
      },
      maxDate: {
        type: String,
      },
      minValue: {
        type: String,
        default: '',
      },
      maxValue: {
        type: String,
        default: '',
      },
      showDirtyIndicator: {
        type: Boolean,
        default: false,
      },
      forceError: {
        type: Boolean,
        default: false,
      },
      viewOnly: {
        type: Boolean,
        default: false,
      },
      minuteStep: {
        type: Number,
        default: 15,
      },
    },
  }

</script>

<style lang="scss">
  @use "~Styles/mixins";

  .text-view-mode {
    @include mixins.view-mode;

    min-height: 32px;
  }

  .textarea-view-mode {
    @include mixins.view-mode;

    max-height: 200px;
    word-wrap: wrap;
    overflow: auto;
  }
</style>
