import { filter, includes } from 'lodash'

import BaseDataService from 'DataServices/BaseDataService'
import Event from 'Models/Event'
import axios from './axios'
import defer from 'Utils/defer'

class Events extends BaseDataService {
  async getAll (params) {
    return axios.get(`/events`, { params: params })
      .then(response => response.data)
      .then(respData => {
        return Promise.all(respData.data.map(async event => {
          return newEvent(event)
        }))
          .then(eventsArray => {
            return { data: eventsArray, links: respData.links }
          })
      })
      .catch(console.error)
  }

  async getById (id) {
    const deferred = defer()

    if (!id) {
      console.error('Argument Missing: id is required')
    }

    const callback = (records, ids) => {
      // console.log('CALLBACK', records, ids)
      if (ids) {
        deferred.resolve(filter(records, r => includes(ids, r.id)))
      } else {
        deferred.reject([])
      }
    }

    this._createOrAddRequestToBatch(this._createDataRequest([id], callback))

    return deferred.promise
  }

  async accept ({ eventId }) {
    if (!eventId) {
      console.error('Argument Missing: eventId is required')
    }
    return axios.put(`/events/${eventId}/accept`)
      .then(response => newEvent(response.data))
      .catch(console.error)
  }

  async unaccept ({ eventId }) {
    if (!eventId) {
      console.error('Argument Missing: eventId is required')
    }
    return axios.put(`/events/${eventId}/unaccept`)
      .then(response => newEvent(response.data))
      .catch(console.error)
  }

  async reject ({ eventId, reason }) {
    if (!eventId || !reason) {
      console.error('Argument Missing: eventId and reason are required')
    }
    return axios.put(`/events/${eventId}/reject`, { event: { reject_reason: reason } })
      .then(response => newEvent(response.data))
      .catch(console.error)
  }

  async openBidding ({ eventId }) {
    if (!eventId) {
      console.error('Argument Missing: eventId is required')
    }
    return axios.put(`/events/${eventId}/open_bidding`)
      .then(response => newEvent(response.data))
      .catch(console.error)
  }

  async closeBidding ({ eventId }) {
    if (!eventId) {
      console.error('Argument Missing: eventId is required')
    }
    return axios.put(`/events/${eventId}/close_bidding`)
      .then(response => newEvent(response.data))
      .catch(console.error)
  }

  async skipBidding ({ eventId }) {
    if (!eventId) {
      console.error('Argument Missing: eventId is required')
    }
    return axios.put(`/events/${eventId}/skip_bidding`)
      .then(response => newEvent(response.data))
      .catch(console.error)
  }

  async reopenBidding ({ eventId }) {
    if (!eventId) {
      console.error('Argument Missing: eventId is required')
    }
    return axios.put(`/events/${eventId}/reopen_bidding`)
      .then(response => newEvent(response.data))
      .catch(console.error)
  }

  async schedule ({ eventId }) {
    if (!eventId) {
      console.error('Argument Missing: eventId is required')
    }
    return axios.put(`/events/${eventId}/schedule`)
      .then(response => newEvent(response.data))
      .catch(console.error)
  }

  async markDistributed ({ eventId }) {
    if (!eventId) {
      console.error('Argument Missing: eventId is required')
    }
    return axios.put(`/events/${eventId}/mark_distributed`)
      .then(response => newEvent(response.data))
      .catch(console.error)
  }

  async cancel ({ eventId, reason }) {
    if (!eventId || !reason) {
      console.error('Argument Missing: eventId and reason are required')
    }
    return axios.put(`/events/${eventId}/cancel`, { event: { cancel_reason: reason } })
      .then(response => newEvent(response.data))
      .catch(console.error)
  }

  async clone ({ eventId, newName, newStartDate, autoAccept = false }) {
    if (!eventId) {
      console.error('Argument Missing: eventId is required')
    } else if (!newName) {
      console.error('Argument Missing: newName is required')
    } else if (!newStartDate) {
      console.error('Argument Missing: newStartDate is required')
    }

    return axios.put(`/events/${eventId}/clone`, {
      name: newName,
      start_date: newStartDate,
      auto_accept: autoAccept,
    })
      .then(response => newEvent(response.data))
      .catch(console.error)
  }

  async cloneAndAccept (details) {
    details.autoAccept = true
    return this.clone(details)
  }

  async create (eventData) {
    let payload = eventData
    return axios.post(`/events`, payload)
      .then(event => newEvent(event.data))
      .catch(console.error)
  }

  async update (event) {
    const id = event.id
    delete event.id
    return axios.put(`/events/${id}`, { event: { ...event } })
      .then(response => newEvent(response.data))
      .catch(console.error)
  }
}

function newEvent (event) {
  return new Event(event)
}

export default new Events()
