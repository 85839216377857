// import Agency from 'Models/Agency'
import EmailAddress from 'Models/EmailAddress'
import User from 'Models/User'
import { featureFlags } from './constants'

const WindowVariables = {
  agencyId: Number(window.dkVariables['agency_id']),
  agency: window.dkVariables['agency'] || {}, // ? new Agency(window.dkVariables['agency']) : {},
  currentUserId: window.dkVariables['current_user_id'],
  currentUser: window.dkVariables['current_user'] ? new User(window.dkVariables['current_user']) : {},

  // TODO: Remove currentRole and all uses of it.  Must go into PermissionSets
  currentRole: window.dkVariables['current_role'],
  currentRoleResourceType: window.dkVariables['current_role_resource_type'],
  restrictedPermissionKeys: window.dkVariables['restricted_permission_keys'],

  isSuperAdmin: window.dkVariables['current_role'] === 'super_admin' || window.dkVariables.current_user_specialty_role === 'super_admin',
  isSpecialtySuperAdmin: window.dkVariables.current_user_specialty_role === 'super_admin',
  // the "current entity" for the Super Admin. Their current agency.
  currentEntityId: window.dkVariables['current_entity_id'] || undefined,

  currentVersion: window.dkVariables['current_js_version'],

  // TODO: Figure out how to dynamically figure out what TZ to use for a page/component
  timezone: window.dkVariables.time_zone,
  businessId: window.dkVariables['business_id'],
  businessName: window.dkVariables['business_name'],
  agencyUsersForReports: window.dkVariables['agency_users_for_reports'] || [],
  businessesForReports: window.dkVariables['businesses_for_reports'] || [],
  paySchedulesForReports: window.dkVariables['pay_schedules_for_reports'] || [],
  permissionsByAgencyId: window.dkVariables['permissions_by_agency_id'] || {},
  newCachedPermissions: window.dkVariables['new_permissions_by_key'] || {},
  invoiceVersion: window.dkVariables['invoice_version'],
  flashMessages: window.dkVariables['flash_messages'],
  hideTaskList: !!window.dkVariables['hide_task_list'],
  wideLayout: window.dkVariables['wide_layout'],
  defaultAllowJobPickup: window.dkVariables['default_allow_job_pickup'],
  defaultAvailableJobNotify: window.dkVariables['default_available_job_notify'],
  defaultActivityLogTemplateId: window.dkVariables['default_activity_log_template_id'],
  hasTaxFromsVisible: window.dkVariables['has_tax_forms_visible'],
  taxFormsEnabledForAgency: window.dkVariables['tax_forms_enabled_for_agency'],
  primaryEmailAddress: window.dkVariables['primary_email_address'] ? new EmailAddress(window.dkVariables['primary_email_address'], { timezone: window.dkVariables.time_zone }) : {},
  hasManualPaymentMethod: window.dkVariables['has_manual_payment_method'],
}

Object.defineProperty(WindowVariables.agency, 'hasCustomInvoiceId', {
  get() {
    return this.enabled_features.includes(featureFlags.agency.CUSTOM_INVOICE_ID)
  }
})

Object.defineProperty(WindowVariables.agency, 'hasEventDeposits', {
  get() {
    return this.enabled_features.includes(featureFlags.agency.EVENT_DEPOSITS)
  }
})

Object.defineProperty(WindowVariables.agency, 'hasAssignmentInsurance', {
  get() {
    return this.enabled_features.includes(featureFlags.agency.ASSIGNMENT_INSURANCE)
  }
})

Object.defineProperty(WindowVariables.agency, 'hasActivityLog', {
  get() {
    return this.enabled_features.includes(featureFlags.agency.ACTIVITY_LOG)
  }
})

export default WindowVariables
