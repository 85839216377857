import 'moment-timezone'

import { contains, isUndefined, last, range } from 'lodash'

import { WindowVariables } from 'Utils'
import moment from 'moment'

var theTz = WindowVariables.timezone
var startOfWeekDay = 0
var workDayStartHour = 0
var fiscalYearStartFromDB = '01-01'

var hourRegex = new RegExp('^(1[0-2]|[0]?[0-9])\\s?(a|p)?m?$', 'i')
var hourMinuteRegex = new RegExp('^([1][0-2]|[0]?[0-9])[:]?([0-5][0-9])\\s?(a|p)?m?$', 'i')

var twixToArray = function (twix) {
  return range(twix.length('days', true)).map(num => {
    return moment(twix.start()).add(num, 'days').startOf('day')
  })
}

export function lengthOfAssignment (assign, job, unit = 'hours') {
  if (!assign || (!assign.times_confirmed && !job)) {
    console.error('Assignment and Job required to compute length of Assignment.')
  }
  let start = job.start_date
  let end = job.end_date
  if (assign.times_confirmed) {
    start = assign.completed_datetime
    end = assign.on_site_datetime
  }
  return end.diff(start, unit)
}

export const setStartOfWeekDay = function (x) {
  startOfWeekDay = x
  return startOfWeekDay
}

export const getBeginningOfDayFromDate = function (dt) {
  theTz = WindowVariables.timezone
//        var basicDt = moment(dt).tz(theTz);
//        var simpleDt = moment(dt).hours(0).tz(theTz);
  var valOther = moment(dt).hours(moment(dt).tz(theTz).startOf('day').zone() / 60.0).tz(theTz).minutes(0).seconds(0).milliseconds(0).hours(0)
  var valOtherZone = valOther.zone()
//        console.log('getBeginningofDayFromDate: ', 'adding ', valOtherZone - moment(dt).tz(theTz).zone(), 'minutes');
  valOther = valOther.add(valOtherZone - moment(dt).tz(theTz).zone(), 'minutes')

  return valOther
}

export const getEndOfDayFromDate = function (dt) {
  return getBeginningOfDayFromDate(moment(dt).add(24, 'hours'))
}

export const getBeginningOfDayFromDateWithOrgSetting = function (dt) {
  var temp = getBeginningOfDayFromDate(dt)
//        console.log('getBeginningOfDayFromDate', temp.format());
  workDayStartHour = window.dkVariables.getWorkDayStartHour()
  if (workDayStartHour && workDayStartHour > 0) {
    return moment(temp).hours(workDayStartHour, 'hours')
  } else {
    return temp
  }
}

export const getNowInStoreTime = function () {
  return moment.utc().tz(WindowVariables.timezone)
}

export const getDayTwixFromDate = function (dt) {
  var temp = getBeginningOfDayFromDate(dt)
  return temp.twix(moment(temp).add(24, 'hours'))
}

export const getWeekTwixFromMoment = function (mmnt, numPreDays, numPostDays, useWorkDayStartHour) {
  startOfWeekDay = window.dkVariables.getStartOfWeekDay()
  numPreDays = isUndefined(numPreDays) ? 0 : numPreDays
  numPostDays = isUndefined(numPostDays) ? 0 : numPostDays
  var offsetAdjust = 0

  var basis = useWorkDayStartHour ? getBeginningOfDayFromDateWithOrgSetting(mmnt) : getBeginningOfDayFromDate(mmnt)
  var basisDayOfWeek = basis.day()

  if (basisDayOfWeek < startOfWeekDay) {
    offsetAdjust = 7
  }
  // console.log(moment(basis).subtract(basisDayOfWeek - startOfWeekDay + numPreDays + offsetAdjust, 'days').format());
  return moment(basis).subtract(basisDayOfWeek - startOfWeekDay + numPreDays + offsetAdjust, 'days')
      .twix(moment(basis).add(startOfWeekDay - basisDayOfWeek + numPostDays + 7 - offsetAdjust, 'days'))
}

export const getWorkWeekNumberFromMoment = function (mmnt) {
  var firstWeekStart, dayDiff, weekNum
  var nowYear = mmnt.year()
  var fiscalYearStartDate = getDayTwixFromDate(moment.utc(nowYear + '-' + fiscalYearStartFromDB)).start
  while (mmnt.isBefore(fiscalYearStartDate)) {
    fiscalYearStartDate = moment(fiscalYearStartDate).subtract(1, 'years')
  }

  if (startOfWeekDay < fiscalYearStartDate.day()) {
    firstWeekStart = fiscalYearStartDate.add(7 - fiscalYearStartDate.day() + startOfWeekDay, 'days')
    dayDiff = Math.ceil(firstWeekStart.twix(mmnt).length('hours') / 24.0 - 0.1)
    if (dayDiff < 0) {
      weekNum = 1
    } else {
      weekNum = 2 + Math.floor(dayDiff / 7.0)
    }
  } else {
    firstWeekStart = fiscalYearStartDate.subtract(7 - (startOfWeekDay - fiscalYearStartDate.day()), 'days')
    dayDiff = firstWeekStart.twix(mmnt).length('days')
    weekNum = Math.floor(dayDiff / 7.0) + 1
  }
  // console.log('dayDiff: ' + dayDiff + ';   weekNum: ' + weekNum);
  return weekNum
}

export const minutesToTimeString = function (minutes, includeMeridian, useMarkup) {
  // convert a number of minutes from 0 to 48hrs worth of minutes to a pretty time
  var pad = function (x) {
    if (x < 10) {
      return '0' + x
    } else {
      return x
    }
  }

  var renderMinIfNeeded = function (iMin) {
    if (iMin !== 0) {
      return ':' + pad(iMin)
    } else {
      return ''
    }
  }

  var renderText = function (hours, iMin, meridian) {
    if (useMarkup) {
      var sMin = renderMinIfNeeded(iMin)
      return '<span class="min_to_time_hours">' + hours + '</span>' +
          ((sMin !== '') ? ('<span class="min_to_time_minutes">' + renderMinIfNeeded(iMin) + '</span>') : '') +
          ((meridian !== '') ? ('<span class="min_to_time_meridian">' + meridian + '</span>') : '')
    } else {
      return hours + renderMinIfNeeded(iMin) + (includeMeridian ? meridian : '')
    }
  }

  var hours = Math.floor(minutes / 60.0)
  var min = minutes - hours * 60

  if (minutes < 720) {
    if (minutes < 60) {
      hours = 12
    }
    return renderText(hours, min, 'a')
  } else if (minutes >= 720 && minutes < 1440) {
    if (hours > 12) {
      return renderText(hours - 12, min, 'p')
    } else {
      return renderText(12, min, 'p')
    }
  } else if (minutes >= 1440) {
    // if the time is into the next day
    if (hours > 36) {
      // past noon on the next day
      return renderText(hours - 36, min, 'p')
    } else if (hours > 24) {
      // before noon on the next day
      return renderText(hours - 24, min, 'a')
    } else {
      return renderText(12, min, 'a')
    }
  }
}

export const minimalMomentFormat = function (mmnt, showMeridian, useMarkup) {
  var min = mmnt.format('mm')
  var hr = mmnt.format('h')

  if (useMarkup) {
    var temp = '<div class="moment_hour">' + hr + '</div>'

    if (min > 0) {
      temp += '<div class="moment_hour_min_sep"></div>' +
        '<div class="moment_min">' + min + '</div>'
    }

    if (showMeridian) {
      temp += '<div class="moment_meridian">' + mmnt.format('a')[0] + '</div>'
    }
    return temp
  } else {
    return hr + (min > 0 ? (':' + min) : '') + (showMeridian ? mmnt.format('a')[0] : '')
  }
}

export const momentFormat = function (mmnt, showMeridian, useMarkup) {
  const min = mmnt.format('mm')
  let hr = mmnt.format('h')

  if (showMeridian) {
    hr = mmnt.format('hh')
  } else {
    hr = mmnt.format('HH')
  }

  if (useMarkup) {
    var temp = '<div class="moment_hour">' + hr + '</div>'

    temp += '<div class="moment_hour_min_sep"></div>' +
            '<div class="moment_min">' + min + '</div>'

    if (showMeridian) {
      temp += '<div class="moment_meridian">' + mmnt.format('a')[0] + '</div>'
    }

    return temp
  } else {
    return `${hr}:${min} ${(showMeridian ? mmnt.format('a')[0] : '')}`
  }
}

export const minimalTwixFormat = function (twix, showMeridian, useMarkup, seperator) {
  var sep = isUndefined(seperator) ? '-' : seperator

  if (useMarkup) {
    sep = '<div class="twix_range_seperator">' + sep + '</div>'
  }
  return minimalMomentFormat(twix.start(), showMeridian, useMarkup) + sep + minimalMomentFormat(twix.end(), showMeridian, useMarkup)
}

export const formattedTwixFormat = function (twix, showMeridian, useMarkup, seperator) {
  var sep = isUndefined(seperator) ? '—' : seperator

  if (useMarkup) {
    sep = '<div class="twix_range_seperator">' + sep + '</div>'
  }
  return `${momentFormat(twix.start(), showMeridian, useMarkup)} ${sep} ${momentFormat(twix.end(), showMeridian, useMarkup)}`
}

export const minimalIntervalTimeFormat = function (twix, combineWord, startDateFmt) {
  // for use with day off requests
  var str = ''
  var htmlStr = ''
  startDateFmt = startDateFmt || 'MMM D'

  if (!combineWord) {
    combineWord = 'through'
  }

  // var isMidnightToMidnight = twix.start.hour() === 0 && twix.end.hour() === 0
  var isLongerThanDay = (Math.abs(twix.length('minutes') / 60.0)) > 24.0
  var isOnTwoDifferentDates = twix.start.dayOfYear() !== twix.end.dayOfYear() && (Math.abs(twix.length('minutes') / 60.0)) >= 24 && twix.end.hour() !== 0
  var isAcrossMultipleMonths = twix.start.month() !== twix.end.month()

  var addStartTime = twix.start.hour() !== 0 || twix.start.minute() !== 0
  var addEndTime = twix.end.hour() !== 0 || twix.start.minute() !== 0
  var addEndDate = isLongerThanDay || isOnTwoDifferentDates
  var needPostStartSpace = true

//        console.log(twix.format());
//        console.log(twix);
//        console.log('isLongerThanDay', isLongerThanDay);
//        console.log('isOnTwoDifferentDates', isOnTwoDifferentDates);
//        console.log('isAcrossMultipleMonths', isAcrossMultipleMonths);
//        console.log('addStartTime', addStartTime);
//        console.log('addEndTime', addEndTime);
//        console.log('addEndDate', addEndDate);

  htmlStr += '<span class="mif_start_date">' + twix.start.format(startDateFmt) + '</span>'
  str += twix.start.format(startDateFmt)

  if (addStartTime || addEndTime) {
    str += ' ' + minimalMomentFormat(twix.start, true)
    htmlStr += ' <span class="mif_start_time">' + minimalMomentFormat(twix.start, true) + '</span>'
  }

  if ((!isLongerThanDay && !isOnTwoDifferentDates && addEndTime) || (addEndDate && !isAcrossMultipleMonths && !addEndTime && !addStartTime)) {
    str += '-'
    htmlStr += '<span class="mif_dash">-</span>'
    needPostStartSpace = false
  } else if (addEndDate || addEndTime) {
    str += ' ' + combineWord
    htmlStr += ' <span class="mif_combine_word">' + combineWord + '</span>'
  }

  // if !addEndTime then use the day before for the end date, notating THROUGH instead of a day past
  var endDate
  if (addEndTime) {
    endDate = twix.end
  } else {
    endDate = moment(twix.end).subtract(22, 'hours')
  }

  if (addEndDate) {
    if (isAcrossMultipleMonths || addEndTime || addStartTime) {
      str += ' ' + endDate.format('MMM D')
      htmlStr += ' <span class="mif_end_date">' + endDate.format('MMM D') + '</span>'
    } else {
      if (needPostStartSpace) {
        str += ' '
      }
      str += endDate.format('D')
      htmlStr += '<span class="mif_end_date">' + endDate.format('D') + '</span>'
    }
  }

  if (addEndTime) {
    if (needPostStartSpace) {
      str += ' '
      htmlStr += ' '
    }
    str += minimalMomentFormat(twix.end, true)
    htmlStr += '<span class="mif_end_time">' + minimalMomentFormat(twix.end, true) + '</span>'
  }

  var ret = {
    strings: {
      interval: str,
      htmlInterval: htmlStr,
      startDate: twix.start.format('MMM D'),
      endDate: endDate.format('MMM D'),
      startTime: minimalMomentFormat(twix.start, true),
      endTime: minimalMomentFormat(twix.end, true),
    },
    needEndDate: addEndDate,
    needEndTime: addEndTime,
    needStartTime: addStartTime,
  }
  return ret
}

const decodeExplicitRegexResult = function (match, shortFormMatch) {
  var pHour = parseInt(match[1]) // parsed hour
  var hour

  if (last(match) === 'a') {
    hour = (pHour === 12) ? 0 : pHour
  } else {
    hour = (pHour === 12) ? 12 : pHour + 12
  }

  return {
    min: shortFormMatch ? 0 : parseInt(match[2]),
    hour: hour,
  }
}

const decodeRelativeEndRegexResult = function (match, shortFormMatch, startRes) {
  var hour = parseInt(match[1])
  var min = shortFormMatch ? 0 : parseInt(match[2])

  if ((startRes.hour * 60 + startRes.min) >= (hour * 60 + min)) {
    hour += 12 // bump it to the next meridian
  }

  if ((startRes.hour * 60 + startRes.min) >= (hour * 60 + min)) {
    hour += 12 // bump it all the way to the next day if it's still not there
  }

  return {
    min: min,
    hour: hour,
  }
}

export const parseRawStartEndTimeString = function (rawString, currentDate) {
  // now let's try and split on hyphens (-) and attempt to get a range
  if (rawString.split('-').length === 1) {
    return {
      parsed: false,
      rawText: rawString,
    }
  }
  var start = rawString.split('-')[0].trim().toLowerCase()
  var end = rawString.split('-')[1].trim().toLowerCase()

  // figure out what hour start is (am/pm, etc)

  var startRegexMatch, endRegexMatch
  var startShortFormMatch = false
  var endShortFormMatch = false
  var explicitStartMeridian, explicitEndMeridian
  currentDate = getBeginningOfDayFromDateWithOrgSetting(currentDate)
  currentDate = currentDate.seconds(0).milliseconds(0)

  var startRes, endRes
  startRegexMatch = hourRegex.exec(start)
  endRegexMatch = hourRegex.exec(end)

  if (startRegexMatch) {
    startShortFormMatch = true
  } else {
    startRegexMatch = hourMinuteRegex.exec(start)
  }

  if (endRegexMatch) {
    endShortFormMatch = true
  } else {
    endRegexMatch = hourMinuteRegex.exec(end)
  }

  if (startRegexMatch && endRegexMatch) {
    // OK. We're in business. Both regex's matched
    explicitStartMeridian = contains(['a', 'p'], last(startRegexMatch))
    explicitEndMeridian = contains(['a', 'p'], last(endRegexMatch))

    // check out the meridians...
    // if explicit start and end meridians are true then just do it
    if (explicitStartMeridian && explicitEndMeridian) {
      startRes = decodeExplicitRegexResult(startRegexMatch, startShortFormMatch)
      endRes = decodeExplicitRegexResult(endRegexMatch, endShortFormMatch)

      if ((startRes.hour * 60 + startRes.min) >= (endRes.hour * 60 + endRes.min)) {
        // in the case that end is before start, force end to the next day
        endRes.hour += 24
      }
    } else if (explicitStartMeridian && !explicitEndMeridian) {
      startRes = decodeExplicitRegexResult(startRegexMatch, startShortFormMatch)
      endRes = decodeRelativeEndRegexResult(endRegexMatch, endShortFormMatch, startRes)
    } else if (!explicitStartMeridian && explicitEndMeridian) {
      // if our start hour is >= the org.workDayStartHour then assume AM.
      if (parseInt(startRegexMatch[1]) >= window.dkVariables.getWorkDayStartHour()) {
        // just assume that they mean AM!
        startRegexMatch[startRegexMatch.length - 1] = 'a'
        startRes = decodeExplicitRegexResult(startRegexMatch, startShortFormMatch)
        endRes = decodeExplicitRegexResult(endRegexMatch, endShortFormMatch)
      } else {
        // guaranteed that startHour < window.dkVariables.getWorkDayStartHour()

        // scenarios: w/ org workDayStartHour=4am
        // 3-6a --> 3p today to 6a on following day
        // 3-6p --> 3p today to 6p today
        // 3-12p --> 3p today to noon tomorrow
        startRegexMatch[startRegexMatch.length - 1] = 'p'
        startRes = decodeExplicitRegexResult(startRegexMatch, startShortFormMatch)

        if (last(endRegexMatch) === 'p') {
          endRes = decodeExplicitRegexResult(endRegexMatch, endShortFormMatch)

          if ((startRes.hour * 60 + startRes.min) >= (endRes.hour * 60 + endRes.min)) {
            endRes.hour += 24 // send it to next day
          }
        } else {
          endRes = decodeExplicitRegexResult(endRegexMatch, endShortFormMatch)
          endRes.hour += 24 // send it to next day
        }
      }
    } else {
      // nothing is explicit! woohoo!

      if (parseInt(startRegexMatch[1]) >= window.dkVariables.getWorkDayStartHour()) {
        startRegexMatch[startRegexMatch.length - 1] = 'a'
      } else {
        startRegexMatch[startRegexMatch.length - 1] = 'p'
      }
      startRes = decodeExplicitRegexResult(startRegexMatch, startShortFormMatch)

      if ((startRes.hour * 60 + startRes.min) >=
          (parseInt(endRegexMatch[0]) * 60 + (endShortFormMatch ? 0 : parseInt(endRegexMatch[1])))) {
        endRegexMatch[endRegexMatch.length - 1] = 'p'
      } else {
        endRegexMatch[endRegexMatch.length - 1] = 'a'
      }
      endRes = decodeExplicitRegexResult(endRegexMatch, endShortFormMatch)

      if ((startRes.hour * 60 + startRes.min) >= (endRes.hour * 60 + endRes.min)) {
        // in the case that end is before start, force end up 12 hours
        endRes.hour += 12
      }

      if ((startRes.hour * 60 + startRes.min) >= (endRes.hour * 60 + endRes.min)) {
        // in the case that end is before start, force end up 12 hours
        endRes.hour += 12
      }
    }

    var ret = {
      parsed: true,
      rawText: rawString,
      start: {
        hour: startRes.hour,
        min: startRes.min,
        dt: moment(currentDate).hour(startRes.hour).minute(startRes.min),
      },
      end: {
        hour: endRes.hour,
        min: endRes.min,
        dt: moment(currentDate).hour(endRes.hour).minute(endRes.min),
      },
    }

    ret.start.minimalFormat = minimalMomentFormat(ret.start.dt, true)
    ret.end.minimalFormat = minimalMomentFormat(ret.end.dt, true)

    ret.twix = ret.start.dt.twix(ret.end.dt)
    ret.twixFormatted = ret.start.minimalFormat + ' - '

    if (ret.start.dt.day() !== ret.end.dt.day()) {
      ret.twixFormatted += ret.end.dt.format('MMM DD ')
    }

    ret.twixFormatted += ret.end.minimalFormat
    ret.lengthInHours = parseFloat((ret.twix.length('minutes') / 60.0).toFixed(2))

    return ret
  } else {
    // one or both of these regex's don't match. return error
    return {
      parsed: false,
      rawText: rawString,
    }
  }
}

export const momentToDateInLocalOffset = function (mmnt, convertHours, convertMinutes, convertSeconds) {
  var m = moment(mmnt)
  return new Date(m.year(), m.month(), m.date(), (convertHours ? m.hours() : 0), (convertMinutes ? m.minutes() : 0), (convertSeconds ? m.seconds() : 0))
}

export const momentFormatInLocalOffset = function (mmnt, formatStr) {
  return moment.tz(mmnt, theTz).format(formatStr)
}

export const momentToDateStringInLocalOffset = function (mmnt) {
  return momentFormatInLocalOffset(mmnt, 'MMM DD, YYYY')
}

export default {
  twixToArray,
  formattedTwixFormat,
  getBeginningOfDayFromDate,
  getEndOfDayFromDate,
  getBeginningOfDayFromDateWithOrgSetting,
  getNowInStoreTime,
  getDayTwixFromDate,
  getWeekTwixFromMoment,
  getWorkWeekNumberFromMoment,
  setStartOfWeekDay,
  minutesToTimeString,
  momentFormat,
  minimalMomentFormat,
  minimalTwixFormat,
  minimalIntervalTimeFormat,
  parseRawStartEndTimeString,
  momentToDateInLocalOffset,
  momentFormatInLocalOffset,
  momentToDateStringInLocalOffset,
}
