import * as actions from './actions'
import * as getters from './getters'
import mutations from './mutations'

const state = {
  allNotifications: {},
  highestStoredPage: 0,
  totalPages: 0,
  unreadCount: 0,
  fetchingNotifications: true,
}

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
}
