/**
 * NOTES:
 *  - Methods with an underscore should only be called internally and
 *    should not push any promises to the `_awaitingPagePromises` structure.
 */

import 'moment-timezone'

import { action, computed, observable, toJS } from 'mobx'
import { cloneDeep, drop, every, flatten, forEach, isArray, isEqual, map, merge, reduce, some } from 'lodash'

import StorePage from 'Stores/base_store/StorePage'
import defer from 'Utils/defer'
import moment from 'moment'
import { v4 as uuid } from 'uuid'

class PageSet {
  _uuid = uuid()
  _parentStore = undefined
  _startingPage = 1

  _filterFrozen = undefined

  @observable _currentPageNumber = undefined
  @observable _upcomingPageNumber = undefined
  @observable _pages = []

  _links = observable.map({})

  // { [pageNumber]: [ ... ] }
  _awaitingPagePromises = {}

  @observable _finalRequestInitiated = false

  constructor (parentStore, startingPage = 1) {
    this._parentStore = parentStore
    this._startingPage = startingPage

    // Copy the raw filter into the page set
    this._filterFrozen = this._parentStore._filter
  }

  get uuid () {
    return this._uuid
  }

  @computed
  get opts () {
    return this._parentStore.opts
  }

  @computed
  get loading () {
    let loadingStates = reduce(this._pages, (res, page) => {
      res[page.pageNumber] = page.requestInFlight
      return res
    }, {}) || {}
    if (!this.opts.paginate) {
      return some(loadingStates)
    } else {
      loadingStates['first'] = loadingStates[1] || false
      loadingStates['last'] = loadingStates[this.links.pagination.last_page] || false
      loadingStates['current'] = loadingStates[this._currentPageNumber] || false
      loadingStates['next'] = loadingStates[this._currentPageNumber + 1] || false
      loadingStates['previous'] = (this._currentPageNumber > 1 && loadingStates[this._currentPageNumber - 1]) || false
      loadingStates['any'] = some(loadingStates) || !this._finalRequestInitiated
      return loadingStates
    }
  }

  @computed
  get anyPageLoading () {
    return this.opts.paginate ? some(this.loading) : this.loading
  }

  @computed
  get cacheValidation () {
    let cacheStates = reduce(this._pages, (res, page) => {
      res[page._pageNumber] = observable.object({
        lastFetchedAt: page._lastFetchedAt,
        lastFetchedAtMoment: moment.unix(page._lastFetchedAt),
        get cacheValid () { return page.cacheValid },
      })
      return res
    }, {}) || {}
    cacheStates['all'] = observable({
      get cacheValid () { return every(this._pages, c => c.cacheValid) },
    })
    return cacheStates
  }

  get cacheValid () {
    return this.anyPageLoading || every(this._pages, 'cacheValid')
  }

  @computed
  get currentPage () {
    return this._pages.find(p => p.pageNumber === this._currentPageNumber)
  }

  @computed
  get upcomingPage () {
    return this._pages.find(p => p.pageNumber === this._upcomingPageNumber)
  }

  @computed
  get pageRecords () {
    return reduce(this._pages, (res, page) => {
      res[page.pageNumber] = page.records
      return res
    }, {}) || {}
  }

  @computed
  get currentPageRecords () {
    return (this.currentPage && this.currentPage.records) || []
  }

  @computed
  get allRecords () {
    return flatten(map(this._pages, 'records'))
  }

  @computed
  get pageData () {
    return {
      data: this.currentPageRecords,
      links: this.links,
    }
  }

  @computed
  get links () {
    const mergedLinks = merge(
      toJS(this._links || {}),
      { pagination: { current_page: this._currentPageNumber } }
    )

    return cloneDeep(mergedLinks)
  }

  compareFilter (filter) {
    let filtersToCompare = map([this._filterFrozen, filter], f => cloneDeep(toJS(f)))
    return isEqual(...filtersToCompare) // && sort comparison
  }

  compare (other) {
    // filter compare
    // TODO: sort compare
    let filtersToCompare = map([this._filterFrozen, other._filterFrozen], f => cloneDeep(toJS(f)))
    return isEqual(...filtersToCompare) // && sort comparison
  }

  @action
  initFetch (startingPage = undefined) {
    // Use the starting page passed into, otherwise default to the one
    // that was stored on the instance in setFilter.
    return this._fetch(startingPage || this._startingPage)
  }

  /**
   * Sets the `upcomingPageNumber` and initiates the request for it.
   * Should be used externally to request and swap pages when returned.
   * @param {Integer} pageNumber Page number to request
   */
  @action
  goToPage (pageNumber) {
    this._upcomingPageNumber = pageNumber

    // check the cache on that page
    if (this.cacheValidation[pageNumber] && this.cacheValidation[pageNumber].cacheValid) {
      // this._notifyOfPageData(pageInst)
      this._transitionToUpcomingPage()
      return Promise.resolve(this.currentPageRecords)
    } else {
      this._fetchPage(pageNumber)
      return this._initPagePromise(pageNumber)
    }
    // if valid promise.resolve
    // if not fetch page || reload page
  }

  @action
  goToNextPage () {
    let referenceablePageNumber = this._upcomingPageNumber || this._currentPageNumber
    if (!this.links || (this.links && referenceablePageNumber < this.links.pagination.last_page)) {
      return this.goToPage(referenceablePageNumber + 1)
    } else {
      return Promise.reject(new Error(`BaseStore.js: Cannot request a page more than the limit. You requested ${referenceablePageNumber + 1}.`))
    }
  }

  @action
  goToPreviousPage () {
    let referenceablePageNumber = this._upcomingPageNumber || this._currentPageNumber
    if (referenceablePageNumber > 1) {
      return this.goToPage(referenceablePageNumber - 1)
    } else {
      return Promise.reject(new Error(`BaseStore.js: Cannot request a page less than 1. You requested ${referenceablePageNumber - 1}.`))
    }
  }

  @action
  goToFirstPage () {
    return this.goToPage(1)
  }

  @action
  goToLastPage () {
    if (!this.links) {
      return Promise.reject(new Error(`BaseStore.js: Cannot go to last page without an initial request being executed.`))
    }
    return this.goToPage(this.links.pagination.last_page)
  }

  @action
  _fetch (startingPage = 1) {
    return this.goToPage(startingPage)
      .then(res => {
        if (this.opts.paginate) {
          if (this.opts.fetchAll) {
            // Create an array from 0..last_page, drop the first index because we have
            // already requested the first page, and loop over all remaining values.
            const pagesToFetch = map(
              drop([...Array(this.links.pagination.last_page).keys()]),
              pn => pn + 1
            )
            forEach(pagesToFetch, pn => this._fetchPage(pn))
          } else if (this.opts.preload && this.links.pagination.last_page > 1) {
            this._fetchPage(startingPage + 1)
            if (startingPage > 1) {
              this._fetchPage(startingPage - 1)
            }
          }
        }
        this._parentStore._swapToUpcomingPageSet(this)
        this._finalRequestInitiated = true
      })
  }

  /**
   * Fetch data for a specified page.
   * Should only be used internally. Does not automatically transition
   * the current page.
   * @param {Integer} pageNumber Page number to request
   */
  @action
  _fetchPage (pageNumber, force = false) {
    let pageInst = this._pages.find(p => p.pageNumber === pageNumber)
    if (!force && pageInst) {
      if (pageInst.cacheValid) {
        return Promise.resolve(pageInst.records)
      } else if (pageInst.requestInFlight) {
        return this._initPagePromise(pageNumber)
      }
    } else if (!pageInst) {
      pageInst = new StorePage(this, pageNumber)
      this._pages.push(pageInst)
    }

    return pageInst.fetch().then(res => {
      this._links = res.links
      // Notify the page set that we have returned with data
      this._notifyOfPageData(pageInst)
      if (pageInst.pageNumber === this._upcomingPageNumber) {
        this._transitionToUpcomingPage()
      }
    })
  }

  _notifyOfPageData (page) {
    let awaitingPromises = this._awaitingPagePromises[page.pageNumber]
    if (awaitingPromises && isArray(awaitingPromises)) {
      while (awaitingPromises.length > 0) {
        awaitingPromises.shift().resolve(page.records)
      }
    }
  }

  _initPagePromise (pageNumber = 1) {
    const deferred = defer()
    if (!this._awaitingPagePromises[pageNumber]) {
      this._awaitingPagePromises[pageNumber] = []
    }
    this._awaitingPagePromises[pageNumber].push(deferred)
    return deferred.promise
  }

  _transitionToUpcomingPage () {
    this._currentPageNumber = this._upcomingPageNumber
    this._upcomingPageNumber = undefined
  }

  @action
  _propagateChanges (changes, action) {
    this._pages.forEach(p => p._propagateChanges(changes, action))
  }

  __debugConsole () {
    this._parentStore.__debugConsole(...arguments)
  }
}

export default PageSet
