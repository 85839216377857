<script>
  import { isUndefined } from 'lodash'

  export default {
    data () {
      return {
        hasFocus: false,
      }
    },
    computed: {
      showHelpText () {
        return !isUndefined(this.helpText)
      },
      showOptionalText () {
        return !this.viewOnly && !this.optionalTextDisabled && !this.required
      },
      showLabel () {
        return !isUndefined(this.label)
      },
      isValid () {
        return !this.$v || (this.$v && !this.$v.$invalid)
      },
      required () {
        return this.$v && (this.$v.$params.required || this.$v.$params.requiredIf)
      },
      hasError () {
        return this.$v && this.$v.$error
      },
      dirty () {
        return this.showDirtyIndicator && this.$v && this.$v.$dirty
      },
      forceView () {
        return this.viewOnly
      },
      formGroupClass () {
        const baseClass = 'form-group'
        const classList = [baseClass]

        if (this.hasError) {
          classList.push(`${baseClass}--error`)
        }

        if (this.dirty) {
          classList.push(`${baseClass}--dirty`)
        }

        if (this.isValid) {
          classList.push(`${baseClass}--valid`)
        }

        if (this.hasFocus) {
          classList.push(`${baseClass}--focus`)
        }

        if (this.disabled) {
          classList.push(`${baseClass}--disabled`)
        }

        if (this.inline) {
          classList.push(`${baseClass}-inline`)
        }

        if (this.hideTags) {
          classList.push('multiselect__tags--hidden')
        }

        return classList.join(' ')
      },
    },
    methods: {
      select (item) {
        if (this.$v) {
          this.$v.$touch()
        }
        this.$emit('input', item)
      },
      addTag (tag) {
        this.$emit('tag', tag)
      },
    },
    props: {
      label: {
        type: String,
      },
      helpText: {
        type: String,
      },
      placeholder: {
        type: String,
        default: 'Select option',
      },
      options: {
        type: Array,
        default: () => [],
      },
      optionsLabel: {
        type: String,
      },
      selectedLabel: {
        type: String,
        default: '',
      },
      selectLabel: {
        type: String,
        default: 'Press enter/click to select',
      },
      deselectLabel: {
        type: String,
        default: 'Press enter/click to remove',
      },
      trackBy: {
        type: String,
      },
      value: {
        default: null,
      },
      searchable: {
        type: Boolean,
        default: true,
      },
      disabled: {
        type: Boolean,
        default: false,
      },
      $v: {
        type: Object,
      },
      inline: {
        type: Boolean,
        default: false,
      },
      optionalTextDisabled: {
        type: Boolean,
        default: false,
      },
      showLabels: {
        type: Boolean,
        default: true,
      },
      groupValues: {
        type: String,
      },
      groupLabel: {
        type: String,
      },
      hideTags: {
        type: Boolean,
        default: false,
      },
      allowEmpty: {
        type: Boolean,
        default: true,
      },
      openDirection: {
        type: String,
        default: '',
      },
      showDirtyIndicator: {
        type: Boolean,
        default: false,
      },
      viewOnly: {
        type: Boolean,
        default: false,
      },
      onSearchChange: {
        type: Function,
        default: () => {},
      },
      taggable: {
        type: Boolean,
        default: false,
      },
    },
  }
</script>
