import { v4 as uuid } from 'uuid'
import { action, computed, observable } from 'mobx'
import { each, filter, has, isArray, isEmpty, omit, reject } from 'lodash'
import StoreChangeBus from 'Services/StoreChangeBus'

const MAX_CACHE_AGE_IN_MS = 30000

class Page {
  _uuid = uuid()
  _parentPageSet = undefined
  @observable _requestInFlight = false
  @observable _lastFetchedAt = null

  _filter = {}
  _pageNumber = 0

  @observable _records = []

  constructor (parentPageSet, pageNumber) {
    this._parentPageSet = parentPageSet

    this._filter = this._parentPageSet._filterFrozen
    this._pageNumber = pageNumber
  }

  get uuid () {
    return this._uuid
  }

  fetch () {
    this._requestInFlight = true

    let fetcher = this._parentStore._fetchAll
    if (this.fetchOneRecord) {
      fetcher = this._parentStore._fetchOne
    }
    return fetcher.bind(this)(this.filter)
      .then(results => {
        this._requestInFlight = false
        if (this._parentPageSet.opts.paginate && !this.fetchOneRecord) {
          this._records = results.data
        } else {
          this._records = isArray(results) ? results : [results]
        }
        this._lastFetchedAt = Date.now()
        return results
      })
  }

  @computed
  get filterWithoutSortOrder () {
    return {
      ...omit(this._filter, 'sortOrder'),
    }
  }

  _sortString () {
    return this._filter.sortOrder ? this._filter.sortOrder.map(sort => `${sort.sortField}|${sort.direction}`).join(',') : ''
  }

  @computed
  get filter () {
    const sortString = this._sortString()
    return {
      page: this._pageNumber,
      ...this.filterWithoutSortOrder,
      ...(sortString.length > 0 && { sort: sortString }),
    }
  }

  @computed
  get records () {
    return this._records.peek()
  }

  @computed
  get cacheValid () {
    return !!this._lastFetchedAt && Date.now() - this._lastFetchedAt <= MAX_CACHE_AGE_IN_MS
  }

  @computed
  get filterNotSet () {
    return isEmpty(this._filter)
  }

  @computed
  get requestInFlight () {
    return this._requestInFlight
  }

  @computed
  get fetchOneRecord () {
    return has(this._filter, 'id')
  }

  @computed
  get pageNumber () {
    return this._pageNumber
  }

  get isNewRecordPage () {
    return this.pageNumber === this._parentPageSet._pages.length
  }

  /**
   * Public Methods
   */
  reload () {
    return this._reload()
  }

  invalidateCache () {
    this._lastFetchedAt = undefined
  }

  /**
   * Private Methods
   */
  @action
  _reload () {
    if (!this.requestInFlight) {
      this.invalidateCache()
      this.fetch()
    }
    return this._parentPageSet._initPagePromise(this.pageNumber)
  }

  @action
  _propagateChanges (changes, action) {
    if (action === 'reject') {
      return this._performRejectByFilter(changes)
    } else {
      return this._performChanges(changes)
    }
  }

  @action
  _performChanges (changedRecords) {
    let temp = this._records.peek()
    each(changedRecords, rec => {
      let idx = temp.findIndex(r => r.id === rec.id)
      let previousData = temp[idx]
      if (rec.deleted_at && idx >= 0) {
        temp.splice(idx, 1)
        StoreChangeBus.push({ action: 'remove', type: this._parentStore._type, id: rec.id, data: rec, previousData })
      } else if (!rec.deleted_at && idx >= 0) {
        temp[idx] = rec
        StoreChangeBus.push({ action: 'update', type: this._parentStore._type, id: rec.id, data: rec, previousData })
      } else if (filter([rec], this._parentStore.filterWithoutSortOrder).length > 0 && this.isNewRecordPage) {
        StoreChangeBus.push({ action: 'add', type: this._parentStore._type, id: rec.id, data: rec, previousData })
        temp.push(rec)
      }
    })
    this._records = temp
    return temp
  }

  @action
  _performRejectByFilter (filter) {
    const temp = reject(this._records.peek(), filter)
    this._records = temp
    return temp
  }

  get _parentStore () {
    return this._parentPageSet._parentStore
  }
}

export default Page
