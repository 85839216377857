<template>
  <component
    :is="componentType"
    v-bind="buttonAttrs"
    class="btn-dropdown-list-item"
    :class="{
      'btn-dropdown-list-item--danger': danger,
      'no-underline': isLink,
      'btn--disabled': disabled,
    }"
    @click="onClick"
  >
    {{ label }}
  </component>
</template>

<script>
  import ButtonMixin from 'Mixins/ButtonMixin.vue'

  export default {
    name: 'ks-button-dropdown-item',
    mixins: [ ButtonMixin ],
    data () {
      return {
      }
    },
    computed: {
    },
    methods: {
    },
    props: {
    },
  }
</script>
