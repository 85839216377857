import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

// Vuex Modules
import notifications from './notifications'
import paySchedules from './pay_schedules'
import personnelTypes from './personnel_types'
import tasks from './tasks'

Vue.use(Vuex)
const persistedState = createPersistedState({
  storage: window.sessionStorage,
  paths: ['notifications', 'tasks'],
})

const state = {
}

export default new Vuex.Store({
  plugins: [persistedState],
  state,
  modules: {
    notifications,
    paySchedules,
    personnelTypes,
    tasks,
  },
})
