import * as actions from './actions'
import * as getters from './getters'
import mutations from './mutations'

const state = {
  apiError: null,
  gettingPaySchedules: false,
  gettingPayScheduleEntities: false,
  paySchedules: {},
  payScheduleEntities: {},
  savingPayScheduleEntity: false,
  selectedPayScheduleId: null,
  selectedPayScheduleEntityId: null,
  selectedPayScheduleEntityRuleIdx: null,
  expenseCategories: {},
}

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
}
