import BusinessPolicy from 'Models/policies/BusinessPolicy'
import EventPolicy from 'Models/policies/EventPolicy'
import AgencyPolicy from 'Models/policies/AgencyPolicy'

const policies = {}
const modelPolicy = {
  'Event': EventPolicy,
  'Business': BusinessPolicy,
  'Agency': AgencyPolicy,
}

function mapModelNameToPolicyClass (modelName) {
  return modelPolicy[modelName]
}

class GlobalPolicyService {
  findOrCreate (modelName, record) {
    if (policies[modelName] && policies[modelName][record.id]) {
      return policies[modelName][record.id]
    } else {
      if (!policies[modelName]) {
        policies[modelName] = {}
      }
      const PolicyKlass = mapModelNameToPolicyClass(modelName)
      policies[modelName][record.id] = new PolicyKlass(record)
      return policies[modelName][record.id]
    }
  }
}

export default new GlobalPolicyService()
