import Vue from 'vue'
import { v4 as uuid } from 'uuid'
import { filter, isEmpty, reject } from 'lodash'

// Beep Beep
const BUS = new Vue()
const DUPLICATE_REJECT_WINDOW_MS = 100
const EVENT_NAME = 'STORE_CHANGE'

const queue = {}

function addToQueue (data) {
  const id = uuid()
  queue[id] = {
    uuid: id,
    data: data,
    timeout: setTimeout(() => {
      // if somehow this timeout didn't get cancelled quickly enough when a duplicate was added, check again right before dispatch
      if (!isDuplicate(data, id)) {
        dispatch(id)
      }
    }, DUPLICATE_REJECT_WINDOW_MS),
  }
}

function removeDuplicate (uuid) {
  if (queue[uuid]) {
    clearTimeout(queue[uuid].timeout)
    delete queue[uuid]
  }
}

function findDuplicates (data, uuid) {
  if (uuid) {
    return reject(filter(queue, { data: data }), { uuid })
  } else {
    return filter(queue, { data: data })
  }
}

function isDuplicate (data, id) {
  return !isEmpty(findDuplicates(data, id))
}

function dispatch (uuid) {
  const entry = queue[uuid]

  BUS.$emit(EVENT_NAME, entry.data)
  delete queue[uuid]
}

export default {
  subscribe (cb) {
    BUS.$on(EVENT_NAME, cb)
    return () => BUS.$off(EVENT_NAME, cb)
  },
  push (data) {
    if (isDuplicate(data)) {
      const dups = findDuplicates(data)
      dups.forEach(d => removeDuplicate(d.uuid))
    }
    addToQueue(data)
  },
}
