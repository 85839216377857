import { merge, orderBy } from 'lodash'

import axios from './axios'

const baseUrlString = '/expense_categories'
const baseAgencyUrlStringPrefix = '/agencies'
const baseAgencyUrlStringSuffix = baseUrlString

const baseAgencyUrlString = function (agencyId) {
  return `${baseAgencyUrlStringPrefix}/${agencyId}/${baseAgencyUrlStringSuffix}`
}

const ExpenseCategory = function (initialData) {
  var expenseCategory = {}

  expenseCategory.$reinitialize = function (data) {
    merge(expenseCategory, data)
  }

  expenseCategory.$reinitialize(initialData)

  return expenseCategory
}

const ExpenseCategories = {
  create: async function (agencyId, name) {
    return axios.post(baseAgencyUrlString(agencyId), {
      expense_category: { name },
    }).then(function (response) {
      return ExpenseCategories.initWithData(response.data)
    })
  },
  getAll: async function (agencyId) {
    return axios.get(baseAgencyUrlString(agencyId))
      .then(function (response) {
        return orderBy(response.data.map(expenseCategory => {
          return ExpenseCategories.initWithData(expenseCategory)
        }), ['name'])
      }).catch(function (error) {
        console.error(error.message)
      })
  },
  initWithData: function (data) {
    return new ExpenseCategory(data)
  },
}

export default ExpenseCategories
