import Model from './Model'

export const insuranceCategories = {
  OCAC_INSURANCE: 'ocac_insurance',
}

export const insuranceAssignmentAddonNames = {
  OCAC_INSURANCE_LLOYDS_OF_LONDON: 'ocac_insurance_lloyds_of_london',
}

export const assignmentAddonNames = {
  ...insuranceAssignmentAddonNames,
}

class AssignmentAddon extends Model {
  constructor (initialData) {
    super(initialData, {
    })
  }
}

export default AssignmentAddon
