import * as types from './mutation_types'

import Vue from 'vue'
import { each } from 'lodash'

export default {
  [types.RECEIVE_NOTIFICATIONS] (state, { notificationData }) {
    if (notificationData.page > state.highestStoredPage) {
      state.highestStoredPage = notificationData.page
    }
    if (notificationData.total_pages !== state.lastPage) {
      state.totalPages = notificationData.total_pages
    }
    state.unreadCount = notificationData.unread_count
    each(notificationData.notifications, function (notification) {
      addNotification(state, notification)
    })
  },
  [types.RECEIVE_NOTIFICATION] (state, { notification }) {
    addNotification(state, notification)
  },
  [types.RECEIVE_UNREAD_NOTIFICATIONS] (state, { notificationData }) {
    each(notificationData.notifications, function (notification) {
      addNotification(state, notification)
    })
  },
  [types.READ_NOTIFICATION] (state, { id }) {
    readNotification(state, id)
  },
  [types.UNREAD_NOTIFICATION] (state, { id }) {
    unreadNotification(state, id)
  },
  [types.READ_ALL_NOTIFICATIONS] (state) {
    state.unreadCount = 0
    each(state.allNotifications, function (notification) {
      readNotification(state, notification.id)
    })
  },
  [types.FETCHING_NOTIFICATIONS] (state) {
    state.fetchingNotifications = true
  },
  [types.FETCHED_NOTIFICATIONS] (state) {
    state.fetchingNotifications = false
  },
  [types.API_ERROR] (state, { error }) {
    state.apiError = error
  },
}

function addNotification (state, notification) {
  Vue.set(state.allNotifications, notification.id, notification)
}

function readNotification (state, id) {
  let notification = state.allNotifications[id]
  if (!notification.dismissed) {
    state.allNotifications[id].dismissed = true
    state.unreadCount -= 1
  }

  if (state.unreadCount < 0) {
    state.unreadCount = 0
  }
}

function unreadNotification (state, id) {
  let notification = state.allNotifications[id]
  if (notification.dismissed) {
    state.allNotifications[id].dismissed = false
    state.unreadCount += 1
  }
}
