import OVERSEER from 'Services/NewUserPermissionsService'

const MODEL_NAME = 'Event'

export default class EventPolicy {
  _record = undefined

  constructor (record) {
    this._record = record

    this._canAccept = OVERSEER.queryPermissions(MODEL_NAME, this._record, 'event.accept')
    this._canAddFees = OVERSEER.queryPermissions(MODEL_NAME, this._record, 'event.add_fees')
    this._canApproveEstimate = OVERSEER.queryPermissions(MODEL_NAME, this._record, 'event.approve_estimate')
    this._canCancel = OVERSEER.queryPermissions(MODEL_NAME, this._record, 'event.cancel')
    this._canClone = OVERSEER.queryPermissions(MODEL_NAME, this._record, 'event.clone')
    this._canCloseBidding = OVERSEER.queryPermissions(MODEL_NAME, this._record, 'event.close_bidding')
    this._canCreate = OVERSEER.queryPermissions(MODEL_NAME, this._record, 'event.create')
    this._canCreateAttachment = OVERSEER.queryPermissions(MODEL_NAME, this._record, 'event.create_attachment')
    this._canCreateJob = OVERSEER.queryPermissions(MODEL_NAME, this._record, 'event.create_job')
    this._canDeclineEstiamte = OVERSEER.queryPermissions(MODEL_NAME, this._record, 'event.decline_estimate')
    this._canDefaultFilterAllJobs = OVERSEER.queryPermissions(MODEL_NAME, this._record, 'event.default_filter_all_jobs')
    this._canDeleteFees = OVERSEER.queryPermissions(MODEL_NAME, this._record, 'event.delete_fees')
    this._canEditAlcoholServed = OVERSEER.queryPermissions(MODEL_NAME, this._record, 'event.edit_alcohol_served')
    this._canEditBeforeEventEstimated = OVERSEER.queryPermissions(MODEL_NAME, this._record, 'event.edit_before_event_estimated')
    this._canEditBeforeEventInvoiced = OVERSEER.queryPermissions(MODEL_NAME, this._record, 'event.edit_before_event_invoiced')
    this._canEditBiddingEndDate = OVERSEER.queryPermissions(MODEL_NAME, this._record, 'event.edit_bidding_end_date')
    this._canEditBiddingStartDate = OVERSEER.queryPermissions(MODEL_NAME, this._record, 'event.edit_bidding_start_date')
    this._canEditBusinessId = OVERSEER.queryPermissions(MODEL_NAME, this._record, 'event.edit_business_id')
    this._canEditContact1Id = OVERSEER.queryPermissions(MODEL_NAME, this._record, 'event.edit_contact_1_id')
    this._canEditCustomInvoiceId = OVERSEER.queryPermissions(MODEL_NAME, this._record, 'event.edit_custom_invoice_id')
    this._canEditDepositAmount = OVERSEER.queryPermissions(MODEL_NAME, this._record, 'event.edit_deposit_amount')
    this._canEditDepositType = OVERSEER.queryPermissions(MODEL_NAME, this._record, 'event.edit_deposit_type')
    this._canEditDescription = OVERSEER.queryPermissions(MODEL_NAME, this._record, 'event.edit_description')
    this._canEditEndDate = OVERSEER.queryPermissions(MODEL_NAME, this._record, 'event.edit_end_date')
    this._canEditEstimatedAttendance = OVERSEER.queryPermissions(MODEL_NAME, this._record, 'event.edit_estimated_attendance')
    this._canEditEstimatedVehicles = OVERSEER.queryPermissions(MODEL_NAME, this._record, 'event.edit_estimated_vehicles')
    this._canEditEventCategoryId = OVERSEER.queryPermissions(MODEL_NAME, this._record, 'event.edit_event_category_id')
    this._canEditFees = OVERSEER.queryPermissions(MODEL_NAME, this._record, 'event.edit_fees')
    this._canEditGateCode = OVERSEER.queryPermissions(MODEL_NAME, this._record, 'event.edit_gate_code')
    this._canEditJobPickupOverrideOption = OVERSEER.queryPermissions(MODEL_NAME, this._record, 'event.edit_job_pickup_override_option')
    this._canEditJobNotifyOption = OVERSEER.queryPermissions(MODEL_NAME, this._record, 'event.edit_job_notify_override_option')
    this._canEditLocation = OVERSEER.queryPermissions(MODEL_NAME, this._record, 'event.edit_location')
    this._canEditLockboxCode = OVERSEER.queryPermissions(MODEL_NAME, this._record, 'event.edit_lockbox_code')
    this._canEditName = OVERSEER.queryPermissions(MODEL_NAME, this._record, 'event.edit_name')
    this._canEditNumberOfOfficers = OVERSEER.queryPermissions(MODEL_NAME, this._record, 'event.edit_number_of_officers')
    this._canEditOvertime = OVERSEER.queryPermissions(MODEL_NAME, this._record, 'event.edit_overtime')
    this._canEditPaymentMethodType = OVERSEER.queryPermissions(MODEL_NAME, this._record, 'event.edit_payment_method_type')
    this._canEditPermit = OVERSEER.queryPermissions(MODEL_NAME, this._record, 'event.edit_permit')
    this._canEditSpecialInstructions = OVERSEER.queryPermissions(MODEL_NAME, this._record, 'event.edit_special_instructions')
    this._canEditStartDate = OVERSEER.queryPermissions(MODEL_NAME, this._record, 'event.edit_start_date')
    this._canEstimate = OVERSEER.queryPermissions(MODEL_NAME, this._record, 'event.estimate')
    this._canInteractingAsAgency = OVERSEER.queryPermissions(MODEL_NAME, this._record, 'event.interacting_as_agency')
    this._canInteractingAsBusiness = OVERSEER.queryPermissions(MODEL_NAME, this._record, 'event.interacting_as_business')
    this._canInvoice = OVERSEER.queryPermissions(MODEL_NAME, this._record, 'event.invoice')
    this._canManageAssignments = OVERSEER.queryPermissions(MODEL_NAME, this._record, 'event.manage_assignments')
    this._canOpenBidding = OVERSEER.queryPermissions(MODEL_NAME, this._record, 'event.open_bidding')
    this._canRemoveAttachment = OVERSEER.queryPermissions(MODEL_NAME, this._record, 'event.remove_attachment')
    this._canReopenBidding = OVERSEER.queryPermissions(MODEL_NAME, this._record, 'event.reopen_bidding')
    this._canReject = OVERSEER.queryPermissions(MODEL_NAME, this._record, 'event.reject')
    this._canSchedule = OVERSEER.queryPermissions(MODEL_NAME, this._record, 'event.schedule')
    this._canSelectManualPaymentMethod = OVERSEER.queryPermissions(MODEL_NAME, this._record, 'event.select_manual_payment_method')
    this._canSelectUnavailablePaymentMethod = OVERSEER.queryPermissions(MODEL_NAME, this._record, 'event.select_unavailable_payment_method')
    this._canShowOverviewTab = OVERSEER.queryPermissions(MODEL_NAME, this._record, 'event.show_overview_tab')
    this._canSkipBidding = OVERSEER.queryPermissions(MODEL_NAME, this._record, 'event.skip_bidding')
    this._canUnaccept = OVERSEER.queryPermissions(MODEL_NAME, this._record, 'event.unaccept')
    this._canUpdate = OVERSEER.queryPermissions(MODEL_NAME, this._record, 'event.update')
    this._canUpdateJob = OVERSEER.queryPermissions(MODEL_NAME, this._record, 'event.update_job')
    this._canUpdateFees = OVERSEER.queryPermissions(MODEL_NAME, this._record, 'event.update_fees')
    this._canView = OVERSEER.queryPermissions(MODEL_NAME, this._record, 'event.view')
    this._canViewFees = OVERSEER.queryPermissions(MODEL_NAME, this._record, 'event.view_fees')
    this._canViewActivityLog = OVERSEER.queryPermissions(MODEL_NAME, this._record, 'event.view_activity_log')
    this._canViewAlcoholServed = OVERSEER.queryPermissions(MODEL_NAME, this._record, 'event.view_alcohol_served')
    this._canViewAssignments = OVERSEER.queryPermissions(MODEL_NAME, this._record, 'event.can_view_assignments')
    this._canViewAssignmentsAfterScheduled = OVERSEER.queryPermissions(MODEL_NAME, this._record, 'event.can_view_assignments_after_scheduled')
    this._canViewApprovalRequests = OVERSEER.queryPermissions(MODEL_NAME, this._record, 'event.view_approval_requests')
    this._canViewBiddingEndDate = OVERSEER.queryPermissions(MODEL_NAME, this._record, 'event.view_bidding_end_date')
    this._canViewBiddingStartDate = OVERSEER.queryPermissions(MODEL_NAME, this._record, 'event.view_bidding_start_date')
    this._canViewBusinessId = OVERSEER.queryPermissions(MODEL_NAME, this._record, 'event.view_business_id')
    this._canViewContact1Id = OVERSEER.queryPermissions(MODEL_NAME, this._record, 'event.view_contact_1_id')
    this._canViewCustomInvoiceId = OVERSEER.queryPermissions(MODEL_NAME, this._record, 'event.view_custom_invoice_id')
    this._canViewDeposits = OVERSEER.queryPermissions(MODEL_NAME, this._record, 'event.view_deposits')
    this._canViewDepositAmount = OVERSEER.queryPermissions(MODEL_NAME, this._record, 'event.view_deposit_amount')
    this._canViewDepositType = OVERSEER.queryPermissions(MODEL_NAME, this._record, 'event.view_deposit_type')
    this._canViewDescription = OVERSEER.queryPermissions(MODEL_NAME, this._record, 'event.view_description')
    this._canViewDistributions = OVERSEER.queryPermissions(MODEL_NAME, this._record, 'event.view_distributions')
    this._canViewEndDate = OVERSEER.queryPermissions(MODEL_NAME, this._record, 'event.view_end_date')
    this._canViewEstimate = OVERSEER.queryPermissions(MODEL_NAME, this._record, 'event.view_estimate')
    this._canViewEstimatedAttendance = OVERSEER.queryPermissions(MODEL_NAME, this._record, 'event.view_estimated_attendance')
    this._canViewEstimatedVehicles = OVERSEER.queryPermissions(MODEL_NAME, this._record, 'event.view_estimated_vehicles')
    this._canViewEventCategoryId = OVERSEER.queryPermissions(MODEL_NAME, this._record, 'event.view_event_category_id')
    this._canViewExpenseCategorySummary = OVERSEER.queryPermissions(MODEL_NAME, this._record, 'event.view_expense_category_summary')
    this._canViewGateCode = OVERSEER.queryPermissions(MODEL_NAME, this._record, 'event.view_gate_code')
    this._canViewInvoice = OVERSEER.queryPermissions(MODEL_NAME, this._record, 'event.view_invoice')
    this._canViewJobFilters = OVERSEER.queryPermissions(MODEL_NAME, this._record, 'event.view_job_filters')
    this._canViewJobStats = OVERSEER.queryPermissions(MODEL_NAME, this._record, 'event.can_view_job_stats')
    this._canViewKommanderPay = OVERSEER.queryPermissions(MODEL_NAME, this._record, 'event.view_kommander_pay')
    this._canViewLocation = OVERSEER.queryPermissions(MODEL_NAME, this._record, 'event.view_location')
    this._canViewLockboxCode = OVERSEER.queryPermissions(MODEL_NAME, this._record, 'event.view_lockbox_code')
    this._canViewNotes = OVERSEER.queryPermissions(MODEL_NAME, this._record, 'event.view_notes')
    this._canViewNumberOfOfficers = OVERSEER.queryPermissions(MODEL_NAME, this._record, 'event.view_number_of_officers')
    this._canViewOvertime = OVERSEER.queryPermissions(MODEL_NAME, this._record, 'event.view_overtime')
    this._canViewPaymentMethodType = OVERSEER.queryPermissions(MODEL_NAME, this._record, 'event.view_payment_method_type')
    this._canViewPayments = OVERSEER.queryPermissions(MODEL_NAME, this._record, 'event.view_payments')
    this._canViewPaymentsAndDistributions = OVERSEER.queryPermissions(MODEL_NAME, this._record, 'event.view_payment_method_type')
    this._canViewPermit = OVERSEER.queryPermissions(MODEL_NAME, this._record, 'event.view_permit')
    this._canViewRoster = OVERSEER.queryPermissions(MODEL_NAME, this._record, 'event.view_roster')
    this._canViewStartDate = OVERSEER.queryPermissions(MODEL_NAME, this._record, 'event.view_start_date')
    this._canViewWarnings = OVERSEER.queryPermissions(MODEL_NAME, this._record, 'event.view_warnings')
    this._canViewSpecialInstructions = OVERSEER.queryPermissions(MODEL_NAME, this._record, 'event.view_special_instructions')
  }

  get canAccept () { return this._canAccept.can }
  get canAddFees () { return this._canAddFees.can }
  get canApproveEstimate () { return this._canApproveEstimate.can }
  get canCancel () { return this._canCancel.can }
  get canClone () { return this._canClone.can }
  get canCloseBidding () { return this._canCloseBidding.can }
  get canCreate () { return this._canCreate.can }
  get canCreateAttachment () { return this._canCreateAttachment.can }
  get canCreateJob () { return this._canCreateJob.can }
  get canDeclineEstiamte () { return this._canDeclineEstiamte.can }
  get canDefaultFilterAllJobs () { return this._canDefaultFilterAllJobs.can }
  get canDeleteFees () { return this._canDeleteFees.can }
  get canEditAlcoholServed () { return this._canEditAlcoholServed.can }
  get canEditBeforeEventEstimated () { return this._canEditBeforeEventEstimated.can }
  get canEditBeforeEventInvoiced () { return this._canEditBeforeEventInvoiced.can }
  get canEditBiddingEndDate () { return this._canEditBiddingEndDate.can }
  get canEditBiddingStartDate () { return this._canEditBiddingStartDate.can }
  get canEditBusinessId () { return this._canEditBusinessId.can }
  get canEditContact1Id () { return this._canEditContact1Id.can }
  get canEditCustomInvoiceId () { return this._canEditCustomInvoiceId.can }
  get canEditDepositAmount () { return this._canEditDepositAmount.can }
  get canEditDepositType () { return this._canEditDepositType.can }
  get canEditDescription () { return this._canEditDescription.can }
  get canEditEndDate () { return this._canEditEndDate.can }
  get canEditEstimatedAttendance () { return this._canEditEstimatedAttendance.can }
  get canEditEstimatedVehicles () { return this._canEditEstimatedVehicles.can }
  get canEditEventCategoryId () { return this._canEditEventCategoryId.can }
  get canEditFees () { return this._canEditFees.can }
  get canEditGateCode () { return this._canEditGateCode.can }
  get canEditJobPickupOverrideOption () { return this._canEditJobPickupOverrideOption.can }
  get canEditJobNotifyOption () { return this._canEditJobNotifyOption.can }
  get canEditLocation () { return this._canEditLocation.can }
  get canEditLockboxCode () { return this._canEditLockboxCode.can }
  get canEditName () { return this._canEditName.can }
  get canEditNumberOfOfficers () { return this._canEditNumberOfOfficers.can }
  get canEditOvertime () { return this._canEditOvertime.can }
  get canEditPaymentMethodType () { return this._canEditPaymentMethodType.can }
  get canEditPermit () { return this._canEditPermit.can }
  get canEditSpecialInstructions () { return this._canEditSpecialInstructions.can }
  get canEditStartDate () { return this._canEditStartDate.can }
  get canEstimate () { return this._canEstimate.can }
  get canInteractingAsAgency () { return this._canInteractingAsAgency.can }
  get canInteractingAsBusiness () { return this._canInteractingAsBusiness.can }
  get canInvoice () { return this._canInvoice.can }
  get canManageAssignments () { return this._canManageAssignments.can }
  get canOpenBidding () { return this._canOpenBidding.can }
  get canRemoveAttachment () { return this._canRemoveAttachment.can }
  get canReopenBidding () { return this._canReopenBidding.can }
  get canReject () { return this._canReject.can }
  get canSchedule () { return this._canSchedule.can }
  get canSelectManualPaymentMethod () { return this._canSelectManualPaymentMethod.can }
  get canSelectUnavailablePaymentMethod () { return this._canSelectUnavailablePaymentMethod.can }
  get canShowOverviewTab () { return this._canShowOverviewTab.can }
  get canSkipBidding () { return this._canSkipBidding.can }
  get canUnaccept () { return this._canUnaccept.can }
  get canUpdate () { return this._canUpdate.can }
  get canUpdateJob () { return this._canUpdateJob.can }
  get canUpdateFees () { return this._canUpdateFees.can }
  get canView () { return this._canView.can }
  get canViewFees () { return this._canViewFees.can }
  get canViewActivityLog () { return this._canViewActivityLog.can }
  get canViewAlcoholServed () { return this._canViewAlcoholServed.can }
  get canViewAssignments () { return this._canViewAssignments.can }
  get canViewAssignmentsAfterScheduled () { return this._canViewAssignmentsAfterScheduled.can }
  get canViewApprovalRequests () { return this._canViewApprovalRequests.can }
  get canViewBiddingEndDate () { return this._canViewBiddingEndDate.can }
  get canViewBiddingStartDate () { return this._canViewBiddingStartDate.can }
  get canViewBusinessId () { return this._canViewBusinessId.can }
  get canViewContact1Id () { return this._canViewContact1Id.can }
  get canViewCustomInvoiceId () { return this._canViewCustomInvoiceId.can }
  get canViewDeposits () { return this._canViewDeposits.can }
  get canViewDepositAmount () { return this._canViewDepositAmount.can }
  get canViewDepositType () { return this._canViewDepositType.can }
  get canViewDescription () { return this._canViewDescription.can }
  get canViewDistributions () { return this._canViewDistributions.can }
  get canViewEndDate () { return this._canViewEndDate.can }
  get canViewEstimate () { return this._canViewEstimate.can }
  get canViewEstimatedAttendance () { return this._canViewEstimatedAttendance.can }
  get canViewEstimatedVehicles () { return this._canViewEstimatedVehicles.can }
  get canViewEventCategoryId () { return this._canViewEventCategoryId.can }
  get canViewExpenseCategorySummary () { return this._canViewExpenseCategorySummary.can }
  get canViewGateCode () { return this._canViewGateCode.can }
  get canViewInvoice () { return this._canViewInvoice.can }
  get canViewJobFilters () { return this._canViewJobFilters.can }
  get canViewJobStats () { return this._canViewJobStats.can }
  get canViewKommanderPay () { return this._canViewKommanderPay.can }
  get canViewLocation () { return this._canViewLocation.can }
  get canViewLockboxCode () { return this._canViewLockboxCode.can }
  get canViewNotes () { return this._canViewNotes.can }
  get canViewNumberOfOfficers () { return this._canViewNumberOfOfficers.can }
  get canViewOvertime () { return this._canViewOvertime.can }
  get canViewPaymentMethodType () { return this._canViewPaymentMethodType.can }
  get canViewPayments () { return this._canViewPayments.can }
  get canViewPaymentsAndDistributions () { return this._canViewPaymentsAndDistributions.can }
  get canViewPermit () { return this._canViewPermit.can }
  get canViewRoster () { return this._canViewRoster.can }
  get canViewStartDate () { return this._canViewStartDate.can }
  get canViewWarnings () { return this._canViewWarnings.can }
  get canViewSpecialInstructions () { return this._canViewSpecialInstructions.can }
}
