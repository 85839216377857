import axios from 'axios'
import { BASE_API_URL } from 'Utils/constants'
import { registerInterceptors } from 'Utils/AxiosInterceptors'

const baseApiUrl = BASE_API_URL
const axiosInstance = axios.create()

axiosInstance.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'
axiosInstance.defaults.headers.common['X-CSRF-Token'] = document.querySelector('meta[name="csrf-token"]').getAttribute('content')
axiosInstance.defaults.baseURL = baseApiUrl

registerInterceptors(axiosInstance)

export default axiosInstance
