import { startCase } from 'lodash'
import moment from 'moment'
import { WindowVariables } from 'Utils'
import Business from './Business'
import Event from './Event'
import Job from './Job'
import Model from './Model'
import AssignmentsPolicy from './policies/AssignmentsPolicy'

const timeFormat = 'MM/DD/YY HH:mm'

export const createTypes = {
  FROM_PICKUP: 'from_pickup',
  MANUALLY_ASSIGNED: 'manually_assigned',
  AUTO_SCHEDULED: 'auto_scheduled',
}

export const assignmentTypes = {
  PRIMARY: 'primary',
  ALTERNATE: 'alternate',
}

export const typesForDropdown = [
  { id: 'type-both', label: 'Both', val: null },
  { id: 'type-primary', label: 'Primary', val: assignmentTypes.PRIMARY },
  { id: 'type-alternate', label: 'Alternate', val: assignmentTypes.ALTERNATE },
]

export const assignmentStates = {
  PENDING: 'pending',
  ASSIGNED: 'assigned',
  REJECTED: 'rejected',
  ACCEPTED: 'accepted',
  ENROUTE: 'enroute',
  ON_SITE: 'on_site',
  COMPLETED: 'completed',
  CANCELLED: 'cancelled',
}

export const statesForDropdown = [
  { id: assignmentStates.PENDING, label: 'Pending' },
  { id: assignmentStates.ASSIGNED, label: 'Assigned' },
  { id: assignmentStates.REJECTED, label: 'Rejected' },
  { id: assignmentStates.ACCEPTED, label: 'Acknowledged/Accepted' },
  { id: assignmentStates.ENROUTE, label: 'Enroute' },
  { id: assignmentStates.ON_SITE, label: 'On Site' },
  { id: assignmentStates.COMPLETED, label: 'Completed' },
  { id: assignmentStates.CANCELLED, label: 'Cancelled' },
]

const defaultExcludedStates = ['cancelled', 'rejected']

export const defaultStatesForDropdown = statesForDropdown
  .map(st => st.id)
  .filter(st => !defaultExcludedStates.includes(st))

class Assignment extends Model {
  constructor (initialData) {
    super(initialData, {
      momentKeys: [
        'enroute_datetime',
        'on_site_datetime',
        'completed_datetime',
        'pressed_enroute_datetime',
        'pressed_on_site_datetime',
        'pressed_completed_datetime',
        'job_start_date',
        'job_end_date',
        'accepted_at',
        'rejected_at',
        'deleted_at',
        'created_at',
        'updated_at',
        'derived_start_date',
        'derived_end_date',
        'cancelled_at',
      ],
      modelKeyMaps: {
        business: Business,
        event: Event,
        job: Job,
      },
    })

    this.policy = new AssignmentsPolicy(this)
  }

  get permissionsAgencyId () {
    return parseInt(this.agency_id)
  }

  get permissionsBusinessId () {
    return parseInt(this.business_id)
  }

  get permissionsOwnedByUserId () {
    // TODO: if we don't want this to work at all do we just return null?
    return parseInt(this.user_id)
  }

  // NEW PERMISSION STYLE BEGIN

  get canApproveCancellation () {
    return this.policy.canApproveCancellation
  }

  get canRequestCancel () {
    return this.policy.canRequestCancel
  }

  get canRequestCancelForOtherUser () {
    return this.policy.canRequestCancelForOtherUser
  }

  get canWithdrawCancel () {
    return this.policy.canWithdrawCancel
  }

  get canWithdrawCancelForOtherUser () {
    return this.policy.canWithdrawCancelForOtherUser
  }

  get canCreateActivityLogEntry () {
    return this.policy.canCreateActivityLogEntry
  }
  mayCreateActivityLogEntry () {
    return !this.event_invoiced
  }
  get canMayCreateActivityLogEntry () {
    return this.canCreateActivityLogEntry && this.mayCreateActivityLogEntry()
  }

  get canEdit () {
    return this.policy.canEdit
  }
  mayEdit () {
    return !(this.isPending() && this.isAssigned())
  }
  get canMayEdit () {
    return this.canEdit && this.mayEdit()
  }

  // NEW PERMISSION STYLE ENDS
  get assignmentState () {
    return this.user_facing_state
  }

  get cancelReasonDisplay () {
    return startCase(this.cancel_option === 'other' ? this.cancel_reason : this.cancel_option)
  }

  get isCreateTypePickup () {
    return this.create_type === createTypes.FROM_PICKUP
  }

  get isCreateTypeManual () {
    return this.create_type === createTypes.MANUALLY_ASSIGNED
  }

  get isCreateTypeAuto () {
    return this.create_type === createTypes.AUTO_SCHEDULED
  }

  get durationDisplay () {
    return parseFloat(this.derived_duration_in_hours).toFixed(2)
  }

  get derivedStartDateDisplay () {
    return this.derived_start_date.format(timeFormat)
  }

  get derivedEndDateDisplay () {
    return this.derived_end_date.format(timeFormat)
  }

  isActive () {
    return !this.isCancelled() && !this.isRejected()
  }

  isActivePrimary () {
    return this.isPrimary() && this.isActive()
  }

  isActiveAlternate () {
    return this.isAlternate() && this.isActive()
  }

  isPrimary () {
    return !this.alternate
  }

  isAlternate () {
    return !!this.alternate
  }

  isAccepted () {
    return this.aasm_state === 'accepted'
  }

  isPending () {
    return this.aasm_state === 'pending'
  }

  isAssigned () {
    return this.aasm_state === 'assigned'
  }

  wasCancelRequested () {
    return !!this.cancel_requested
  }

  isCancelled () {
    return this.aasm_state === 'cancelled'
  }

  isRejected () {
    return this.aasm_state === 'rejected'
  }

  hasAcknowledged () {
    return !this.isPending() && !this.isAssigned() && !this.isCancelled() && !this.isRejected()
  }

  get hasAdjustedTimes () {
    return this.hasAdjustedStartTime || this.hasAdjustedEndTime
  }

  get hasAdjustedStartTime () {
    if (!this.on_site_datetime) {
      return false
    } else {
      return !this.job_start_date.isSame(this.on_site_datetime)
    }
  }

  get hasAdjustedEndTime () {
    if (!this.completed_datetime) {
      return false
    } else {
      return !this.job_end_date.isSame(this.completed_datetime)
    }
  }

  get requiresActivityLogSubjectEntry () {
    return this.job_requires_activity_log_subject_entry
  }

  get jobStartDateIsApproaching () {
    const numHours = WindowVariables.agency.agency_preference_list.auto_cancel_attempt_cutoff_hours
    return this.derived_start_date.isBefore(moment().add(numHours, 'hours'))
  }

  get noAlternateAssignmentsOnJob () {
    if (this.job === null || this.job === undefined) {
      this.throwModelError(`'job' must be defined to use getter 'noAlternateAssignmentsOnJob'`)
    } else {
      return this.job.cachedNumAlternates === 0
    }
  }
}

export default Assignment
