import { merge, has } from 'lodash'

const messages = []
const callbacks = []

const DEFAULT_CONFIG = {
  defaultPosition: 'toast-top-full-width',
  defaultType: 'info',
  defaultTimeout: 4000,
  defaultClickClose: true,
  defaultCloseOnHover: false,
}

function processObjectData (type, data, title) {
  // if Object
  if (typeof data === 'object' && data.msg) {
    return {
      ...data,
      ...!data.type && { type: DEFAULT_CONFIG.defaultType },
      ...{ type },
      ...title && { title },
      ...!has(data, 'position') && { position: DEFAULT_CONFIG.defaultPosition },
      ...!has(data, 'timeout') && { timeout: DEFAULT_CONFIG.defaultTimeout },
      ...!has(data, 'closeOnHover') && { closeOnHover: DEFAULT_CONFIG.defaultCloseOnHover },
      ...!has(data, 'clickClose') && { clickClose: DEFAULT_CONFIG.defaultClickClose },
    }
  }

  // if String
  return {
    msg: data.toString(),
    position: DEFAULT_CONFIG.defaultPosition,
    type: type || DEFAULT_CONFIG.defaultType,
    timeout: DEFAULT_CONFIG.defaultTimeout,
    closeOnHover: DEFAULT_CONFIG.defaultCloseOnHover,
    clickClose: DEFAULT_CONFIG.defaultClickClose,
  }
}

function addMessage (data) {
  if (callbacks.length > 0) {
    callAddCallbacks(data)
  } else {
    messages.push(data)
  }
}

function callAddCallbacks (data) {
  callbacks.forEach((cb) => {
    cb.add(data)
  })
}

function callRemoveCallbacks (data) {
  callbacks.forEach((cb) => {
    cb.remove(data)
  })
}

function registerCallbacks (addCb, removeCb) {
  callbacks.push({
    add: addCb,
    remove: removeCb,
  })

  if (callbacks.length === 1) {
    messages.forEach(callAddCallbacks)
  }
}

function warning (msg, title, data) {
  addMessage(processObjectData('warning', merge(data || {}, { msg: msg }, title)))
}

function notice (msg, title, data) {
  addMessage(processObjectData('info', merge(data || {}, { msg: msg }, title)))
}

function info (msg, title, data) {
  addMessage(processObjectData('info', merge(data || {}, { msg: msg }, title)))
}

function error (msg, title, data) {
  addMessage(processObjectData('error', merge(data || {}, { msg: msg }, title)))
}

function success (msg, title, data) {
  addMessage(processObjectData('success', merge(data || {}, { msg: msg }, title)))
}

function alert (msg, title, data) {
  addMessage(processObjectData('info', merge(data || {}, { msg: msg }, title)))
}

function removeFlash (data) {
  callRemoveCallbacks(data)
}

export default {
  registerCallbacks,
  removeFlash,
  warning,
  success,
  info,
  error,
  alert,
  notice,
}
