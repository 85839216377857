export const RECEIVE_PAY_SCHEDULES = 'RECEIVE_PAY_SCHEDULES'
export const RECEIVE_PAY_SCHEDULE = 'RECEIVE_PAY_SCHEDULE'
export const RECEIVE_PAY_SCHEDULE_ENTITIES = 'RECEIVE_PAY_SCHEDULE_ENTITIES'
export const RECEIVE_PAY_SCHEDULE_ENTITY = 'RECEIVE_PAY_SCHEDULE_ENTITY'
export const RECEIVE_EXPENSE_CATEGORIES = 'RECEIVE_EXPENSE_CATEGORIES'
export const RECEIVE_EXPENSE_CATEGORY = 'RECEIVE_EXPENSE_CATEGORY'
export const SELECT_PAY_SCHEDULE = 'SELECT_PAY_SCHEDULE'
export const SELECT_PAY_SCHEDULE_ENTITY = 'SELECT_PAY_SCHEDULE_ENTITY'
export const SAVING_PAY_SCHEDULE_ENTITY = 'SAVING_PAY_SCHEDULE_ENTITY'
export const SAVED_PAY_SCHEDULE_ENTITY = 'SAVED_PAY_SCHEDULE_ENTITY'
export const API_ERROR = 'API_ERROR'
