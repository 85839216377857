import { getStoreFor } from 'Stores/StoreInstances'
import BaseStore from 'Stores/base_store/BaseStore'
import { action } from 'mobx'
import Users from 'DataServices/Users'

const STORE_TYPE = 'UsersMin'

export function getUsersMinStoreFor (param, opts = {}) {
  return getStoreFor(UsersMinStore, STORE_TYPE, param, {
    forceUnique: false,
    preload: opts.preload,
    autoFetch: opts.autoFetch,
    fetchAll: opts.fetchAll,
  })
}

export class UsersMinStore extends BaseStore {
  constructor (seedData) {
    const opts = {
      paginate: true,
    }
    super(STORE_TYPE, seedData, opts)
  }

  @action
  _fetchAll (overridePayload) {
    let payload = overridePayload || {
      ...this._filter,
    }
    return Users.getAll({
      ...payload,
      mode: 'min',
    })
  }

  @action
  _fetchOne () {
    return Users.getById(this._filter.id, 'min')
  }
}
